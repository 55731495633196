import { combineReducers } from 'redux';
import { ProductsReducer } from './Products';

const rootReducer = combineReducers({
  oldReducer: oldRootReducer,
  products: ProductsReducer,
});

function oldRootReducer(state = { clients: [] }, action) {
  switch (action.type) {
    case 'SET_CLIENTS':
      return { ...state, clients: action.clients };
    case 'SET_CLIENT':
      return { ...state, client: action.client };
    default:
      return state;
  }
}

export default rootReducer;

import React from 'react';

function ListedProductURL({ brand, url, manualUrl }) {
  const tobeListedURL = () => `${brand} - ToBe`;
  const delistedURL = () => `${brand} - Delisted`;
  const listedURL = () => (
    <a href={url} rel="noopener noreferrer" target="_blank">
      {brand}
    </a>
  );
  const manualListedURL = () => (
    <a href={manualUrl} rel="noopener noreferrer" target="_blank">
      {brand} - M
    </a>
  );

  const getLink = () => {
    if ((url === null || url === '') && manualUrl === '')
      return tobeListedURL();
    else if (url === 'd') return delistedURL();
    else if (url !== '') return listedURL();
    else return manualListedURL();
  };
  return <small>{getLink()}</small>;
}

export default ListedProductURL;

import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Row, Col, Table, Button } from 'react-bootstrap';
import { Confirmer } from 'src/services/SweetAlerts';

function ErrorMap() {
  const [errorNo, setErrorNo] = useState('');
  const [eBayError, seteBayError] = useState('');
  const [hammoqError, setHammoqError] = useState('');
  const [errorMap, setErrorMap] = useState([]);

  useEffect(() => fetchErrorMap(), []);
  const fetchErrorMap = () => {
    Axios.get('/errormap')
      .then((res) => {
        setErrorMap(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addError = () => {
    if (errorNo == '' || hammoqError == '')
      return Confirmer.fire({ text: 'Please fill Error# and Hammoq Error' });
    for (let item of errorMap) {
      if (errorNo == item.errorID) {
        return Confirmer.fire({ text: 'ErrorID already added' });
      }
    }
    Axios.post('/errormap/adderrormap', {
      errorNo: errorNo,
      ebayError: eBayError,
      hammoqError: hammoqError,
    })
      .then(() => {
        fetchErrorMap();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteError = (id) => {
    Axios.delete('/errormap/deleteerrormap/' + id)
      .then(() => {
        fetchErrorMap();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div>
        <h2>eBay error map</h2>
        Make errors agent-friendly.
      </div>
      <br />
      <input
        type="text"
        placeholder="Error #"
        className="form-control mt-3 body-text"
        name="errorNo"
        value={errorNo}
        onChange={(e) => {
          setErrorNo(e.target.value);
        }}
      />
      <input
        type="text"
        placeholder="eBay Error (for reference)"
        className="form-control mt-3 body-text"
        name="eBayError"
        value={eBayError}
        onChange={(e) => {
          seteBayError(e.target.value);
        }}
      />
      <input
        type="text"
        placeholder="Hammoq Error"
        className="form-control mt-3 body-text"
        name="hammoqError"
        value={hammoqError}
        onChange={(e) => {
          setHammoqError(e.target.value);
        }}
      />
      <button className="btn btn-primary mt-3" onClick={addError}>
        Add
      </button>
      <br />
      <br />
      <Table responsive bordered hover>
        <thead>
          <tr>
            <th>Error # </th>
            <th>Ebay error</th>
            <th>Hammoq error</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {errorMap &&
            errorMap.map((error, idx) => {
              return (
                <tr>
                  <td>{error.errorID}</td>
                  <td>{error.ebayError}</td>
                  <td>{error.hammoqError}</td>
                  <td>
                    <Button
                      variant="link"
                      style={{ color: 'red' }}
                      className="fas fa-trash-alt"
                      onClick={() => deleteError(error._id)}
                    ></Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </>
  );
}

export default ErrorMap;

import React from 'react';
import DashboardTable from './Components/DashboardTable';
import Axios from '../../services/Axios';
import Search from '../../Pages/utils/Search';

class AgentDashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      users: [],
      searchedAgents: [],
      search: '',
      totalListing: 0,
      totalDelisting: 0,
      totalCrossListing: 0,
      isLoading: false,
    };
  }

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    await Axios.get('/agent').then((response) => {
      this.setState({ users: response.data.agents });
      this.setState({ isLoading: false });
    });

    var listing = 0;
    var delisting = 0;
    var crossListing = 0;

    for (var i = 0; i < this.state.users.length; i++) {
      listing = listing + this.state.users[i].totalListings.listing;
      delisting = delisting + this.state.users[i].totalListings.delisting;
      crossListing =
        crossListing + this.state.users[i].totalListings.crosslisting;
    }
    this.setState({
      totalListing: listing,
      totalDelisting: delisting,
      totalCrossListing: crossListing,
    });
  };

  handleSearchChange = (e) => {
    const { value } = e.target;
    const { users } = this.state;
    this.setState({ searchedAgents: Search(users, value), search: value });
  };

  render() {
    return (
      <DashboardTable
        searchedAgents={this.state.searchedAgents}
        users={this.state.users}
        search={this.state.search}
        handleSearchChange={this.handleSearchChange}
        totalListing={this.state.totalListing}
        totalCrossListing={this.state.totalCrossListing}
        totalDelisting={this.state.totalDelisting}
        isLoading={this.state.isLoading}
      />
    );
  }
}

export default AgentDashboard;

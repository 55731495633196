import React from 'react';
import ListingDetailsTable from './Components/ListingDetailsTable';
import Axios from '../../services/Axios';

class AgentListingDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      users: [],
    };
  }

  componentDidMount = async () => {
    const id = this.props.match.params.id;
    const response = await Axios.get(`/agent/${id}`);
    this.setState({ users: response.data.noOfListings });
  };

  render() {
    return (
      <div>
        {/* <DashboardTable 
            users={this.state.users}
        /> */}
        <ListingDetailsTable users={this.state.users} />
      </div>
    );
  }
}

export default AgentListingDetails;

import axios from 'axios';
import { baseURL } from './Axios';
import db from './database';
import { on, trigger } from './event';
import { ErrorToaster } from './SweetAlerts';
window.pendingImages = [];

const signImage = async (id) => {
  try {
    const query = { id };
    const response = await axios.get(`${baseURL}/media/sign`, {
      params: query,
    });
    return response.data;
  } catch (error) {
    if (error.message.includes('Network')) {
      ErrorToaster.fire({
        title: 'No internet connection! unable to upload pending images',
      });
    }
    return { error: error.message };
  }
};
const removeFile = async (data, params) => {
  // Deleting from the prevent array
  for (var i = 0; i < window.pendingImages.length; i++) {
    if (window.pendingImages[i].id === data.id) {
      window.pendingImages.splice(i, 1);
    }
  }

  if (params && params.cache) return true;

  // Delete the image
  console.log('Deleting the image', data.id);
  const docId = data.docId ? data.docId : 'image:' + data.id;
  const doc = await db.get(docId);
  doc._deleted = true;

  return db.put(doc);
};

const uploadFile = async (file, data) => {
  try {
    const pendingImage = window.pendingImages.find((o) => o.id === data.id);
    if (!pendingImage) {
      window.pendingImages.push({
        progress: 0,
        id: data.id,
      });
    }

    // Set axios configs
    const config = {
      onUploadProgress: function (progressEvent) {
        var percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        trigger('image:progress', { data, percentage });
      },
      headers: {
        'Content-Type': data.contentType,
      },
    };

    // Tranfser Image
    const response = await axios.put(data.presignedURL, file, config);
    trigger('image:complete', { file, data });

    // Remove Image
    return removeFile(data);
  } catch (error) {
    console.error('something went wrong uploading image', error.message);
    if (error.message && error.message.includes('Network')) {
      trigger('image:error', { data });
    }
    return removeFile(data, { cache: true });
  }
};

const appendImage = async (file, data) => {
  const key = 'image:' + data.id;
  let image;
  try {
    image = await db.get(key, { include_docs: true });
  } catch (error) {
    console.log('error happened retrieving image from pouchdb');
  }

  if (!image) {
    const doc = {
      _id: 'image:' + data.id,
      ...data,
      _attachments: {
        [data.id]: {
          content_type: data.contentType,
          data: file,
        },
      },
    };
    db.put(doc)
      .then(function (result) {
        uploadFile(file, data);
      })
      .catch(function (err) {
        console.log(err);
      });
  }
};

const init = async () => {
  // const key = '^image';
  const images = await db.allDocs({
    attachments: true,
    binary: true,
    include_docs: true,
  });

  if (!images.rows) {
    return;
  }
  images.rows.map(async (image, index) => {
    const attachmentId = image.id.replace('image:', '');
    const file = new File(
      [image.doc._attachments[attachmentId].data],
      attachmentId,
      { type: image.doc.contentType }
    );
    setTimeout(async () => {
      let data = {
        id: attachmentId,
        docId: image.id,
      };
      const fileName = attachmentId.substring(
        attachmentId.indexOf('-') + 1,
        attachmentId.length
      );
      const updated = await signImage(attachmentId);
      if (!updated.error) {
        data.presignedURL = image.doc.presignedURL;
        data.contentType = image.doc.contentType;
      } else {
        data.presignedURL = image.doc.presignedURL;
        data.contentType = image.doc.contentType;
      }

      trigger('image:restart', { data });
      uploadFile(file, data);
    }, index * 2000);
  });
};

init();
on('image:append', ({ detail }) => appendImage(detail.file, detail.data));
on('image:remove', ({ detail }) => {
  removeFile(detail.data);
});

window.onbeforeunload = function () {
  if (window.pendingImages && Object.keys(window.pendingImages).length !== 0) {
    return 'Your images are not completely uploaded...';
  }
};

import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { service_endpoint_urls } from './services/endpoint-detection';

interface DatadogConfig {
  clientToken: string;
  ddSite?: string;
  applicationId: string;
  serviceName: string;
  appVersion: string;
  appEnv?: string;
  trackSessionAcrossSubdomains?: boolean;
}

export function initializeDataDog(datadogConfig: DatadogConfig): void {
  const {
    clientToken,
    ddSite = 'datadoghq.com',
    applicationId,
    serviceName,
    appVersion,
    appEnv = service_endpoint_urls.appEnv,
    trackSessionAcrossSubdomains = true,
  } = datadogConfig;

  datadogRum.init({
    applicationId: applicationId,
    clientToken: clientToken,
    site: ddSite,
    service: serviceName,
    env: appEnv, // Specify a version number to identify the deployed version of your application in Datadog
    version: appVersion,
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'allow',
    trackSessionAcrossSubdomains,
    allowedTracingOrigins: [
      /https?:\/\/(::1|localhost|127.0.0.1)(:\d+)?/,
      /https:\/\/.*\.hammoq\.com/,
    ],
  });

  datadogLogs.init({
    clientToken: clientToken,
    site: ddSite,
    service: serviceName,
    env: appEnv,
    forwardErrorsToLogs: true,
    sampleRate: 100,
    trackSessionAcrossSubdomains,
  });

  datadogRum.startSessionReplayRecording();
}

// logger.debug | info | warn | error (message: string, messageContext = Context)
const logger = datadogLogs.logger;
export { logger };

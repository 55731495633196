import React from 'react';

function LeafNode({ brandName, value, update }) {
  return (
    <>
      <input
        className="py-1 text-center"
        type="text"
        value={brandName}
        onChange={(e) => update(e.target.value, value)}
      />
      <span className="bg-secondary text-white px-3 py-2 mx-2">Percentage</span>
      <input
        className="py-1 text-center"
        type="text"
        value={value}
        onChange={(e) => update(brandName, parseFloat(e.target.value) || 0)}
      />
    </>
  );
}

export default LeafNode;

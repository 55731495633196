import React, { useEffect, useState } from 'react';
import axiox from '../../services/Axios';
import TopBar from './Components/TopBar';
import AllRates from './Components/AllRates';
import { ErrorToaster } from 'src/services/SweetAlerts';
export default function Rates() {
  const [rates, setRates] = useState([]);
  useEffect(() => {
    getRates();
  }, []);

  var getRates = async () => {
    try {
      const rateResponse = await axiox.get('/rates');
      setRates(rateResponse.data.rates);
    } catch (err) {
      ErrorToaster.fire({ text: 'Something went wrong while get rates ' });
    }
  };
  return (
    <div>
      <TopBar getRates={getRates} />
      <AllRates rates={rates} getRates={getRates} />
    </div>
  );
}

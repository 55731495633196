import React, { useState } from 'react';
import './ApprovedDraftedFilterSort.css';
import Select from 'react-select';

const ApprovedDraftedFilterSort = (props) => {
  const [filterTypeOptions] = useState([
    {
      value: 'Filter Non-Empty Values and Sort',
      label: 'Filter Non-Empty Values and Sort',
    },
  ]);

  const [nonEmptyValuesSortDirection] = useState([
    { value: 'Asc', label: 'Asc' },
    { value: 'Desc', label: 'Desc' },
  ]);

  const [selectedFilterType, setSelectedFilterType] = useState('');

  const [
    nonEmptyFilterSelectedSortDirection,
    setNonEmptyFilterSelectedSortDirection,
  ] = useState('');

  const handleFilterTypeChange = (selectedOption) => {
    setSelectedFilterType(selectedOption);
  };

  const handleNonEmptyFilterSortDirectionChange = (selectedOption) => {
    setNonEmptyFilterSelectedSortDirection(selectedOption);
  };

  const apply = () => {
    if (
      selectedFilterType === null ||
      nonEmptyFilterSelectedSortDirection === null
    ) {
      props.setFilterColumn('');
      props.setFilterType('');
      return;
    }

    if (selectedFilterType.value === 'Filter Non-Empty Values and Sort') {
      if (nonEmptyFilterSelectedSortDirection.value === 'Asc') {
        props.setFilterColumn('APPROVED_DRAFTED');
        props.setFilterType('FILTER_NON_EMPTY_APPROVED_DRAFTED_ASC');
      }

      if (nonEmptyFilterSelectedSortDirection.value === 'Desc') {
        props.setFilterColumn('APPROVED_DRAFTED');
        props.setFilterType('FILTER_NON_EMPTY_APPROVED_DRAFTED_DESC');
      }
    }
  };

  const clear = () => {
    setSelectedFilterType(null);
    setNonEmptyFilterSelectedSortDirection(null);
  };

  return (
    <div className="approved_drafted-filter-sort-container">
      <Select
        className="basic-single"
        classNamePrefix="select"
        isDisabled={false}
        isClearable={true}
        isSearchable={true}
        autosize={true}
        value={selectedFilterType}
        options={filterTypeOptions}
        onChange={handleFilterTypeChange}
      />
      {selectedFilterType?.value === 'Filter Non-Empty Values and Sort' ? (
        <Select
          className="basic-single"
          classNamePrefix="select"
          isDisabled={false}
          isClearable={true}
          isSearchable={true}
          value={nonEmptyFilterSelectedSortDirection}
          options={nonEmptyValuesSortDirection}
          onChange={handleNonEmptyFilterSortDirectionChange}
        />
      ) : null}
      <div>
        <div id="approved_drafted-filter-apply-button" onClick={() => apply()}>
          Apply
        </div>
        <div id="clear-filter-apply-button" onClick={() => clear()}>
          Clear
        </div>
      </div>
    </div>
  );
};

export default ApprovedDraftedFilterSort;

import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Row, Col, Table, Button } from 'react-bootstrap';
import { Confirmer } from 'src/services/SweetAlerts';

function Notifications() {
  const [email, setEmail] = useState('');
  const [emailIds, setEmailIds] = useState([]);

  useEffect(() => fetchEmailIds(), []);
  const fetchEmailIds = () => {
    Axios.get('/notifications')
      .then((res) => {
        console.log(res.data);
        setEmailIds(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const addEmail = () => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!email.match(emailRegex))
      return Confirmer.fire({ text: 'Email address not valid' });
    for (let item of emailIds) {
      if (email == item.email) {
        return Confirmer.fire({ text: 'Email already added' });
      }
    }
    Axios.post('/notifications/addemail', {
      email: email,
    })
      .then(() => {
        fetchEmailIds();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteEmail = (id) => {
    Axios.delete('/notifications/deletemail/' + id).then(() => {
      fetchEmailIds();
    });
  };

  return (
    <>
      <div>
        <h2>Notifications</h2>
        Receive email notifications when a new user signs up.
      </div>
      <Row>
        <Col>
          <input
            type="text"
            placeholder="Email"
            className="form-control mt-3 body-text"
            name="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </Col>
        <Col>
          <button className="btn btn-primary mt-3" onClick={addEmail}>
            Add
          </button>
        </Col>
      </Row>
      <br />
      <Table responsive bordered hover>
        <thead>
          <tr>
            <th>No. </th>
            <th>Email Id</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {emailIds &&
            emailIds.map((user, idx) => {
              return (
                <tr>
                  <td>{idx + 1}</td>
                  <td>{user.email}</td>
                  <td>
                    <Button
                      variant="link"
                      style={{ color: 'red' }}
                      className="fas fa-trash-alt"
                      onClick={() => deleteEmail(user._id)}
                    ></Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </>
  );
}

export default Notifications;

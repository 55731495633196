import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@material-ui/core';

const CustomCheckBox = ({ onChange, checked, disabled }) => {
  return (
    <Checkbox
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      inputProps={{ 'aria-label': 'controlled' }}
    />
  );
};

CustomCheckBox.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

export default CustomCheckBox;

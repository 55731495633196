import React from 'react';
import Axios from '../services/Axios';
import { Link } from 'react-router-dom';
import { nanoid } from 'nanoid';
import moment from 'moment';

class EditAndUpdateRate extends React.Component {
  constructor() {
    super();
    this.state = {
      rates: [],
      minProducts: '',
      maxProducts: '',
      list: '',
      crosslist: '',
      delist: '',
      date_from: '',
      date_to: '',
    };
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    Axios.get(`/rate/${id}`).then((response) => {
      var data = response.data;
      this.setState({
        rates: data.Rates,
        minProducts: data.minProducts,
        maxProducts: data.maxProducts,
        list: data.Rates[data.Rates.length - 1].list,
        delist: data.Rates[data.Rates.length - 1].delist,
        crosslist: data.Rates[data.Rates.length - 1].crosslist,
        date_from: data.Rates[data.Rates.length - 1].date_from,
        date_to: data.Rates[data.Rates.length - 1].date_to,
      });
    });
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const id = this.props.match.params.id;
    const {
      list,
      delist,
      crosslist,
      date_to,
      date_from,
      maxProducts,
      minProducts,
    } = this.state;
    const formDate = {
      list,
      delist,
      crosslist,
      date_to,
      date_from,
      maxProducts,
      minProducts,
    };

    const response = await Axios.post(`/rate/add/${id}`, formDate);
    if (response.data) {
      const data = response.data;
      this.setState({
        rates: data.Rates,
        minProducts: data.minProducts,
        maxProducts: data.maxProducts,
        list: data.Rates[data.Rates.length - 1].list,
        delist: data.Rates[data.Rates.length - 1].delist,
        crosslist: data.Rates[data.Rates.length - 1].crosslist,
        date_from: data.Rates[data.Rates.length - 1].date_from,
        date_to: data.Rates[data.Rates.length - 1].date_to,
      });
    } else {
      window.alert('something went wrong...when kundan fix it');
    }
  };
  render() {
    const {
      rates,
      maxProducts,
      minProducts,
      list,
      crosslist,
      delist,
      date_to,
    } = this.state;
    return (
      <div>
        <div className="d-flex justify-content-center">
          <h2>
            Set Rates for Listing Product Range : {minProducts} - {maxProducts}
          </h2>
        </div>
        <form onSubmit={this.handleSubmit}>
          <div className="row mt-4 d-flex justify-content-around">
            <div className="col-3">
              For Listing(in cents)
              <input
                type="number"
                name="list"
                className="mt-3"
                min="0"
                value={list}
                onChange={this.handleChange}
              />
            </div>
            <div className="col-3">
              For Crosslisting (in cents){' '}
              <input
                type="number"
                name="crosslist"
                className="mt-3"
                min="0"
                value={crosslist}
                onChange={this.handleChange}
              />
            </div>
            <div className="col-3">
              For Delisting(in cents){' '}
              <input
                type="number"
                name="delist"
                min="0"
                className="mt-3"
                value={delist}
                onChange={this.handleChange}
              />
            </div>
            <div className="col-3">
              Min Products{' '}
              <input
                type="number"
                name="minProducts"
                min="0"
                className="mt-3"
                value={minProducts}
                onChange={this.handleChange}
              />
            </div>
            <div className="col-3">
              Max Products{' '}
              <input
                type="number"
                name="maxProducts"
                min="0"
                className="mt-3"
                value={maxProducts}
                onChange={this.handleChange}
              />
            </div>

            <div className="col-3">
              Expiry Date for the Rates
              <input
                type="date"
                name="date_to"
                className="mt-3"
                value={date_to || ''}
                onChange={this.handleChange}
              />
            </div>
            <div></div>
            <div className="col-4 mt-4 d-flex justify-content-center">
              <button type="submit" className="btn btn-success">
                Submit
              </button>
              <Link to="/users" className="btn btn-danger ml-3">
                Cancel
              </Link>
            </div>
          </div>
        </form>

        {/* <button className="btn btn-primary btn-sm m-auto" onClick={handleExtend}>
          Extend Last Rate for 2 months from today
        </button> */}
        <table className="table mt-3">
          <thead>
            <tr>
              <th>Listing</th>
              <th>Cross Listing</th>
              <th>DeListing</th>
              <th>From</th>
              <th>To</th>
            </tr>
          </thead>
          <tbody>
            {rates &&
              rates.map((rate, idx) => {
                if (idx === rates.length - 1) {
                  return (
                    <tr key={nanoid(5)} style={{ backgroundColor: '#f0f0f0' }}>
                      <td>{rate.list}</td>
                      <td>{rate.crosslist}</td>
                      <td>{rate.delist}</td>
                      <td>{moment(rate.date_from).format('ll')}</td>
                      <td>
                        {rate.date_to ? moment(rate.date_to).format('ll') : ' '}
                      </td>
                    </tr>
                  );
                } else {
                  return (
                    <tr key={nanoid(5)}>
                      <td>{rate.list}</td>
                      <td>{rate.crosslist}</td>
                      <td>{rate.delist}</td>
                      <td>{moment(rate.date_from).format('ll')}</td>
                      <td>
                        {rate.date_to ? moment(rate.date_to).format('ll') : ' '}
                      </td>
                    </tr>
                  );
                }
              })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default EditAndUpdateRate;

/** @format */

import { useDispatch } from 'react-redux';
import { setProductIds, updateProductIds } from './actions';

export const useProductsActions = () => {
  const dispatch = useDispatch();

  /**
   *
   * Function that Set the products and pagination data
   * @param {products} [ProductDoc[]] - List of products
   * @param {currentPage} [number] - the current page
   * @param {totalPage} [number] - the total number of products total
   * @param {rowsPerPage} [number] - the number of rows per page
   * @param {prodStatus} [string] - the selected product status
   * @return {*}  {void}
   */
  const doSetProductsIdsAndPaginationData = async (
    products,
    currentPage,
    totalPage,
    rowsPerPage,
    prodStatus
  ) => {
    dispatch(
      setProductIds({
        products,
        currentPage,
        totalPage,
        rowsPerPage,
        prodStatus,
      })
    );
  };

  /**
   *
   * Function that update the products and pagination data
   * @param {products} [ProductDoc[]] - List of products
   * @param {currentPage} [number] - the current page
   * @param {totalPage} [number] - the total number of products total
   * @param {rowsPerPage} [number] - the number of rows per page
   * @param {prodStatus} [string] - the selected product status
   * @return {*}  {void}
   */
  const doUpdateProductsIdsAndPaginationData = async (
    products,
    currentPage,
    totalPage,
    rowsPerPage,
    prodStatus
  ) => {
    dispatch(
      updateProductIds({
        products,
        currentPage,
        totalPage,
        rowsPerPage,
        prodStatus,
      })
    );
  };

  // returns functions
  return {
    doSetProductsIdsAndPaginationData,
    doUpdateProductsIdsAndPaginationData,
  };
};

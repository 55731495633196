import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Confirmer,
  SuccessToaster,
  ErrorToaster,
} from 'src/services/SweetAlerts';
import Axios from '../../services/Axios';
import AssignRateModal from './Components/AssignRateModal';
import ClientInfo from './Components/ClientInfo';
import './payment.css';

const Form = ({ title, children }) => {
  return (
    <div style={{ border: '1px solid black' }}>
      <div className="card-header">
        <h5>{title}</h5>
      </div>
      <div className="card-body">{children}</div>
    </div>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={'simple-tabpanel-${index}'}
      aria-labelledby={'simple-tab-${index}'}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: 'simple-tab-${index}',
    'aria-controls': 'simple-tabpanel-${index}',
  };
}

class Payments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userid: props.match.params.clientid,
      firstName: '',
      lastName: '',
      isSubscribed: false,
      balance: 0,
      amt: 0,
      promotionamt: 0,
      payments: [],
      reason: 'promotional_amount_by_admin',
      isOpen: false,
      tabValue: 0,
      subscribedByAdmin: false,
      stripeCustomerId: '',
      rates: [],
      assignRateModalOpen: false,
      userRate: {},
    };
    this.getUserDetails = this.getUserDetails.bind(this);
  }

  change = (e) => {
    if (e.target.name == 'promotionamt' && isNaN(e.target.value)) return;
    this.setState({ [e.target.name]: e.target.value });
  };

  changeAmt = (e) => {
    this.setState({ amt: e.target.value.replace(/[^\d,.]/, '') });
  };

  payment = async (e) => {
    //window.alert(`Are You Sure, you want to add $${this.state.amt}`);
    const { payments } = this.state;
    e.preventDefault();
    Axios.post('/payment/makepayment', {
      amount: this.state.amt,
      customer_id: this.state.userid,
    })
      .then(({ data }) => {
        if (data.status === false) {
          Confirmer.fire({
            text: 'The payment is failed with message: ' + data.msg,
          });
        } else {
          payments.push({
            amount: this.state.amt,
            date: data.date,
            receipt_url: data.receipt_url,
          });
          this.setState({ payments, isOpen: false });
          Confirmer.fire({ text: data.msg });
        }
      })
      .catch((err) => {
        this.setState({ isOpen: false });
        ErrorToaster.fire('Error is updating balance');
      });
  };

  promotionalPayment = async (e) => {
    //window.alert(`Are You Sure, you want to add ${this.state.promotionamt}`);
    const { payments } = this.state;
    if (!this.state.promotionamt) return;
    e.preventDefault();
    Axios.post('/payment/promotionalpayment', {
      amount: this.state.promotionamt,
      customer_id: this.state.userid,
      reason: this.state.reason,
    })
      .then(({ data }) => {
        payments.push({
          amount: this.state.promotionamt,
          date: data.date,
          receipt_url: data.receipt_url,
        });
        this.setState({ payments, promotionamt: 0, isOpen: false });
        this.getUserDetails();
        SuccessToaster.fire(data.msg);
      })
      .catch((err) => {
        this.setState({ isOpen: false });
        ErrorToaster.fire('Error is updating balance');
      });
  };

  componentDidMount(prevProps) {
    const { userid } = this.state;
    Axios.get(`/payment/getpayments/${userid}`)
      .then(({ data }) => {
        this.setState({ payments: data });
      })
      .catch((err) => ErrorToaster.fire(err));

    this.getUserDetails();
    this.getUserRateData();
    // this.getRates();
  }

  getRates = async () => {
    try {
      const ratesResponse = await Axios.get('/rates');
      this.setState({ rates: ratesResponse.data.rates });
    } catch (err) {
      Confirmer.fire({ text: 'Error in getting Rates' });
    }
  };
  handleAssignRate = async () => {
    try {
      await this.getRates();
      this.setState({ assignRateModalOpen: true });
    } catch (err) {
      Confirmer.fire({ text: 'Error in assigning Rates' });
    }
  };

  handleAssignRateModalClose = () => {
    this.setState({ assignRateModalOpen: false });
  };

  getUserDetails = async () => {
    try {
      const { userid } = this.state;
      const { data } = await Axios.get(`/client1/clientinfo/${userid}`);
      this.setState({
        firstName: data.firstName,
        lastName: data.lastName,
        isSubscribed: data.isSubscribed,
        balance: data.balance,
        subscribedByAdmin: data.subscribedByAdmin,
        stripeCustomerId: data.stripeCustomerId,
      });
    } catch (err) {
      Confirmer.fire({ text: `Error in getting client's details` });
    }
  };

  getUserRateData = async () => {
    try {
      const { userid } = this.state;
      const userRateResponse = await Axios.get(`/rates/byClientId/${userid}`);
      this.setState({ userRate: userRateResponse.data.rate });
    } catch (err) {
      Confirmer.fire({ text: "Error in getting client's assigned rate." });
    }
  };

  openDialog = () => {
    var { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  closeDialog = () => {
    var { isOpen } = this.state;
    this.setState({ isOpen: false });
  };

  changeTabs = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  toggleSubscription = () => {
    var { subscribedByAdmin, userid } = this.state;
    Axios.get(`/client1/clientsubscription/${userid}/${!subscribedByAdmin}`)
      .then((response) => {
        ErrorToaster.fire(response);
      })
      .catch((err) => ErrorToaster.fire(err));
    this.setState({ subscribedByAdmin: !subscribedByAdmin });
  };

  render() {
    const {
      payments,
      isOpen,
      tabValue,
      subscribedByAdmin,
      firstName,
      lastName,
      balance,
      isSubscribed,
      stripeCustomerId,
      userRate,
    } = this.state;
    return (
      <>
        <ClientInfo
          firstName={firstName}
          lastName={lastName}
          balance={balance}
          isSubscribed={isSubscribed}
          subscribedByAdmin={subscribedByAdmin}
          stripeCustomerId={stripeCustomerId}
          userRate={userRate}
        />

        <div className="d-flex justify-content-around my-1">
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleAssignRate}
          >
            Assign New Rate
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={this.openDialog}
          >
            Add Balance
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={this.toggleSubscription}
          >
            {subscribedByAdmin ? 'Disable Subscription' : 'Enable Subscription'}
          </Button>
        </div>

        <Dialog
          onClose={this.closeDialog}
          style={{
            minHeight: '80vh',
            maxHeight: '80vh',
            minWidth: '80vh',
            maxWidth: '80vh',
            margin: 'auto',
          }}
          open={isOpen}
        >
          <AppBar position="static">
            <Tabs value={tabValue} onChange={this.changeTabs} name="tabValue">
              <Tab label="Charge Customer" {...a11yProps(0)} />
              <Tab label="Comp Customer" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          <DialogContent dividers>
            <TabPanel value={tabValue} index={0}>
              <Form title="Charge Customer Card">
                <form>
                  <div className="form-group">
                    <label>Amount</label>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">
                          $
                        </span>
                      </div>
                      <input
                        type="number"
                        className="form-control"
                        name="amt"
                        value={this.state.amt}
                        onChange={this.changeAmt}
                        min="0"
                        step="0"
                        oninput="validity.valid||(value='')"
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-block btn-primary"
                    onClick={this.payment}
                  >
                    Add
                  </button>
                </form>
              </Form>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <Form title="Add User Balance">
                <form>
                  <div className="form-group">
                    <label>Amount</label>
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon1">
                          $
                        </span>
                      </div>
                      <input
                        min="1"
                        type="number"
                        className="form-control"
                        name="promotionamt"
                        value={this.state.promotionamt}
                        onChange={this.change}
                      />
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      name="reason"
                      value={this.state.reason}
                      onChange={this.change}
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-block btn-primary"
                    onClick={this.promotionalPayment}
                  >
                    Add
                  </button>
                </form>
              </Form>
            </TabPanel>
          </DialogContent>
        </Dialog>
        <TableContainer style={{ minHeight: '75vh', maxHeight: '75vh' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Amount</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Taken</TableCell>
                <TableCell>Receipt</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payments
                ? payments.reverse().map((payment) => {
                    return (
                      <TableRow>
                        <TableCell>{payment.amount}</TableCell>
                        <TableCell>{payment.date.split('T')[0]}</TableCell>
                        <TableCell>{payment.date.split('T')[1]}</TableCell>
                        <TableCell>
                          {payment.receipt_url[0] === 'h' ? (
                            <a
                              className="text-primary"
                              rel="noopener noreferrer"
                              href={payment.receipt_url}
                              target="_blank"
                            >
                              Receipt
                            </a>
                          ) : (
                            payment.receipt_url
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })
                : ''}
            </TableBody>
          </Table>
        </TableContainer>
        <AssignRateModal
          assignRateModalOpen={this.state.assignRateModalOpen}
          handleModalClose={this.handleAssignRateModalClose}
          rates={this.state.rates}
          clientId={this.state.userid}
          getUserRateData={this.getUserRateData}
        />
      </>
    );
  }
}

export default Payments;

import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import LoadingSpinner from '../../utils/loader';
import { nanoid } from 'nanoid';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: '76vh',
  },
});

function DashboardTable(props) {
  const classes = useStyles();
  const {
    users,
    search,
    handleSearchChange,
    searchedAgents,
    totalListing,
    totalCrossListing,
    totalDelisting,
    isLoading,
  } = props;
  //const newusers = searchedUsers.length > 0 ? searchedUsers : users;

  const agentSearch =
    searchedAgents.length > 0 || search.length > 0 ? searchedAgents : users;
  return (
    <div>
      <div className="d-flex justify-content-between">
        <div>
          <Card style={{ width: '50rem', marginBottom: '1rem' }}>
            <CardContent>
              <Typography>
                <Grid className="d-flex justify-content-around">
                  <Grid>Total Listing - {totalListing}</Grid>
                  <Grid>Total CrossListing - {totalCrossListing}</Grid>
                  <Grid>Total Delisting - {totalDelisting}</Grid>
                </Grid>{' '}
              </Typography>
            </CardContent>
          </Card>
        </div>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            value={search}
            onChange={handleSearchChange}
            placeholder="Search"
          />
        </div>
      </div>

      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>UserName</TableCell>
                <TableCell>No. Of Listing</TableCell>
                <TableCell>No. Of CrossListing</TableCell>
                <TableCell>No. Of Delisting</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading ? (
                agentSearch ? (
                  agentSearch.map((user) => {
                    return (
                      <TableRow key={nanoid(3)}>
                        <TableCell>{user.username}</TableCell>
                        <TableCell>{user.totalListings.listing}</TableCell>
                        <TableCell>{user.totalListings.crosslisting}</TableCell>
                        <TableCell>{user.totalListings.delisting}</TableCell>
                        <TableCell>
                          <Link
                            style={{ width: '80px' }}
                            to={`/agentDashboard/${user._id}`}
                            className="btn btn-warning btn-sm ml-3"
                          >
                            View Details
                          </Link>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <tr>
                    <td>There are no agents...</td>
                  </tr>
                )
              ) : (
                <div className="center">
                  <LoadingSpinner asOverlay />
                </div>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        /> */}
      </Paper>
    </div>
  );
}

export default DashboardTable;

const doesInclueds = (obj, string) => {
  let values;
  if (obj) values = Object.values(obj);
  else return false;
  string = string.toLowerCase();
  for (const value of values) {
    if (typeof value === 'object' && doesInclueds(value, string)) return true;
    if (value) {
      const bool = value.toString().toLowerCase().includes(string);

      if (value && bool) return true;
    }
  }
  return false;
};

/**
 * Filter users array based on search string in the first name, last name, or email field
 * @param {Array[Objects]} users  Array of users objects
 * @param {String} searchString   String to search for
 * @returns {Array[Objects]}      Array of filtered user objects based on search string
 */
export const searchUsers = (users, searchString) => {
  return users.filter((user) => {
    const lowerSearch = searchString.toLowerCase().trim();
    return (
      user.firstName.toLowerCase().includes(lowerSearch) ||
      user.lastName.toLowerCase().includes(lowerSearch) ||
      user.email.toLowerCase().includes(lowerSearch)
    );
  });
};

const Search = (arr, string) => {
  const newArr = arr.filter((a) => doesInclueds(a, string));
  return newArr;
};

export default Search;

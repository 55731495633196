import PouchDB from 'pouchdb-browser';

const db = new PouchDB('hammoq');

db.info()
  .then(function (info) {
    console.log(info);
  })
  .catch(() => {
    alert(
      'Hammoq requires the use of Chrome v30, Firefox v29, Opera v21, Safari v5 or above to function. Please download a new browser now and come right back!'
    );
  });

export default db;

import React, { useState, useEffect } from 'react';
import Axios, { assetsURL, baseURL, get, post } from '../../../services/Axios';
import { on, off, trigger } from '../../../services/event';
import { ErrorToaster, SuccessToaster } from '../../../services/SweetAlerts';
import './MessagesTextArea.css';
import jwt_decode from 'jwt-decode';

const MessagesTextArea = (props) => {
  const [message, setMessage] = useState('');
  const [uploadedFile, setUploadedFile] = useState('');
  const [uploadingFile, setUploadingFile] = useState(false);

  const showFile = ({ detail }) => {
    const url = assetsURL + detail.data.id;
    setUploadedFile(url || '');
    setUploadingFile(false);
  };

  useEffect(() => {
    on('image:complete', showFile);
    return () => {
      off('image:complete', showFile);
    };
  }, [props]);
  const sendMessage = async () => {
    if (uploadingFile) return;

    if (uploadedFile.trim().length > 0) {
      const token = localStorage.getItem('token');
      const decoded = jwt_decode(token);
      const adminUserId = decoded._id;
      await Axios.post('/messagesNeo/sendMessage', {
        message: { messageType: 'File', messageBody: uploadedFile.trim() },
        fromUserId: adminUserId,
        toUserIds: [props.selectedTab.tabId],
      });

      // setMessage("");
      // setUploadedFile("");
      // props.refresh();
      // return;
    }

    if (message.trim().length > 0) {
      const token = localStorage.getItem('token');
      const decoded = jwt_decode(token);
      const adminUserId = decoded._id;
      await Axios.post('/messagesNeo/sendMessage', {
        message: { messageType: 'Text', messageBody: message.trim() },
        fromUserId: adminUserId,
        toUserIds: [props.selectedTab.tabId],
      });

      // setMessage("");
      // setUploadedFile("");
      // props.refresh();
      //props.setRefreshDisplayArea(true);
    }

    setMessage('');
    setUploadedFile('');
    props.refresh();
    return;
  };

  const signImage = async ({ fileName, body }) => {
    const query = { fileName };
    const data = body
      ? await post(`${baseURL}/media/sign`, body)
      : await get(`${baseURL}/media/sign`, query);
    if (data.error) {
      ErrorToaster.fire({
        title: data.error,
      });
      return data;
    }
    return data;
  };

  const uploadImage = async (file) => {
    const data = await signImage({ fileName: file.name });
    if (data.presignedURL) {
      trigger('image:append', { data, file });
    }
    if (data.error && data.error.includes('Network')) {
      ErrorToaster.fire({
        title: 'No internet connection! unable to upload images',
      });
    }
    return data;
  };

  const onChangeHandler = async (event) => {
    // setMessage('')
    setUploadingFile(true);
    const data = await uploadImage(event.target.files[0]);
    const url = assetsURL + data.id;

    // setUploadedFile(url || "");
    setUploadingFile(false);
  };

  return (
    <div className="message-textarea-container">
      <div className="message-textarea">
        <textarea
          className="message-textarea-input"
          placeholder="Message HAMMOQ support"
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          onKeyPress={(event) => {
            if (event.key === 'Enter') sendMessage();
          }}
        />
      </div>
      <div className="message-textarea-controls">
        <span className="message-icons-right">
          <button
            className="send-message-button"
            onClick={sendMessage}
            disabled={uploadingFile}
          >
            <i
              className="fas fa-paper-plane"
              style={{ margin: '5px', color: 'white' }}
            ></i>
          </button>
        </span>
        <span className="message-icons">
          {uploadedFile.trim().length > 0 ? (
            <img
              alt="messageTextArea"
              src={uploadedFile}
              height={35}
              width={35}
              style={{ marginLeft: '10px' }}
            ></img>
          ) : null}
          {uploadingFile === true ? (
            <i
              className="fa fa-upload"
              aria-hidden="true"
              style={{ marginLeft: '10px', color: '#8E7DBE' }}
            />
          ) : null}
          <button
            className="message-attachment-button"
            style={{ display: 'inline-flex', padding: '5px' }}
            disabled={uploadingFile}
          >
            <span style={{ position: 'relative' }}>
              <input
                type="file"
                name="file"
                accept="image/*"
                onChange={onChangeHandler}
                onClick={(event) => (event.target.value = '')}
                style={{ opacity: 0, position: 'absolute', width: '100%' }}
              />
              <i
                className="fas fa-paperclip"
                style={{ margin: '5px', color: 'white' }}
              />
            </span>
          </button>
        </span>
      </div>
    </div>
  );
};

export default MessagesTextArea;

import PropTypes from 'prop-types';
import { useCallback, useEffect } from 'react';
import { useProductsActions } from '../../../redux/Products';

const ProductChangeTrigger = ({
  products,
  currentPage,
  totalPage,
  rowsPerPage,
  prodStatus,
}) => {
  const { doSetProductsIdsAndPaginationData } = useProductsActions();

  const updateProducts = useCallback(
    (items, curPage, tot, _rowsPerPage, _prodStatus) => {
      doSetProductsIdsAndPaginationData(
        items,
        curPage,
        tot,
        _rowsPerPage,
        _prodStatus
      );
    },
    []
  );

  useEffect(() => {
    if (products)
      updateProducts(products, currentPage, totalPage, rowsPerPage, prodStatus);
  }, [
    products,
    updateProducts,
    currentPage,
    totalPage,
    rowsPerPage,
    prodStatus,
  ]);

  return null;
};

ProductChangeTrigger.propTypes = {
  products: PropTypes.array.isRequired,
};

export default ProductChangeTrigger;

import React, { useState } from 'react';

import { Tabs, Tab, AppBar } from '@material-ui/core';

import MaintenanceForm from './Components/MaintenanceForm';
import RecentMaintenanceAlert from './Components/RecentMaintenanceAlert';
import './Maintenance.css';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Maintenance = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div className="maintenance">
      <AppBar position="static">
        <Tabs
          value={activeTab}
          onChange={handleChange}
          aria-label="Maintenance Tabs"
          variant="fullWidth"
          className="maintenance-tabs"
        >
          <Tab wrapped={false} label="Set Maintenance Mood" {...a11yProps(0)} />
          <Tab wrapped={false} label="Maintenance Schedule" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={activeTab} index={0}>
        <MaintenanceForm />
      </TabPanel>
      <TabPanel className="table-container" value={activeTab} index={1}>
        <RecentMaintenanceAlert />
      </TabPanel>
    </div>
  );
};

export default Maintenance;

import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { SuccessToaster } from 'src/services/SweetAlerts';
import axios from '../../../services/Axios';

export default function AddUpdateRateModal({
  deleteRateModalOpen,
  handleModalClose,
  getRates,
  rateId,
}) {
  const handleDeleteRate = async () => {
    try {
      if (rateId) {
        await axios.delete(`/rates/${rateId}`);
      }

      SuccessToaster.fire({ text: 'Rate Deleted Successfully' });
      handleModalClose();
      getRates();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.msg) {
        SuccessToaster.fire({ text: err.response.data.msg });
      } else {
        SuccessToaster.fire({ text: 'Something went wrong' });
      }
    }
  };
  return (
    <Modal show={deleteRateModalOpen} centered onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Rate</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this rate?</Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <button className="btn btn-outline-danger" onClick={handleDeleteRate}>
          Delete Rate
        </button>
      </Modal.Footer>
    </Modal>
  );
}

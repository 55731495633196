import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from '../../../services/Axios';
import Table from 'react-bootstrap/Table';
import {
  Confirmer,
  ErrorToaster,
  SuccessToaster,
} from 'src/services/SweetAlerts';
import CustomCheckBox from '../../utils/CustomCheckBox';

export default function ProductTransferModal({
  selectedProducts,
  handleProductTransfer,
}) {
  const [newUser, setNewUser] = useState('');
  const [ProductTransferModalOpen, setProductTransferModalOpen] =
    useState(false);
  const [users, setUsers] = useState('');
  const [selectedUsers, setSelectedUsers] = useState('');
  const [selectedUsersEmail, setSelectedUsersEmail] = useState('');

  const handleModalOpen = () => {
    setProductTransferModalOpen(true);
  };
  const handleModalClose = () => {
    setProductTransferModalOpen(false);
  };
  const selectUserHandler = (e, user) => {
    if (e.target.checked) {
      setSelectedUsers(user._id.toString());
      setSelectedUsersEmail(user.email.toString());
    } else {
      setSelectedUsers('');
      setSelectedUsersEmail('');
    }
  };
  const isUserSelected = (user) => {
    return selectedUsers.length > 0 && selectedUsers === user._id.toString();
  };
  const handleSearchNewUser = () => {
    if (!newUser) return Confirmer.fire({ text: 'Email is required' });

    axios
      .get(`/client1`, {
        params: {
          searchText: newUser,
          limit: 50,
          offset: 0,
          filterColumn: '',
          contextFilterColumn: '',
          filterType: '',
          contextFilterType: '',
          firstTimeInputValue: '',
          contextFirstTimeInputValue: '',
          secondTimeInputValue: '',
          contextSecondTimeInputValue: '',
        },
      })
      .then(({ data }) => {
        setUsers(data.data);
      })
      .catch(function (err) {
        ErrorToaster.fire(
          'Something went wrong while searching customer.' + err
        );
      });
  };
  const handleTransferItemSubmit = () => {
    if (!selectedUsers)
      return Confirmer.fire({ text: 'Please select the target customer.' });
    Confirmer.fire({
      text: `You are about to transfer these items to the ${selectedUsersEmail}. Are you sure you want to do this?`,
      confirmButtonText: 'Yes',
      showCloseButton: true,
      showDenyButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const reqdata = {
          clientId: selectedUsers,
          productIds: selectedProducts,
        };
        axios
          .put(`/wholesale/transfer-product`, reqdata)
          .then((resdata) => {
            if (resdata.data.productsTransfered.ok) {
              selectedProducts = [];
              SuccessToaster.fire({
                text: 'Item(s) transferred successfully!',
              });
              handleProductTransfer();
              handleModalClose();
            } else {
              ErrorToaster.fire('Something went wrong while transfer item(s).');
            }
          })
          .catch(function (err) {
            ErrorToaster.fire(
              'Something went wrong while transfer item(s).' + err
            );
          });
      }
    });
  };
  return (
    <div>
      {selectedProducts.length > 0 && (
        <div className="d-flex py-2 px-2">
          <div className="form-group">
            <button
              className="btn btn-primary "
              onClick={() => handleModalOpen()}
            >
              Transfer Item(s)
            </button>
          </div>
        </div>
      )}
      <Modal
        show={ProductTransferModalOpen}
        size="xl"
        centered
        onHide={handleModalClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Transfer Item(s)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3 form-group row">
            <div className="col-sm-2">
              <label htmlFor="newUser" className="form-label col-form-label">
                User's Email:
              </label>
            </div>
            <div className="col-sm-5">
              <input
                type="text"
                name="newUser"
                value={newUser}
                placeholder="User Search By Email Address"
                className="form-control"
                onChange={(evt) => setNewUser(evt.target.value)}
              />
            </div>
            <div className="col-sm-3">
              <button
                className="btn btn-primary "
                onClick={handleSearchNewUser}
              >
                Search
              </button>
            </div>
          </div>
          <div className="row">
            <div className="form-group"></div>
          </div>

          {users.length > 0 ? (
            <Table bordered hover size="sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Action</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>
                        <CustomCheckBox
                          onChange={(e) => selectUserHandler(e, user)}
                          checked={isUserSelected(user)}
                        />
                      </td>
                      <td>{user.firstName}</td>
                      <td>{user.lastName}</td>
                      <td>{user.email}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <div className="w-50 mx-auto py-2">
              <div className="d-flex justify-content-center">No User Found</div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <button
            className="btn btn-outline-primary"
            onClick={handleTransferItemSubmit}
          >
            Transfer the Selected Item(s)
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

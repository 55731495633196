import constants from './constants';
export const getJSON = (value, defaultValue = {}) => {
  try {
    if (typeof value === 'string') {
      return JSON.parse(value);
    }
    if (typeof value === 'object') {
      return value;
    }
    return defaultValue;
  } catch (error) {
    return defaultValue;
  }
};

export const getStatus = (value, marketplace = {}) => {
  const label = marketplace.label || value.name;
  const listed = value.url && value.url !== '';
  const status = listed
    ? constants.STATUSES.LISTED
    : constants.STATUSES.NOT_LISTED;
  const message = [label, status].join(' - ');
  return { message, listed, value };
};

export const getProductMarketPlaces = (product) => {
  const otherMarketplacesRes = getJSON(product.others, []);
  const otherMarketplaces = otherMarketplacesRes ? otherMarketplacesRes : [];
  const others = otherMarketplaces.map((marketplace) => {
    return getStatus(marketplace);
  });
  const common = constants.MARKETPLACES.map((marketplace) => {
    const value = getJSON(product[marketplace.key]);
    return { value, marketplace };
  })
    .filter(({ value }) => value && value.check)
    .map(({ value, marketplace }) => getStatus(value, marketplace));
  return common.concat(others);
};

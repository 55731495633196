import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Axios from '../../services/Axios';

import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import './Productlogs.css';
export default function Productlogs() {
  const { productId } = useParams();
  const [productChangeEntries, setProductChangeEntries] = useState([]);
  useEffect(() => {
    getProductData();
  }, []);

  var getProductData = async () => {
    try {
      const response = await Axios.get(`/product/getproductlog/${productId}`);
      if (response?.data?.data?.productChangeEntries) {
        setProductChangeEntries(response.data.data.productChangeEntries);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const TableDataRow = ({ productChangeEntrie, pceIdx }) => {
    const [open, setOpen] = React.useState(false);
    return (
      <>
        <TableRow style={{ backgroundColor: open ? 'lightgrey' : 'white' }}>
          <TableCell>{pceIdx + 1}</TableCell>
          <TableCell>
            {new Date(productChangeEntrie.createdAt).toDateString()}
          </TableCell>
          <TableCell>
            {productChangeEntrie.userType}
            {productChangeEntrie.emailUsername !== ''
              ? `(${productChangeEntrie.emailUsername})`
              : ''}
          </TableCell>
          <TableCell>{productChangeEntrie.fieldChanges.length}</TableCell>
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={5} style={{ paddingBottom: 0, paddingTop: 0 }}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <div
                style={{
                  padding: '0px 25px',
                  maxHeight: '75vh',
                  overflowX: 'auto',
                }}
              >
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className="tableHead">#</TableCell>
                      <TableCell className="tableHead">Field Name</TableCell>
                      <TableCell className="tableHead">
                        Previous Value
                      </TableCell>
                      <TableCell className="tableHead">Old Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {productChangeEntrie.fieldChanges.map(
                      (fieldChange, fcIdx) => {
                        return (
                          <TableRow>
                            <TableCell>{fcIdx + 1}</TableCell>
                            <TableCell>{fieldChange.fieldName}</TableCell>
                            <TableCell>
                              {fieldChange.beforeValueType == 'string'
                                ? fieldChange.beforeValue.length > 25
                                  ? fieldChange.beforeValue
                                  : fieldChange.beforeValue
                                : ''}
                            </TableCell>
                            <TableCell>
                              {fieldChange.afterValueType == 'string'
                                ? fieldChange.afterValue.length > 100
                                  ? fieldChange.afterValue.slice(0, 100) + '...'
                                  : fieldChange.afterValue
                                : ''}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    )}
                  </TableBody>
                </Table>
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  console.log(productChangeEntries);
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table" size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className="tableHead">#</TableCell>
            <TableCell className="tableHead">Date</TableCell>
            <TableCell className="tableHead">User</TableCell>
            <TableCell className="tableHead">Field Changes Count</TableCell>
            <TableCell className="tableHead" />
          </TableRow>
        </TableHead>
        <TableBody>
          {productChangeEntries.map((productChangeEntrie, pceIdx) => (
            <TableDataRow
              productChangeEntrie={productChangeEntrie}
              pceIdx={pceIdx}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

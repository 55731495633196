import React, { Component } from 'react';
import Form from '../../Components/Form';
import BackButton from '../../Components/BackButton';
import Axios from '../../services/Axios';
import SweetAlerts from 'src/services/SweetAlerts';

class EditAgent extends Component {
  constructor() {
    super();
    this.state = {
      agent: {},
    };
  }

  componentDidMount() {
    const { agentid } = this.props.match.params;
    Axios.get(`/agent/${agentid}`)
      .then(({ data }) => {
        data.oldPassword = data.password;
        delete data.password;
        this.setState({ agent: data });
      })
      .catch((err) => console.log(err));
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    const { agent } = this.state;
    agent[name] = value;
    this.setState({ agent });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { agentid } = this.props.match.params;
    if (!this.state.agent.password) {
      return SweetAlerts.ErrorToaster.fire({
        icon: 'error',
        title: 'Please provide the password',
      });
    }

    Axios.put(`/agent/${agentid}`, this.state.agent)
      .then(() => this.props.history.push('/agent'))
      .catch((err) => console.log(err));
  };

  render() {
    const { username, password } = this.state.agent;
    return (
      <div className="col-6 m-auto">
        <BackButton />
        <Form title="Edit Agent Password">
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <label>Username</label>
              <input
                type="text"
                value={username || ''}
                onChange={this.handleChange}
                name="username"
                disabled
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                type="text"
                value={password || ''}
                onChange={this.handleChange}
                name="password"
                className="form-control"
              />
            </div>
            <button type="submit" className="btn btn-block btn-primary">
              Edit
            </button>
          </form>
        </Form>
      </div>
    );
  }
}

export default EditAgent;

import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import axios from '../../../services/Axios';
import {
  Confirmer,
  ErrorToaster,
  SuccessToaster,
} from 'src/services/SweetAlerts';

export default function AssignRateModal({
  assignRateModalOpen,
  handleModalClose,
  rates,
  clientId,
  getUserRateData,
}) {
  const [selectedRate, setSelectedRate] = useState('');

  const handelAssignRate = async () => {
    try {
      if (selectedRate == '')
        return Confirmer.fire({ text: 'Please select a rate!' });
      const assignRateRes = await axios.post('/rates/addClientRate', {
        rateId: selectedRate,
        clientId,
      });
      SuccessToaster.fire({ text: assignRateRes.data.msg });
      getUserRateData();
      handleModalClose();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.msg) {
        return ErrorToaster.fire({ text: err.response.data.msg });
      }
      return ErrorToaster.fire({ text: 'Error in assigning rate' });
    }
  };
  return (
    <Modal
      show={assignRateModalOpen}
      centered
      size="lg"
      onHide={handleModalClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>Assign New Rate</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {rates.length > 0 ? (
          <Table bordered hover size="sm">
            <tr>
              <th></th>
              <th>Name</th>
              <th>List Rate</th>
              <th>Cross-List Rate</th>
              <th>De-List Rate</th>
            </tr>
            {rates.map((rate) => {
              return (
                <tr>
                  <td>
                    <Form.Check
                      type="radio"
                      value={selectedRate}
                      name="rate"
                      onChange={(evt) => {
                        setSelectedRate(rate._id);
                      }}
                    />
                  </td>
                  <td>
                    {rate.name}{' '}
                    {rate.isDefault ? (
                      <span
                        className="p-1 bg-info"
                        style={{
                          color: 'white',
                          borderRadius: '4px',
                        }}
                      >
                        Default
                      </span>
                    ) : null}
                  </td>
                  <td>{rate.list}</td>
                  <td>{rate.crossList}</td>
                  <td>{rate.deList}</td>
                </tr>
              );
            })}
          </Table>
        ) : (
          <>No Rates available...</>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <button className="btn btn-outline-primary" onClick={handelAssignRate}>
          Assign Rate
        </button>
      </Modal.Footer>
    </Modal>
  );
}

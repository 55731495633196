import React from 'react';

const Form = ({ title, children }) => {
  return (
    <div className="card add-agent-form" style={{ height: 'auto' }}>
      <div className="card-header">
        <h5>{title}</h5>
      </div>
      <div className="card-body">{children}</div>
    </div>
  );
};

export default Form;

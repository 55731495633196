import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: '76vh',
  },
});

function ListingDetailsTable(props) {
  const classes = useStyles();
  const { users } = props;
  //const newusers = searchedUsers.length > 0 ? searchedUsers : users;
  return (
    <div>
      {/* <div className="d-flex justify-content-between">
      <div>
      <Link
                    style={{ width: "80px" }}
                    to={`/AddUser`}
                    className="btn btn-primary btn-sm ml-3"
                  >
                    Add User
      </Link>
      </div>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            value={search}
            onChange={handleSearchChange}
            placeholder="Search"
          />
        </div>
      </div> */}

      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>Dates</TableCell>
                <TableCell>No. Of Listing</TableCell>
                <TableCell>No. Of CrossListing</TableCell>
                <TableCell>No. Of Delisting</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => {
                return (
                  <TableRow>
                    <TableCell>{user.date}</TableCell>
                    <TableCell>{user.listing}</TableCell>
                    <TableCell>{user.delisting}</TableCell>
                    <TableCell>{user.crosslisting}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        /> */}
      </Paper>
    </div>
  );
}

export default ListingDetailsTable;

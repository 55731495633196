import React from 'react';
import './Style.css';

class AnalyticCard extends React.Component {
  state = {};
  render() {
    const { title, number, timeframe } = this.props;

    return (
      <div className="card container">
        <div style={{ height: '100%', width: '100%' }} className="card-body">
          <sup
            style={{
              verticalAlign: '',
              position: 'relative',
              float: 'right',
              right: '-1rem',
            }}
          >
            {timeframe} Days
          </sup>
          <div
            style={{ padding: '0', paddingTop: '.5rem' }}
            className="row card-header justify-content-center"
          >
            {title}
          </div>
          <div className="row card-number justify-content-center">
            {number || 0}
          </div>
        </div>
      </div>
    );
  }
}

export default AnalyticCard;

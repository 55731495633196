import React, { useState, useEffect } from 'react';
import './OtherMessagesSection.css';
import OtherMessagesSectionAccordian from './OtherMessagesSectionAccordian';
import OtherMessagesClientList from './OtherMessagesClientList';
import { nanoid } from 'nanoid';

const OtherMessagesSection = React.forwardRef((props, ref) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [filterType, setFilterType] = useState(
    'FILTER_UNREAD_MESSAGES_OLDEST_FIRST'
  );
  const [refreshOtherMessagesClientList, setRefreshOtherMessagesClientList] =
    useState('');

  useEffect(() => {
    setIsCollapsed(!props.otherMessagesSectionExpanded);
  }, [props.otherMessagesSectionExpanded]);

  useEffect(() => {
    setRefreshOtherMessagesClientList(nanoid(5));
  }, [props.newMessagesClients]);

  return (
    <>
      <div id="otherMessagesSection" ref={ref} style={{ width: '100%' }}>
        <OtherMessagesSectionAccordian
          setOtherMessagesAccordianHeight={
            props.setOtherMessagesAccordianHeight
          }
          isCollapsed={isCollapsed}
          handleCollapse={(value) => {
            setIsCollapsed(value);
            props.setOtherMessagesSectionExpanded(!value);
          }}
          setFilterType={setFilterType}
          filterType={filterType}
          setRefreshOtherMessagesClientList={setRefreshOtherMessagesClientList}
        />
        {isCollapsed === false ? (
          <OtherMessagesClientList
            otherMessagesSectionHeight={props.otherMessagesSectionHeight}
            selectedClient={props.selectedClient}
            setSelectedClient={props.setSelectedClient}
            selectedTab={props.selectedTab}
            newMessagesClients={props.newMessagesClients}
            setOtherMessagesClients={props.setOtherMessagesClients}
            clientAndTotalUnreadMessagesMap={
              props.clientAndTotalUnreadMessagesMap
            }
            searchText={props.searchText}
            filterType={filterType}
            refreshOtherMessagesClientList={refreshOtherMessagesClientList}
          />
        ) : null}
      </div>
    </>
  );
});

export default OtherMessagesSection;

import { ListingSettings } from '@hammoq-inc/hammoq-recycledcomponents-package';
import React from 'react';
import SweetAlerts from 'src/services/SweetAlerts';
import Axios from '../../services/Axios';

const ListingSettingsPortal = (props) => {
  let { clientid } = props.match.params;

  return (
    <ListingSettings
      Axios={Axios}
      clientid={clientid}
      SweetAlerts={SweetAlerts}
    />
  );
};

export default ListingSettingsPortal;

import React, { useState } from 'react';
import Form from '../../../Components/Form';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const AllocateForm = ({ allocateClient, clients }) => {
  const [clientId, setClientId] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    allocateClient(clientId);
  };

  var options = clients.map((client) => {
    return { name: client.firstName + ' ' + client.lastName, id: client._id };
  });

  const handleChange = (id) => {
    setClientId(id);
  };

  return (
    <Form title="Allocate Client">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <Autocomplete
            freeSolo
            options={options}
            getOptionLabel={(option) => option.name}
            defaultValue={options}
            onChange={(event, newValue) => {
              if (newValue != null) {
                handleChange(newValue.id);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Customer Name"
                margin="noraml"
                variant="outlined"
              />
            )}
          />
        </div>
        <button type="submit" className="btn btn-block btn-primary">
          Allocate
        </button>
      </form>
    </Form>
  );
};

export default AllocateForm;

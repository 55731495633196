import React from 'react';
import { nanoid } from 'nanoid';
import BackButton from '../../../Components/BackButton';

const ClientTable = ({ allocatedClients, deAllocateClient }) => {
  return (
    <div>
      <div className="d-flex justify-content-between">
        <BackButton />
      </div>
      <table className="table text-center">
        <thead>
          <tr>
            <th>Name</th>
            <th>Products listed</th>
            <th>Products not listed</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {allocatedClients.map((client) => {
            return (
              <tr key={nanoid(5)}>
                <td>{client.firstName + ' ' + client.lastName}</td>
                <td>{client.noOfListings}</td>
                <td>{client.draftCount + client.submittedCount}</td>
                <td
                  className="d-flex justify-content-around text-white"
                  onClick={() => deAllocateClient(client._id)}
                >
                  <div
                    className="bg-danger rounded p-1 px-2"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Deallocate"
                  >
                    <i className="fas fa-trash-alt"></i>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ClientTable;

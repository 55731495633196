import React, { Component } from 'react';
import './Agent.css';
import AddAgent from './Component/AddAgent';
import AgentTable from './Component/AgentTable';
import Axios from '../../services/Axios';
import Search from '../utils/Search';
import {
  Confirmer,
  ErrorToaster,
  SuccessToaster,
} from 'src/services/SweetAlerts';

const $ = window.$;

class Agent extends Component {
  constructor() {
    super();
    this.state = {
      agents: [],
      searchedAgents: [],
      search: '',
    };
    this.getAgentData = this.getAgentData.bind(this);
  }
  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip();
    this.getAgentData();
  }

  getAgentData() {
    Axios.get('/agent')
      .then((res) => {
        this.setState({ agents: res.data.agents || [] });
      })
      .catch((err) => console.log(err));
  }

  componentWillUnmount() {
    // hides the tooltip when navigating to another page.
    $('[data-toggle="tooltip"]').tooltip('dispose');
  }

  addAgent = (body) => {
    Axios.post('/agent', body)
      .then(({ data }) => {
        this.getAgentData();
        SuccessToaster.fire('Agent added successfully');
      })
      .catch((err) => {
        ErrorToaster.fire(err?.response?.data?.error || 'Something went wrong');
      });
  };

  deleteAgent = async (agentid) => {
    const result = await Confirmer.fire({
      text: 'Are you sure you want to delete agent?',
      showConfirmButton: true,
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No',
    });
    if (result.isConfirmed) {
      Axios.delete(`/agent/${agentid}`)
        .then(() => {
          const { agents } = this.state;
          const idx = agents.findIndex((agent) => agent._id === agentid);
          agents.splice(idx, 1);
          this.setState({ agents });
        })
        .catch((err) => console.log(err));
    }
  };

  handleSearchChange = (e) => {
    const { value } = e.target;
    const { agents } = this.state;
    this.setState({ searchedAgents: Search(agents, value), search: value });
  };

  render() {
    const { searchedAgents, search, agents } = this.state;

    return (
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-6">
          <div
            style={{
              marginRight: '20px',
            }}
          >
            <AgentTable
              searchedAgents={searchedAgents}
              search={search}
              handleSearchChange={this.handleSearchChange}
              agents={agents}
              deleteAgent={this.deleteAgent}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div style={{ display: 'inline-block' }}>
            <AddAgent addAgent={this.addAgent} />
          </div>
        </div>
      </div>
    );
  }
}

export default Agent;

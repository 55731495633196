import React, { Component } from 'react';
import { nanoid } from 'nanoid';
import Axios from '../services/Axios';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import IconButton from '@material-ui/core/IconButton';
import { Confirmer, ErrorToaster } from 'src/services/SweetAlerts';

class ViewPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sites: [],
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      ebayAccounts: [],
    };
  }

  getData = async () => {
    const { clientid } = this.props.match.params;
    Axios.get(`/client1/password/${clientid}`)
      .then(({ data }) => this.setState({ sites: data.passwords || [] }))
      .catch(() =>
        ErrorToaster.fire({
          text: 'There is an issue with fetching password details',
        })
      );

    Axios.get(`/client1/clientinfo/${clientid}`)
      .then(({ data }) =>
        this.setState({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phoneNumber: data.phoneNumber,
        })
      )
      .catch(() =>
        ErrorToaster.fire({
          text: 'There is an issue with fetching client information',
        })
      );

    await Axios.get(`/ebayAuth/ebayaccounts/${clientid}`)
      .then(async (data) => {
        this.setState({ ebayAccounts: data.data } || []);
      })
      .catch(() =>
        ErrorToaster.fire({ text: 'Ebay accounts information not found' })
      );
  };

  componentDidMount = () => {
    this.getData();
  };

  refreshEbayUserToken = async (ebayUserName) => {
    try {
      const { clientid } = this.props.match.params;
      await Axios.post('/ebayAuth/refreshtokenbyuserid/' + ebayUserName, {
        userId: clientid,
      });
      Confirmer.fire({ text: 'Token refreshed successfully.' });
    } catch (err) {
      ErrorToaster.fire({ text: 'Error in refreshing token' });
    } finally {
      this.getData();
    }
  };

  goToPreviousPath = () => {
    this.props.history.goBack();
  };

  render() {
    const { firstName, lastName, email, phoneNumber, sites, ebayAccounts } =
      this.state;
    return (
      <div>
        <div className="row">
          <div className="col-4 m-auto">
            <IconButton aria-label="close" onClick={this.goToPreviousPath}>
              <KeyboardBackspaceIcon />
            </IconButton>
            <div>
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone Number</th>
                  </tr>
                </thead>
                <tbody>
                  <tr key={nanoid(5)}>
                    <td>{firstName || 'N/A'}</td>
                    <td>{lastName || 'N/A'}</td>
                    <td>{email || 'N/A'}</td>
                    <td>{phoneNumber || 'N/A'}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="text-center">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">Domain</th>
                    <th scope="col">Username</th>
                    <th scope="col">Password</th>
                    <th scope="col">Token State</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {sites && sites.length > 0
                    ? sites.map((site) => (
                        <tr key={nanoid(5)}>
                          <td>{site.website}</td>
                          <td>{site.username}</td>
                          <td>{site.password}</td>
                          <td></td>
                          <td></td>
                        </tr>
                      ))
                    : ' '}
                  {ebayAccounts?.length > 0 &&
                    ebayAccounts.map((ebayAccount) => (
                      <tr key={nanoid(5)}>
                        <td>Ebay</td>
                        <td>{ebayAccount.ebayUserName}</td>
                        <td>Connected via oAuth</td>
                        <td>
                          {' '}
                          {ebayAccount?.ebayUserTokenStatus ? (
                            <span className="badge badge-pill badge-success">
                              Active
                            </span>
                          ) : (
                            <span className="badge badge-pill badge-danger">
                              Inactive
                            </span>
                          )}
                        </td>
                        <td>
                          <button
                            className="btn btn-sm btn-light"
                            onClick={() =>
                              this.refreshEbayUserToken(
                                ebayAccount?.ebayUserName
                              )
                            }
                          >
                            <i
                              className="fas fa-sync"
                              style={{ color: '#7EE081' }}
                            ></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ViewPassword;

import React from 'react';
import NewRule from './Components/NewRule';

function AddRules() {
  return (
    <div>
      <h1>Create new rule</h1>
      <div>
        <NewRule />
      </div>
    </div>
  );
}

export default AddRules;

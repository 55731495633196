import React from 'react';
import UserTable from './Components/UserTable';
import Axios from '../../services/Axios';
import { Route } from 'react-router-dom';
import UsersRecycleBinTable from './Components/UsersRecycleBinTable';

const Users = () => {
  return (
    <div>
      <Route exact path="/users">
        <UserTable />
      </Route>
      <Route exact path="/users/recyclebin">
        <UsersRecycleBinTable />
      </Route>
    </div>
  );
};

export default Users;

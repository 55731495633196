import React, { useState, useEffect } from 'react';
import Axios from '../../../services/Axios';
import Alert from '@material-ui/lab/Alert';
import { Grid, Table } from '@material-ui/core';

const RecentMaintenanceAlert = () => {
  const [errMsg, setErrMsg] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [recentAlert, setRecentAlert] = useState({});

  useEffect(() => {
    Axios.get('/maintenance/alerts')
      .then((res) => {
        console.log(res.data);
        if (res.data.err) {
          return setErrMsg(res.data.err);
        }
        console.log(recentAlert);
        setRecentAlert(res.data);
        console.log(recentAlert);
      })
      .catch((err) => {
        const alertMsg = err?.response?.data?.msg
          ? err.response.data.msg
          : err?.response?.data?.err
          ? err.response.data.err
          : 'Something went wrong while fetching the set schedule.';
        setErrMsg(alertMsg);
        setShowAlert(true);
      });
  }, []);

  const deleteAlert = (id) => {
    Axios.delete(`/maintenance/deleteAlert/${id}`)
      .then((res) => {
        console.log(res.data);
        if (res.data.err) {
          return setErrMsg(res.data.err);
        }
        const newAlert = recentAlert.filter((alert) => alert._id !== id);
        setRecentAlert(newAlert);
      })
      .catch((err) => {
        const alertMsg = err?.response?.data?.msg
          ? err.response.data.msg
          : err?.response?.data?.err
          ? err.response.data.err
          : 'Something went wrong while deleting the Alert.';
        setErrMsg(alertMsg);
        setShowAlert(true);
      });
  };

  return (
    <div className="recent-alert-container">
      <div
        style={{
          visibility: showAlert ? 'visible' : 'hidden',
        }}
        className="alert-msg"
      >
        {errMsg && showAlert && (
          <Alert
            elevation={6}
            variant="filled"
            onClose={() => setShowAlert(false)}
            severity="error"
          >
            {errMsg}
          </Alert>
        )}
      </div>
      {recentAlert.length > 0 ? (
        <Table className="maintenance-table text-center  table-hover c-pointer">
          <thead>
            <tr>
              <th className="col-title">#</th>
              <th className="col-title">Start</th>
              <th className="col-title">End</th>
              <th className="col-title">Down Time</th>
              <th className="col-title">Message</th>
              <th className="col-title">Action</th>
            </tr>
          </thead>
          <tbody>
            {recentAlert.map((alert, i) => {
              return (
                <tr key={`row-${alert._id}`}>
                  <td>{i + 1}</td>
                  <td className="date">
                    {alert.startDate
                      .split('T')[1]
                      .split('.')[0]
                      .split(':')
                      .slice(0, 2)
                      .join(':') +
                      ' ' +
                      alert.startDate.split('T')[0]}
                  </td>
                  <td className="date">
                    <>
                      <span>
                        {alert.endDate
                          .split('T')[1]
                          .split('.')[0]
                          .split(':')
                          .slice(0, 2)
                          .join(':') +
                          ' ' +
                          alert.endDate.split('T')[0]}
                      </span>
                    </>
                  </td>
                  <td>
                    <>
                      <span>{alert.downTime}</span>
                    </>
                  </td>
                  <td className="maintenance-msg">
                    <span>{alert.maintenanceMsg}</span>
                  </td>
                  <td className="d-flex justify-content-around text-white">
                    <div
                      className="bg-danger rounded p-1 px-2"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Delete alert"
                      onClick={() => deleteAlert(alert._id)}
                      style={{ margin: '3px' }}
                    >
                      <i className="fas fa-trash-alt"></i>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <Grid container justifyContent="center">
          No recent maintenance is set.
        </Grid>
      )}
    </div>
  );
};

export default RecentMaintenanceAlert;

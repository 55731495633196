import React from 'react';
import AnalyticCard from './Components/AnalyticCard';
import Axios from 'axios';
import CustomerDataTable from './Components/CustomerDataTable';
import moment from 'moment';

class Analytics extends React.Component {
  constructor() {
    super();
    let now = new Date();
    let currentDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    );
    let oneMonthAgo = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    );
    currentDate.setDate(currentDate.getDate());
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
    let beginning = new Date();
    beginning.setFullYear('2021');
    beginning.setMonth('09');
    beginning.setDate('28');

    this.state = {
      totalProducts: 0,
      listedProducts: 0,
      totalUsers: 0,
      avgProducts: 0,
      activeUsers: 0,
      startDate: oneMonthAgo.toISOString().substr(0, 10),
      endDate: currentDate.toISOString().substr(0, 10),
      currentRange: '',
      usersInRange: [],
      totalProductsInRange: 0,
      avgProductsInRange: 0,
      activeUsersInRange: 0,
      inactiveUsersInRange: 0,
      newUsersInRange: 0,
      activeUsersSevenDays: 0,
      inactiveUsersSevenDays: 0,
      beginning: beginning.toISOString().substr(0, 10),
      sinceBeginning: '',
      activeAffiliateCodes: 0,
      affiliateLeadsTotal: 0,
    };
  }

  componentDidMount = async () => {
    this.getAllData();
    //Get users in stated range
  };

  componentDidUpdate(prevProps, prevState) {
    //Check if dates have changed
    if (
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate
    ) {
      this.getAllData();
    }
  }

  getAllData = async () => {
    let { startDate, endDate, currentRange, beginning, sinceBeginning } =
      this.state;
    this.getUsersInRange();
    this.getListedProducts();
    this.getAffiliatesInfo();

    let start = new Date(startDate);
    let end = new Date(endDate);
    let begin = new Date(beginning);

    let timeDiffBeginning = begin.getTime() - start.getTime();
    sinceBeginning = timeDiffBeginning / (1000 * 3600 * 24);

    const beginningDate = moment(begin, 'DD.MM.YYYY');
    const currentDate = moment(new Date(), 'DD.MM.YYYY');
    sinceBeginning = currentDate.diff(beginningDate, 'days');

    let timeDiffRange = end.getTime() - start.getTime();
    currentRange = timeDiffRange / (1000 * 3600 * 24);

    let clientData = await Axios.get(`/analytics/load/${startDate}/${endDate}`);
    let dataResults = clientData.data;

    this.setState({
      totalProducts: dataResults.TotalProducts.totalProducts
        ? dataResults.TotalProducts.totalProducts
        : 0,
      totalUsers: dataResults.TotalClients.totalClients
        ? dataResults.TotalClients.totalClients
        : 0,
      totalProductsInRange: dataResults.DateRangeProducts.totalProducts
        ? dataResults.DateRangeProducts.totalProducts
        : 0,
      newUsersInRange: dataResults.DateRangeClients.newClients[0]
        ? dataResults.DateRangeClients.newClients[0].newClients
        : 0,
      activeUsersInRange: dataResults.DateRangeClients.activeClients[0]
        ? dataResults.DateRangeClients.activeClients[0].activeClients
        : 0,
      activeUsersSevenDays: dataResults.SevenDaysClients.activeClients[0]
        ? dataResults.SevenDaysClients.activeClients[0].activeClients
        : 0,
      inactiveUsersSevenDays: dataResults.SevenDaysClients.inactiveClients[0]
        ? dataResults.SevenDaysClients.inactiveClients[0].inactiveClients
        : 0,
      inactiveUsersInRange: dataResults.DateRangeClients.inactiveClients[0]
        ? dataResults.DateRangeClients.inactiveClients[0].inactiveClients
        : 0,
      avgProductsInRange:
        dataResults.DateRangeProducts.totalProducts /
        dataResults.DateRangeClients.totalClients[0].totalClients,
      avgProducts:
        dataResults.TotalProducts.totalProducts /
        dataResults.TotalClients.totalClients,
      currentRange: currentRange,
      sinceBeginning: sinceBeginning,
    });
  };

  getAffiliatesInfo = async () => {
    let userData = await Axios.get(`/analytics/load/affiliates`); //heavy call so I took it out of componentdidmount.
    let userResults = userData.data;
    this.setState({
      activeAffiliateCodes: userResults.AffiliatesInfo.totalAffiliateAccounts[0]
        ? userResults.AffiliatesInfo.totalAffiliateAccounts[0]
            .totalAffiliateAccounts
        : 0,
      affiliateLeadsTotal: userResults.AffiliatesInfo.totalAffiliateLeads[0]
        ? userResults.AffiliatesInfo.totalAffiliateLeads[0].totalAffiliateLeads
        : 0,
    });
  };

  getListedProducts = async () => {
    let userData = await Axios.get(`/analytics/load/listedproducts`); //heavy call so I took it out of componentdidmount.
    let userResults = userData.data;
    this.setState({
      listedProducts: userResults.ListedProducts.listedProducts,
    });
  };

  getUsersInRange = async () => {
    let { startDate, endDate } = this.state;
    let userData = await Axios.get(
      `/analytics/load/clients/${startDate}/${endDate}`
    ); //heavy call so I took it out of componentdidmount.
    let userResults = userData.data.ClientsInRange;
    this.setState({ usersInRange: userResults });
  };

  render() {
    const todayDate = moment(new Date()).format('YYYY-MM-DD');

    return (
      <>
        <div className="row justify-content-center">
          <div className="col-sm-6 col-lg-4 col-12 input-group ">
            <div className="input-group-prepend">
              <span className="input-group-text">Start Date</span>
            </div>
            <input
              className="form-control"
              type="date"
              min={this.state.beginning}
              max={todayDate}
              defaultValue={this.state.startDate}
              onChange={(e) => this.setState({ startDate: e.target.value })}
            ></input>
          </div>
          <div className="col-sm-6 col-lg-4 col-12 input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">End Date</span>
            </div>
            <input
              className="form-control"
              type="date"
              min={this.state.startDate}
              max={todayDate}
              defaultValue={this.state.endDate}
              onChange={(e) => this.setState({ endDate: e.target.value })}
            ></input>
          </div>
        </div>
        <div className="row d-flex flex-nowrap">
          <AnalyticCard
            timeframe={this.state.sinceBeginning}
            title="Total Products"
            number={this.state.totalProducts}
          />
          <AnalyticCard
            timeframe={this.state.sinceBeginning}
            title="Total Customers"
            number={this.state.totalUsers}
          />
          <AnalyticCard
            timeframe={this.state.sinceBeginning}
            title="Currently Listed Products"
            number={this.state.listedProducts}
          />
          <AnalyticCard
            timeframe={this.state.sinceBeginning}
            title="Avg # Products Per Day"
            number={this.state.avgProducts.toFixed(2)}
          />
          <AnalyticCard
            timeframe={this.state.sinceBeginning}
            title="Active Affiliate Codes"
            number={this.state.activeAffiliateCodes}
          />
          <AnalyticCard
            timeframe={this.state.sinceBeginning}
            title="Affiliate Leads Total"
            number={this.state.affiliateLeadsTotal}
          />
        </div>
        <div className="row d-flex flex-nowrap analytics">
          <AnalyticCard
            timeframe={this.state.currentRange}
            title="Total Products"
            number={this.state.totalProductsInRange}
          />
          <AnalyticCard
            timeframe={this.state.currentRange}
            title="Avg # Products Per Day"
            number={this.state.avgProductsInRange.toFixed(2)}
          />
          <AnalyticCard
            timeframe={this.state.currentRange}
            title="# Active Customers"
            number={this.state.activeUsersInRange}
          />
          <AnalyticCard
            timeframe={this.state.currentRange}
            title="# Inactive Customers"
            number={this.state.inactiveUsersInRange}
          />
          <AnalyticCard
            timeframe={this.state.currentRange}
            title="# of New Users"
            number={this.state.newUsersInRange}
          />
        </div>
        <div className="row d-flex flex-nowrap analytics">
          <AnalyticCard
            timeframe="7"
            title="# Active Customers"
            number={this.state.activeUsersSevenDays}
          />
          <AnalyticCard
            timeframe="7"
            title="# Inactive Customers"
            number={this.state.inactiveUsersSevenDays}
          />
        </div>
        <div className="row">
          <CustomerDataTable usersInRange={this.state.usersInRange} />
        </div>
      </>
    );
  }
}

export default Analytics;

import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { nanoid } from 'nanoid';

import './UserPagination.css';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const UserPagination = (props) => {
  const classes = useStyles();
  const [pageSizeOptions, setPageSizeOptions] = useState([]);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    setPageSizeOptions(props.pageSizeOptions);
  }, [props.pageSizeOptions]);

  useEffect(() => {
    setTotalPages(props.totalNumberOfPages);
  }, [props.totalNumberOfPages]);

  return (
    <Paper elevation={3} className="user-pagination-bar">
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <FormControl className={classes.formControl}>
          <Select
            value={props.limit}
            onChange={(event) => props.handlePageSizeChange(event.target.value)}
          >
            {pageSizeOptions.map((pageSizeOption) => (
              <MenuItem key={nanoid(4)} value={pageSizeOption}>
                {pageSizeOption}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className="user-pagination-change-page-control">
          <IconButton
            disabled={props.currentPage === 1}
            onClick={() => props.handlePageChange('PREVIOUS')}
          >
            <ChevronLeftIcon />
          </IconButton>
          <span>
            <input
              className="current-page-input"
              type="text"
              size={1}
              value={props.currentPage}
              onChange={(event) =>
                props.handlePageChangeViaTextBox(event.target.value)
              }
            />
            {' / ' + totalPages}
          </span>
          <IconButton
            disabled={props.currentPage === totalPages}
            onClick={() => props.handlePageChange('NEXT')}
          >
            <ChevronRightIcon />
          </IconButton>
        </div>
      </div>
    </Paper>
  );
};

export default UserPagination;

import React, { useState, useEffect } from 'react';
import './MessagesTab.css';

const MessagesTab = (props) => {
  const [tabDetails, setTabDetails] = useState('');

  useEffect(() => {
    setTabDetails({
      tabName: props.tabDetails.userName,
      tabId: props.tabDetails.userId,
      totalNewMessagesAfterLogin: props.tabDetails.totalNewMessagesAfterLogin,
      totalUnreadMessages: props.tabDetails.totalUnreadMessages,
    });
  }, [props.tabDetails]);

  return (
    <div
      className={
        props.isSelected ? 'messages-inner-tab-active' : 'messages-inner-tab'
      }
      onClick={() => props.setSelectedTab(tabDetails)}
    >
      {tabDetails.tabName}
      <div className="messages-tab-count">
        {tabDetails.totalNewMessagesAfterLogin > 0 ? (
          <div className="messages-tab-total-new-messages-since-login">
            +&nbsp;{tabDetails.totalNewMessagesAfterLogin}
          </div>
        ) : null}
        {tabDetails.totalUnreadMessages > 0 ? (
          <div className="messages-tab-total-unread-messages">
            {tabDetails.totalUnreadMessages}
          </div>
        ) : null}
      </div>
      <i
        style={{ color: '#CD8987' }}
        className="far fa-times-circle messages-tab-delete"
        onClick={(event) => {
          event.stopPropagation();
          props.deleteTab(tabDetails);
        }}
      ></i>
    </div>
  );
};

export default MessagesTab;

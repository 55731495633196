import React, { Component } from 'react';
import Axios from '../services/Axios';
import { Link } from 'react-router-dom';
import Logo from './hammock_dashboard.svg';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { totalMessages: 0 };
  }

  componentDidMount = async () => {
    var { totalMessages } = this.state;
    let totalMessagesMessage = await Axios.get(
      '/messagesNeo/messagetotaladmin'
    );
    if (
      totalMessagesMessage.data &&
      totalMessagesMessage.data.AdminUnread &&
      totalMessagesMessage.data.AdminUnread[0] &&
      totalMessagesMessage.data.AdminUnread[0].messages
    )
      totalMessages = totalMessagesMessage.data.AdminUnread[0].messages;
    this.setState({ totalMessages });
  };

  logout = () => {
    localStorage.removeItem('token');
    window.open('/login', '_self');
  };

  render = () => {
    const { totalMessages } = this.state;
    return (
      <div>
        <nav
          className="navbar navbar-expand-lg navbar-dark"
          style={{ backgroundColor: '#102747' }}
        >
          <a href="/" className="navbar-brand">
            <img src={Logo} alt="hammock" height="40px" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li>
                <Link className="nav-link" to="/messages">
                  <span style={{ color: 'white' }}>
                    <div className="fas fa-comments mr-1"></div> Messages (
                    {totalMessages})
                  </span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/">
                  <span style={{ color: 'white' }}>
                    <div className="fas fa-users mr-1"></div> Users
                  </span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/agent">
                  <span style={{ color: 'white' }}>
                    <div className="fas fa-user-tie mr-1"></div> Agents
                  </span>
                </Link>
              </li>
              <li className="nav-item dropdown">
                <Link
                  to=""
                  className="nav-link"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span style={{ color: 'white' }}>
                    Analytics <div className="fas fa-caret-down mr-1"></div>
                  </span>
                </Link>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                  <Link className="dropdown-item nav-link" to="/analytics">
                    <div className="fas fa-chart-bar mr-1"></div> Analytics
                  </Link>
                  <Link
                    className="dropdown-item nav-link "
                    to="/agentDashboard"
                  >
                    <span style={{ color: 'white' }}>
                      <div className="fas fa-chart-line"></div> Agent Dashboard
                    </span>
                  </Link>
                </div>
              </li>
              <li className="nav-item dropdown">
                <Link
                  to=""
                  className="nav-link"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
                >
                  <span style={{ color: 'white' }}>
                    Settings &nbsp;
                    <div className="fas fa-caret-down mr-1"></div>
                  </span>
                </Link>
                <div
                  className="dropdown-menu dropdown-menu-right"
                  aria-labelledby="navbarDropdown"
                >
                  <Link className="dropdown-item nav-link" to="/rates">
                    <div className="fas fa-money-check-alt mr-1"></div> Rates
                  </Link>
                  <Link className="dropdown-item nav-link" to="/rules">
                    <div className="fas fa-clipboard-list mr-1"></div> Rules
                  </Link>
                  <Link className="dropdown-item nav-link" to="/notifications">
                    <div className="fas fa-clipboard-list mr-1"></div>{' '}
                    Notifications
                  </Link>
                  <Link className="dropdown-item nav-link" to="/errormap">
                    <div className="fas fa-clipboard-list mr-1"></div> Error Map
                  </Link>
                  <Link
                    className="dropdown-item nav-link"
                    to="/soldnotifications"
                  >
                    <div className="fas fa-clipboard-list mr-1"></div> Sold
                    Notifications
                  </Link>
                  <Link className="dropdown-item nav-link" to="/wholesaleBox">
                    <div className="fas fa-clipboard-list mr-1"></div> Wholesale
                    Box Search
                  </Link>
                  <Link className="dropdown-item nav-link" to="/systemSettings">
                    <div className="fas fa-clipboard-list mr-1"></div> System
                    Settings
                  </Link>
                  <Link className="dropdown-item nav-link" to="/maintenance">
                    <div className="fas fa-tools mr-1"></div> Maintenance
                  </Link>
                  <span
                    onClick={this.logout}
                    className="nav-link c-pointer text-danger"
                  >
                    <div className="fas fa-sign-out-alt mr-1"></div> Logout
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  };
}

export default Header;

import React, { useState, useEffect } from 'react';
import { leafNode, operatorNode } from '../../parser';
import IfThen from './Components/IfThen';
import Axios from 'axios';

function UpdateRule({ match, history }) {
  const { id } = match.params;
  const [rules, addRules] = useState([leafNode('', null, '>')]);
  const [result, setResult] = useState('');
  const addAndRule = () => addRules([...rules, operatorNode('and')]);
  const addOrRule = () => addRules([...rules, operatorNode('or')]);

  const update = (i, key, value) => {
    const upadeRule = rules.find((rule, id) => id === i);
    upadeRule.key = key;
    upadeRule.value = value;
    addRules(rules.map((rule, id) => (i !== id ? rule : upadeRule)));
  };

  useEffect(() => {
    Axios.get('/rule/' + id).then((res) => {
      const data = JSON.parse(res.data.conditionType[0]);
      addRules(data.expression);
      setResult(data.output);
    });
  }, [id]);

  const remove = (i) => addRules(rules.filter((rule, idx) => idx !== i));

  const updateData = () => {
    Axios.put('/rule/edit/' + id, {
      conditionType: [JSON.stringify({ output: result, expression: rules })],
      conditionName: [''],
      conditionValue: [''],
      conditionResult: '',
    }).then((data) => {
      history.push('/rules');
    });
  };
  return (
    <div>
      <h2>Rule</h2>
      <IfThen
        rules={rules}
        addRules={addRules}
        addAndRule={addAndRule}
        addOrRule={addOrRule}
        remove={remove}
        update={update}
        result={result}
        setResult={setResult}
      />
      <button className="btn btn-primary ml-2 mt-2" onClick={updateData}>
        Update
      </button>
    </div>
  );
}

export default UpdateRule;

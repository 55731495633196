import React from 'react';
import './NewMessagesClientList.css';

const NewMessagesClientList = (props) => {
  const calculateTotalNewMessagesAfterLogin = (clientId) => {
    let totalNewMessagesAfterLogin = 0;

    let clientMessages = props.newMessagesClients.filter(
      (messagesClient) =>
        Object.keys(messagesClient)[0].split('|')[0] === clientId
    )[0];

    if (clientMessages) {
      totalNewMessagesAfterLogin = Object.values(clientMessages)[0].filter(
        (message) =>
          message.messageStatus === 'UNREAD' &&
          message.isMessageFromClient === true
      ).length;

      return totalNewMessagesAfterLogin;
    } else {
      return totalNewMessagesAfterLogin;
    }
  };

  const calculateTotalUnreadMessages = (clientId) => {
    let totalUnreadMessages = 0;

    let clientMessages = props.newMessagesClients.filter(
      (messagesClient) =>
        Object.keys(messagesClient)[0].split('|')[0] === clientId
    )[0];

    if (clientMessages) {
      let messagesFromClient = Object.values(clientMessages)[0].filter(
        (message) => message.isMessageFromClient
      );

      totalUnreadMessages =
        messagesFromClient[messagesFromClient.length - 1]
          ?.totalUnreadMessages || 0;

      return totalUnreadMessages;
    } else {
      return totalUnreadMessages;
    }
  };

  const getTotalUnreadMessages = (clientId) => {
    let totalUnreadMessages = calculateTotalUnreadMessages(clientId);

    if (isNaN(totalUnreadMessages)) return 0;
    else return totalUnreadMessages;
  };

  return (
    <>
      <div
        className="new-messages-client-list"
        style={{ maxHeight: props.maxNewMessagesSectionHeight + 'px' }}
      >
        {props.clients.map((client) => (
          <div
            onClick={(event) =>
              props.setSelectedClient({
                userName: Object.keys(client)[0].split('|')[1],
                userId: Object.keys(client)[0].split('|')[0],
                section: 'NEW_MESSAGES',
                totalNewMessagesAfterLogin: calculateTotalNewMessagesAfterLogin(
                  Object.keys(client)[0].split('|')[0]
                ),
                totalUnreadMessages: getTotalUnreadMessages(
                  Object.keys(client)[0].split('|')[0]
                ),
              })
            }
            className={
              props.selectedClient.userName ===
                Object.keys(client)[0].split('|')[1] &&
              props.selectedClient.section === 'NEW_MESSAGES'
                ? 'new-messages-client-li-focus'
                : 'new-messages-client-li'
            }
          >
            <span className="messages-user-icon-container">
              <i
                className="far fa-user-circle fa-2x"
                style={{
                  color:
                    props.selectedClient.userId ===
                      Object.keys(client)[0].split('|')[0] &&
                    props.selectedClient.section === 'NEW_MESSAGES'
                      ? 'white'
                      : 'black',
                  verticalAlign: 'middle',
                }}
              ></i>
            </span>
            <span style={{ userSelect: 'none', fontWeight: '450' }}>
              &nbsp;&nbsp;{Object.keys(client)[0].split('|')[1]}
            </span>
            <span style={{ float: 'right' }}>
              {getTotalUnreadMessages(Object.keys(client)[0].split('|')[0]) >
              0 ? (
                <span
                  style={{
                    backgroundColor: '#18A999',
                    color: 'white',
                    padding: '2px 10px',
                    fontWeight: '400',
                    margin: '0px 7px',
                  }}
                >
                  {getTotalUnreadMessages(Object.keys(client)[0].split('|')[0])}
                </span>
              ) : null}
              {calculateTotalNewMessagesAfterLogin(
                Object.keys(client)[0].split('|')[0]
              ) > 0 ? (
                <span
                  style={{
                    backgroundColor: '#E71D36',
                    color: 'white',
                    padding: '2px 6px',
                    fontWeight: '400',
                    margin: '0px 7px',
                  }}
                >
                  {'+' +
                    calculateTotalNewMessagesAfterLogin(
                      Object.keys(client)[0].split('|')[0]
                    )}
                </span>
              ) : null}
            </span>
          </div>
        ))}
      </div>
    </>
  );
};

export default NewMessagesClientList;

import Axios from '../../services/Axios';
import React, { useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { nanoid } from 'nanoid';
import { TreeDataState, CustomTreeData } from '@devexpress/dx-react-grid';

import {
  Grid,
  Table,
  TableHeaderRow,
  TableTreeColumn,
} from '@devexpress/dx-react-grid-material-ui';

const getRowId = (row) => row.id;

const getChildRows = (row, rootRows) => {
  let outputTable = [];

  if (rootRows && rootRows.length > 1 && row === null) {
    for (let top in rootRows) {
      outputTable.push(rootRows[top]);
    }
    return outputTable;
  }

  if (
    row !== null &&
    row.productChangeEntries &&
    row.id !== row.productChangeEntries.parentId
  ) {
    for (let changeEntry in row.productChangeEntries) {
      outputTable.push(row.productChangeEntries[changeEntry]);
    }
    return outputTable;
  }

  if (
    row !== null &&
    row.fieldChanges &&
    row.id !== row.fieldChanges.parentId
  ) {
    for (let fieldChanges in row.fieldChanges) {
      if (row.fieldChanges[fieldChanges].beforeValue !== null)
        row.fieldChanges[fieldChanges].beforeValue =
          row.fieldChanges[fieldChanges].beforeValue.toString();
      if (row.fieldChanges[fieldChanges].afterValue !== null)
        row.fieldChanges[fieldChanges].afterValue =
          row.fieldChanges[fieldChanges].afterValue.toString();
      outputTable.push(row.fieldChanges[fieldChanges]);
    }
    return outputTable;
  }
  return outputTable;
};

export default () => {
  useEffect(() => {
    const asyncFetch = async () => {
      await Axios.get(`/product/getproductlog/`)
        .then(({ data }) => {
          for (let entry in data.product) {
            data.product[entry]['id'] = nanoid(4);
            data.product[entry]['parentId'] = nanoid(4);
            for (let changes in data.product[entry].productChangeEntries) {
              data.product[entry].productChangeEntries[changes]['id'] =
                nanoid(4);
              data.product[entry].productChangeEntries[changes]['parentId'] =
                data.product[entry]['id'];

              for (let fields in data.product[entry].productChangeEntries[
                changes
              ].fieldChanges) {
                data.product[entry].productChangeEntries[changes].fieldChanges[
                  fields
                ]['id'] = nanoid(4);
                data.product[entry].productChangeEntries[changes].fieldChanges[
                  fields
                ]['parentId'] =
                  data.product[entry].productChangeEntries[changes]['id'];
              }
            }
          }
          setData(data.product);
        })
        .catch((err) => console.log(err));
    };

    asyncFetch();
  }, []);

  const [columns] = useState([
    { name: 'productID', title: 'Product ID' },
    { name: 'userType', title: 'User Type', width: 50 },
    { name: 'userID', title: 'User ID' },
    { name: 'fieldName', title: 'Field Name' },
    { name: 'beforeValue', title: 'Before Value' },
    { name: 'afterValue', title: 'After Value' },
    { name: 'entriesCount', title: 'Total Entries' },
  ]);

  const [data, setData] = useState([]);

  const [tableColumnExtensions] = useState([
    { columnName: 'productID', width: 300 },
  ]);

  const [defaultExpandedRowIds] = useState([0, 1]);
  const [expandedRowIds, setExpandedRowIds] = useState([]);

  return (
    <Paper>
      <Grid rows={data} columns={columns} getRowId={getRowId}>
        <TreeDataState
          expandedRowIds={expandedRowIds}
          onExpandedRowIdsChange={setExpandedRowIds}
        />
        <CustomTreeData getChildRows={getChildRows} />
        <Table columnExtensions={tableColumnExtensions} />
        <TableHeaderRow />
        <TableTreeColumn for="productID" />
      </Grid>
    </Paper>
  );
};

//void comment
const leafNode = (key, value, operator) => ({
  type: 'leafNode',
  key: key,
  op: operator,
  value: value,
});

const operatorNode = (operator, key = '', value = null) => ({
  type: 'operatorNode',
  op: operator,
  key: key,
  value: value,
});

export { leafNode, operatorNode };

import { io } from 'socket.io-client';
import { service_endpoint_urls } from './endpoint-detection';

const { socketIoURI } = service_endpoint_urls;

const socket = io(socketIoURI, {
  path: '/socket',
  transports: ['websocket'],
  withCredentials: true,
});

export default socket;

import React from 'react';
import { withRouter } from 'react-router-dom';

function BackButton(props) {
  return (
    <div>
      <div
        onClick={() => {
          if (props.to) props.history.push(props.to);
          else props.history.goBack();
        }}
        className="back-button"
      >
        <i className="fas fa-arrow-left"></i>
      </div>
    </div>
  );
}

export default withRouter(BackButton);

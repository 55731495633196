import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';

export default function AddUpdateRateModal({
  rate,
  handleModalClose,
  rateHistoryModalOpen,
}) {
  return (
    <Modal
      show={rateHistoryModalOpen}
      size="xl"
      centered
      onHide={handleModalClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h2>{rate.name}</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {rate.history && rate.history.length > 0 ? (
          <Table bordered hover size="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>List Rate</th>
                <th>CrossList Rate</th>
                <th>DeList Rate</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {rate.history.map((r, idx) => {
                return (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>{r.name}</td>
                    <td>{r.list}</td>
                    <td>{r.crossList}</td>
                    <td>{r.deList}</td>
                    <td>{new Date(r.createdAt).toDateString()}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <h2>No History found</h2>
        )}{' '}
      </Modal.Body>
    </Modal>
  );
}

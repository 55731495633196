import React, { useState } from 'react';
import './MessagesContentArea.css';
import MessagesDisplayArea from './MessagesDisplayArea';
import MessagesTextArea from './MessagesTextArea';
import MessagesTabBar from './MessagesTabBar';

const MessagesContentArea = (props) => {
  const [refreshDisplayArea, setRefreshDisplayArea] = useState(false);

  const refresh = () => {
    setRefreshDisplayArea(true);
  };
  return (
    <div className="messages-content-area">
      <MessagesTabBar
        addTab={props.addTab}
        selectedTab={props.selectedTab}
        setSelectedTab={props.setSelectedTab}
        newMessagesClients={props.newMessagesClients}
        setNewMessagesClients={props.setNewMessagesClients}
        clientAndTotalUnreadMessagesMap={props.clientAndTotalUnreadMessagesMap}
        totalUnreadMessagesInSelectedTab={
          props.totalUnreadMessagesInSelectedTab
        }
      />{' '}
      <MessagesDisplayArea
        selectedTab={props.selectedTab}
        newMessagesForSelectedTab={props.newMessagesForSelectedTab}
        setNewMessagesForSelectedTab={props.setNewMessagesForSelectedTab}
        refreshDisplayArea={refreshDisplayArea}
        setRefreshDisplayArea={setRefreshDisplayArea}
        clientAndTotalUnreadMessagesMap={props.clientAndTotalUnreadMessagesMap}
        setClientAndTotalUnreadMessagesMap={
          props.setClientAndTotalUnreadMessagesMap
        }
        setNewMessagesClients={props.setNewMessagesClients}
        newMessagesClients={props.newMessagesClients}
        setTotalUnreadMessagesInSelectedTab={
          props.setTotalUnreadMessagesInSelectedTab
        }
      />{' '}
      <MessagesTextArea
        selectedTab={props.selectedTab}
        setRefreshDisplayArea={setRefreshDisplayArea}
        refresh={refresh}
      />{' '}
    </div>
  );
};

export default MessagesContentArea;

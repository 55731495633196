import React, { useState } from 'react';
import { leafNode, operatorNode } from '../../../parser';
import IfThen from './IfThen';
import Axios from 'axios';

function NewRule() {
  const [rules, addRules] = useState([leafNode('', null, '>')]);
  const [result, setResult] = useState('');
  const addAndRule = () => addRules([...rules, operatorNode('and')]);
  const addOrRule = () => addRules([...rules, operatorNode('or')]);

  const update = (i, key, value) => {
    const upadeRule = rules.find((rule, id) => id === i);
    upadeRule.key = key;
    upadeRule.value = value;
    addRules(rules.map((rule, id) => (i !== id ? rule : upadeRule)));
  };

  const remove = (i) => addRules(rules.filter((rule, idx) => idx !== i));

  const sendData = () => {
    Axios.post('/rule/add', {
      conditionType: [JSON.stringify({ output: result, expression: rules })],
      conditionName: [''],
      conditionValue: [''],
      conditionResult: '',
    }).then((data) => {
      setResult('');
      addRules([leafNode('', 0, '>')]);
    });
  };
  return (
    <div>
      <IfThen
        rules={rules}
        addRules={addRules}
        addAndRule={addAndRule}
        addOrRule={addOrRule}
        remove={remove}
        update={update}
        result={result}
        setResult={setResult}
      />
      <button className="btn btn-primary ml-2 mt-2" onClick={sendData}>
        Save
      </button>
    </div>
  );
}

export default NewRule;

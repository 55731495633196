import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import axios from '../../../services/Axios';
import {
  Confirmer,
  ErrorToaster,
  SuccessToaster,
} from 'src/services/SweetAlerts';

export default function AddUpdateRateModal({
  addUpdateRateModalOpen,
  handleModalClose,
  getRates,
  update,
  rateId,
}) {
  const [name, setName] = useState('');
  const [list, setList] = useState('');
  const [crossList, setCrossList] = useState('');
  const [deList, setDeList] = useState('');
  const [isDefault, setIsDefault] = useState(false);
  const [canMakeRateDefault, setCanMakeRateDefault] = useState(true);
  // when updating a rate, If a rate is already default can't update the rate to not default, else it can result in not default rate.

  useEffect(() => {
    getRate();
  }, [addUpdateRateModalOpen]);

  const getRate = async () => {
    try {
      //Call the api when modal is open and need to update a rate for a given rateId
      if (addUpdateRateModalOpen && update && rateId) {
        const rateResponse = await axios.get(`/rates/${rateId}`);
        const { list, deList, crossList, isDefault, name } =
          rateResponse.data.rate;
        setList(list);
        setDeList(deList);
        setCrossList(crossList);
        setIsDefault(isDefault);
        setName(name);
        setCanMakeRateDefault(!isDefault);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddUpdateRate = async () => {
    try {
      if (name && name == '')
        return Confirmer.fire({ text: 'Name is required' });
      if (list && list == '')
        return Confirmer.fire({ text: 'List is required' });
      if (crossList && crossList == '')
        return Confirmer.fire({ text: 'CrossList is required' });
      if (deList && deList == '')
        return Confirmer.fire({ text: 'DeList is required' });

      const formData = { list, crossList, deList, isDefault, name };

      if (update && rateId) {
        await axios.put(`/rates/${rateId}`, formData);
      } else {
        await axios.post('/rates', formData);
      }

      SuccessToaster.fire({
        text: update
          ? 'Rate Updated Successfully'
          : 'Rate Created Successfully',
      });
      handleModalClose();
      getRates();
    } catch (err) {
      if (err?.response?.data?.error?.message) {
        ErrorToaster.fire({ text: err.response.data.error.message });
      } else {
        ErrorToaster.fire({ text: 'Something went wrong' });
        console.log(err.response);
      }
    }
  };
  return (
    <Modal show={addUpdateRateModalOpen} centered onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {' '}
          {update && rateId ? 'Edit Rate Data' : 'Add New Rate'}{' '}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formPlaintextPassword"
          >
            <Form.Label column sm="5">
              Rate Name
            </Form.Label>
            <Col sm="7">
              <Form.Control
                type="text"
                placeholder="Enter name of rate"
                value={name}
                onChange={(evt) => setName(evt.target.value)}
              />
            </Col>
          </Form.Group>

          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formPlaintextPassword"
          >
            <Form.Label column sm="5">
              Listing Rate (¢)
            </Form.Label>
            <Col sm="7">
              <Form.Control
                type="number"
                placeholder="Listing Rate in cents"
                value={list}
                onChange={(evt) => setList(evt.target.value)}
              />
            </Col>
          </Form.Group>

          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formPlaintextPassword"
          >
            <Form.Label column sm="5">
              Cross-Listing Rate (¢)
            </Form.Label>
            <Col sm="7">
              <Form.Control
                type="number"
                placeholder="Cross-Listing Rate in cents"
                value={crossList}
                onChange={(evt) => setCrossList(evt.target.value)}
              />
            </Col>
          </Form.Group>

          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formPlaintextPassword"
          >
            <Form.Label column sm="5">
              De-Listing Rate (¢)
            </Form.Label>
            <Col sm="7">
              <Form.Control
                type="number"
                placeholder="De-Listing Rate in cents"
                value={deList}
                onChange={(evt) => setDeList(evt.target.value)}
              />
            </Col>
          </Form.Group>

          <Form.Check
            type="checkbox"
            label={'Make this rate default'}
            checked={isDefault}
            onChange={() => setIsDefault(!isDefault)}
            disabled={!canMakeRateDefault}
            //Disable the checkbox when updating the default rate, else unchecking this might result in no default rate.
          />
        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        {update && rateId ? (
          <button
            className="btn btn-outline-primary"
            onClick={handleAddUpdateRate}
          >
            Update Rate
          </button>
        ) : (
          <button
            className="btn btn-outline-primary"
            onClick={handleAddUpdateRate}
          >
            Save Changes
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

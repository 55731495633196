import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import AddUpdateRateModal from './AddUpdateRateModal';
import DeleteRateModel from './DeleteRateModel';
import RateHistoryModal from './RateHistoryModal';

export default function AllRates({ rates, getRates }) {
  const [addUpdateRateModalOpen, setAddUpdateRateModalOpen] = useState(false);
  const [deleteRateModalOpen, setDeleteRateModalOpen] = useState(false);
  const [updateRateId, setUpdateRateId] = useState('');
  const [deleteRateId, setDeleteRateId] = useState('');
  const [rateHistoryModalOpen, setRateHistoryRateModalOpen] = useState(false);
  const [showHistoryOfRate, setShowHistoryOfRate] = useState({});

  const handleRateHistoryModalOpen = () => {
    setRateHistoryRateModalOpen(true);
  };
  const handleRateHistoryModalClose = () => {
    setRateHistoryRateModalOpen(false);
  };

  const handleAddUpdateRateModalOpen = () => {
    setAddUpdateRateModalOpen(true);
  };
  const handleAddUpdateRateModalClose = () => {
    setAddUpdateRateModalOpen(false);
  };

  const handleDeleteRateModalOpen = () => {
    setDeleteRateModalOpen(true);
  };
  const handleDeleteRateModalClose = () => {
    setDeleteRateModalOpen(false);
  };

  const handleRateUpdate = (rateId) => {
    setUpdateRateId(rateId);
    handleAddUpdateRateModalOpen();
  };

  const handleRateDelete = (rateId) => {
    setDeleteRateId(rateId);
    handleDeleteRateModalOpen();
  };

  const handleShowRateHistory = (rate) => {
    setShowHistoryOfRate(rate);
    handleRateHistoryModalOpen();
  };

  return (
    <div
      style={{
        border: '1px solid rgba(0, 0, 0, 0.125)',
        borderRadius: '0 0 10px 10px',
        boxShadow:
          '0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 3px 6px 0 rgba(0, 0, 0, 0.03)',
      }}
    >
      {rates.length > 0 ? (
        <Table bordered hover size="sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>List Rate</th>
              <th>CrossList Rate</th>
              <th>DeList Rate</th>
              <th>Created At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {rates.map((rate, idx) => {
              return (
                <tr key={idx}>
                  <td>{idx + 1}</td>
                  <td>
                    {rate.name}
                    {'  '}
                    {rate.isDefault && (
                      <span
                        className="p-1 bg-info"
                        style={{
                          color: 'white',
                          borderRadius: '4px',
                        }}
                      >
                        Default
                      </span>
                    )}
                  </td>
                  <td>{rate.list}</td>
                  <td>{rate.crossList}</td>
                  <td>{rate.deList}</td>
                  <td>{new Date(rate.createdAt).toDateString()}</td>
                  <td>
                    <button
                      className="btn btn-outline-warning btn-sm"
                      onClick={() => handleRateUpdate(rate._id)}
                    >
                      Edit
                    </button>{' '}
                    <button
                      className="btn btn-outline-danger btn-sm m-1"
                      disabled={rate.isDefault}
                      onClick={() => handleRateDelete(rate._id)}
                    >
                      Delete
                    </button>
                    <button
                      className="btn btn-outline-info btn-sm"
                      onClick={() => handleShowRateHistory(rate)}
                    >
                      History
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <div>No Rates</div>
      )}

      <div>
        <ul>
          <li>
            These rates can be assigned to any user from the payment section of
            that user.
          </li>
          <li>Default rate will be applied to users with no rate assigned.</li>
        </ul>
      </div>

      <AddUpdateRateModal
        addUpdateRateModalOpen={addUpdateRateModalOpen}
        handleModalClose={handleAddUpdateRateModalClose}
        update={true}
        rateId={updateRateId}
        getRates={getRates}
      />

      <DeleteRateModel
        deleteRateModalOpen={deleteRateModalOpen}
        handleModalClose={handleDeleteRateModalClose}
        update={true}
        rateId={deleteRateId}
        getRates={getRates}
      />

      <RateHistoryModal
        rate={showHistoryOfRate}
        rateHistoryModalOpen={rateHistoryModalOpen}
        handleModalClose={handleRateHistoryModalClose}
      />
    </div>
  );
}

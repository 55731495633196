import { useEffect, useState } from 'react';
import Axios from '../../../../services/Axios';
import { ErrorToaster, SuccessToaster } from '../../../../services/SweetAlerts';

export const useBetaUser = () => {
  const [betaUsers, setBetaUsers] = useState([]);

  const isBetaUser = (userId) => {
    return betaUsers.some((user) => user.userId === userId);
  };

  const toggleBetaUser = async (userId, userEmail) => {
    const isExist = isBetaUser(userId);

    if (isExist) {
      const beta = betaUsers.find((user) => user.userId === userId);
      await Axios.delete(`/betaUser/${beta._id}`);
      setBetaUsers(betaUsers.filter((betaUser) => betaUser.userId !== userId));
      SuccessToaster.fire('Successfully removed from beta users');
    } else {
      const response = await Axios.post('/betaUser', { userId, userEmail });
      const beta = response.data.response;
      setBetaUsers([...betaUsers, beta]);
      SuccessToaster.fire('Successfully added to beta users');
    }
  };

  useEffect(() => {
    const getBetaUsers = async () => {
      try {
        const response = await Axios.get('/betaUser');
        const users = response.data.data;
        setBetaUsers(users);
      } catch (error) {
        console.log(error);
        ErrorToaster('Something went wrong. Please try again later.');
      }
    };
    getBetaUsers();
  }, []);

  return { isBetaUser, toggleBetaUser };
};

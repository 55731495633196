import React from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../../services/Axios';
import Table from 'react-bootstrap/Table';

export default function ClientRate({
  firstName,
  lastName,
  balance,
  isSubscribed,
  subscribedByAdmin,
  stripeCustomerId,
  userRate,
}) {
  return (
    <div>
      <Table bordered hover size="sm">
        <tbody>
          <tr>
            <th>First Name</th>
            <td>{firstName}</td>
          </tr>
          <tr>
            <th>Last Name</th>
            <td>{lastName}</td>
          </tr>
          <tr>
            <th>Balance</th>
            <td>{balance ? balance.toFixed(2) : 0}</td>
          </tr>
          <tr>
            <th>Subscribed?</th>
            <td>{isSubscribed ? 'YES' : 'NO'}</td>
          </tr>
          <tr>
            <th>Subscribed By Admin?</th>
            <td>{subscribedByAdmin ? 'Yes' : 'NO'}</td>
          </tr>
          <tr>
            <th>Stripe ID</th>
            <td>{stripeCustomerId}</td>
          </tr>
          <tr>
            <th>Assigned Rate</th>
            <td>
              <p className="m-0 p-0">
                Name: {userRate.name}{' '}
                {userRate.isDefault ? (
                  <span
                    className="p-1 bg-info"
                    style={{
                      color: 'white',
                      borderRadius: '4px',
                    }}
                  >
                    Default
                  </span>
                ) : null}
              </p>
              <p className="m-0 p-0">List: {userRate.list}</p>
              <p className="m-0 p-0">Cross-List: {userRate.crossList}</p>
              <p className="m-0 p-0">De-List: {userRate.deList}</p>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

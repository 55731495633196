import React, { useEffect, useState } from 'react';
import './SubmittedFilterSort.css';
import Select from 'react-select';

const SubmittedFilterSort = (props) => {
  const [filterTypeOptions] = useState([
    {
      value: 'Filter Non-Empty Values and Sort',
      label: 'Filter Non-Empty Values and Sort',
    },
    {
      value: 'Filter by Time Range Since Submit',
      label: 'Filter by Time Range Since Submit',
    },
  ]);

  const [nonEmptyValuesSortDirection] = useState([
    { value: 'Asc', label: 'Asc' },
    { value: 'Desc', label: 'Desc' },
  ]);

  const [timeRangeOperatorOptions] = useState([
    { value: 'Greater than', label: 'Greater than' },
    { value: 'Less than', label: 'Less than' },
  ]);

  const [timeUnitOptions] = useState([
    { value: 'Day(s)', label: 'Day(s)' },
    { value: 'Hour(s)', label: 'Hour(s)' },
    { value: 'Minute(s)', label: 'Minute(s)' },
  ]);

  const [secondRangeOperatorText, setSecondRangeOperatorText] = useState('');
  const [secondTimeUnitText, setSecondTimeUnitText] = useState('');
  const [selectedFilterType, setSelectedFilterType] = useState('');
  const [
    nonEmptyFilterSelectedSortDirection,
    setNonEmptyFilterSelectedSortDirection,
  ] = useState('');
  const [selectedRangeOperator, setSelectedRangeOperator] = useState('');
  const [selectedTimeUnit, setSelectedTimeUnit] = useState('');
  const [firstTimeInputValue, setFirstTimeInputValue] = useState(0);
  const [secondTimeInputValue, setSecondTimeInputValue] = useState(0);
  const [disabledApplyButtonClass, setDisabledApplyButtonClass] = useState('');

  useEffect(() => {
    if (
      selectedFilterType?.value === 'Filter by Time Range Since Submit' &&
      parseInt(firstTimeInputValue) === 0 &&
      parseInt(secondTimeInputValue) === 0
    )
      setDisabledApplyButtonClass('apply-disabled');
    else setDisabledApplyButtonClass('');
  }, [selectedFilterType, firstTimeInputValue, secondTimeInputValue]);

  const handleFilterTypeChange = (selectedOption) => {
    setSelectedFilterType(selectedOption);
  };

  const handleNonEmptyFilterSortDirectionChange = (selectedOption) => {
    setNonEmptyFilterSelectedSortDirection(selectedOption);
  };

  const handleTimeRangeOperatorChange = (selectedOption) => {
    setFirstTimeInputValue(0);
    setSecondTimeInputValue(0);

    if (selectedOption.value === 'Greater than')
      setSecondRangeOperatorText('Less than');
    else if (selectedOption.value === 'Less than')
      setSecondRangeOperatorText('Greater than');

    setSelectedRangeOperator(selectedOption);
  };

  const handleTimeUnitChange = (selectedOption) => {
    if (selectedOption.value === 'Day(s)') setSecondTimeUnitText('Day(s)');
    else if (selectedOption.value === 'Hour(s)')
      setSecondTimeUnitText('Hour(s)');
    else if (selectedOption.value === 'Minute(s)')
      setSecondTimeUnitText('Minute(s)');

    setSelectedTimeUnit(selectedOption);
  };

  const handleFirstTimeInput = (value) => {
    var integerRegex = /^\d+$/;

    if (integerRegex.test(value)) {
      setFirstTimeInputValue(value);
    }
  };

  const handleSecondTimeInput = (value) => {
    var integerRegex = /^\d+$/;

    if (integerRegex.test(value)) {
      setSecondTimeInputValue(value);
    }
  };

  const apply = () => {
    if (disabledApplyButtonClass.length > 0) return;

    if (
      selectedFilterType === null &&
      nonEmptyFilterSelectedSortDirection === null
    ) {
      props.setFilterColumn('');
      props.setFilterType('');
      return;
    }

    if (selectedFilterType.value === 'Filter Non-Empty Values and Sort') {
      if (nonEmptyFilterSelectedSortDirection.value === 'Asc') {
        props.setFilterColumn('SUBMITTED');
        props.setFilterType('FILTER_NON_EMPTY_SUBMITTED_ASC');
      }

      if (nonEmptyFilterSelectedSortDirection.value === 'Desc') {
        props.setFilterColumn('SUBMITTED');
        props.setFilterType('FILTER_NON_EMPTY_SUBMITTED_DESC');
      }
    }

    if (selectedFilterType.value === 'Filter by Time Range Since Submit') {
      if (selectedRangeOperator.value === 'Greater than') {
        if (selectedTimeUnit.value === 'Day(s)') {
          props.setFilterColumn('SUBMITTED');
          props.setFilterType('FILTER_TIME_RANGE_SUBMITTED_GREATER_DAYS');
        }

        if (selectedTimeUnit.value === 'Hour(s)') {
          props.setFilterColumn('SUBMITTED');
          props.setFilterType('FILTER_TIME_RANGE_SUBMITTED_GREATER_HOURS');
        }

        if (selectedTimeUnit.value === 'Minute(s)') {
          props.setFilterColumn('SUBMITTED');
          props.setFilterType('FILTER_TIME_RANGE_SUBMITTED_GREATER_MINUTES');
        }

        props.setFirstTimeInputValue(firstTimeInputValue);
        props.setSecondTimeInputValue(secondTimeInputValue);
      }

      if (selectedRangeOperator.value === 'Less than') {
        if (selectedTimeUnit.value === 'Day(s)') {
          props.setFilterColumn('SUBMITTED');
          props.setFilterType('FILTER_TIME_RANGE_SUBMITTED_LESS_DAYS');
        }

        if (selectedTimeUnit.value === 'Hour(s)') {
          props.setFilterColumn('SUBMITTED');
          props.setFilterType('FILTER_TIME_RANGE_SUBMITTED_LESS_HOURS');
        }

        if (selectedTimeUnit.value === 'Minute(s)') {
          props.setFilterColumn('SUBMITTED');
          props.setFilterType('FILTER_TIME_RANGE_SUBMITTED_LESS_MINUTES');
        }

        props.setFirstTimeInputValue(firstTimeInputValue);
        props.setSecondTimeInputValue(secondTimeInputValue);
      }
    }
  };

  const clear = () => {
    setSelectedFilterType(null);
    setNonEmptyFilterSelectedSortDirection(null);
    setSelectedRangeOperator(null);
    setSelectedTimeUnit(null);
    setFirstTimeInputValue('');
    setSecondTimeInputValue('');
  };

  return (
    <div className="submitted-filter-sort-container">
      <Select
        className="basic-single"
        classNamePrefix="select"
        isDisabled={false}
        isClearable={true}
        isSearchable={true}
        autosize={true}
        value={selectedFilterType}
        options={filterTypeOptions}
        onChange={handleFilterTypeChange}
      />
      {selectedFilterType?.value === 'Filter Non-Empty Values and Sort' ? (
        <Select
          className="basic-single"
          classNamePrefix="select"
          isDisabled={false}
          isClearable={true}
          isSearchable={true}
          value={nonEmptyFilterSelectedSortDirection}
          options={nonEmptyValuesSortDirection}
          onChange={handleNonEmptyFilterSortDirectionChange}
        />
      ) : null}
      {selectedFilterType?.value === 'Filter by Time Range Since Submit' ? (
        <div>
          <div>
            <Select
              className="basic-single timeRange"
              classNamePrefix="select"
              isDisabled={false}
              isClearable={true}
              isSearchable={true}
              options={timeRangeOperatorOptions}
              onChange={handleTimeRangeOperatorChange}
            />
            <input
              type="text"
              value={firstTimeInputValue}
              className="time-input"
              onChange={(event) => handleFirstTimeInput(event.target.value)}
            />
            <Select
              className="basic-single timeUnit"
              classNamePrefix="select"
              isDisabled={false}
              isClearable={true}
              isSearchable={true}
              options={timeUnitOptions}
              onChange={handleTimeUnitChange}
            />
          </div>
          <div>
            {selectedRangeOperator?.value?.length > 0 &&
            selectedTimeUnit?.value?.length > 0 ? (
              <>
                <div style={{ display: 'inline-block', width: '150px' }}>
                  {secondRangeOperatorText}
                </div>
                <input
                  type="text"
                  value={secondTimeInputValue}
                  className="time-input"
                  onChange={(event) =>
                    handleSecondTimeInput(event.target.value)
                  }
                />
                <span style={{ display: 'inline-block', width: '100px' }}>
                  {secondTimeUnitText}
                </span>
              </>
            ) : null}
          </div>
        </div>
      ) : null}

      <div>
        <div
          id="submitted-filter-apply-button"
          className={disabledApplyButtonClass}
          onClick={() => apply()}
        >
          Apply
        </div>
        <div id="clear-filter-apply-button" onClick={() => clear()}>
          Clear
        </div>
      </div>
    </div>
  );
};

export default SubmittedFilterSort;

import Axios from 'axios';
import { service_endpoint_urls } from './endpoint-detection';

const { apiBaseURL, assetsURL, assetsThumbnailURL } = service_endpoint_urls;

Axios.defaults.baseURL = apiBaseURL + '/admin';
if ('token' in localStorage) {
  Axios.defaults.headers['x-access-token'] = `bearer ${localStorage.getItem(
    'token'
  )}`;
}

export const getErrorMessage = (data) => {
  const defautMessage =
    'Something went wrong our servers. Please try again later';
  if (data.Error) {
    if (typeof data.Error === 'string') {
      return data.Error;
    }
    if (data.Error.errors) {
      const message = Object.keys(data.Error.errors).reduce((acc, key) => {
        const message =
          typeof data.Error.errors[key] === 'string'
            ? data.Error.errors[key]
            : data.Error.errors[key].message;
        return (
          acc +
          '<li style="padding-top: 10px;margin: auto;">' +
          message +
          '</li>'
        );
      }, '');
      const html =
        `Please check these <b>errors</b> below <ul style="text-align: left; color:crimson;font-size: 1rem;
          word-break: break-word;
          overflow-wrap: break-word;">%s</ul>`.replace('%s', message);
      return { html };
    }
    return defautMessage;
  }
  return defautMessage;
};

export const handleRequestError = (err) => {
  if (err.response) {
    let message = err.response.data.message
      ? err.response.data.message
      : undefined;

    if (!message) {
      message =
        err.response.data.error &&
        typeof err.response.data.error === 'object' &&
        Object.values(err.response.data.error).length > 0
          ? Object.values(err.response.data.error)[0]
          : undefined;
    }

    if (!message) {
      message = err.response.data.error;
    }

    if (!message) {
      message = getErrorMessage(err.response.data);
    }

    return {
      error: message,
    };
  }

  if (!err.response) {
    return {
      error: 'Something went wrong our servers. Please try again later',
    };
  }
};

export const post = async (path, data) => {
  try {
    const response = await Axios.post(path, data);
    return response.data;
  } catch (err) {
    return handleRequestError(err);
  }
};

export const get = async (path, params = {}) => {
  try {
    const response = await Axios.get(path, { params });
    return response.data;
  } catch (err) {
    return handleRequestError(err);
  }
};

export const put = async (path, data) => {
  try {
    const response = await Axios.put(path, data);
    return response.data;
  } catch (err) {
    return handleRequestError(err);
  }
};

Axios.interceptors.response.use(
  async (response) => {
    return Promise.resolve(response);
  },
  async (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const response = error.response;
    if (response && response.status === 403) {
      const errors = response.data && response.data.err;
      const string = JSON.stringify(errors);
      const regex = new RegExp('Token', 'i');
      if (string.match(regex)) {
        if (localStorage.getItem('token')) {
          delete Axios.defaults.headers['x-access-token'];
          localStorage.removeItem('token');
          window.open('/login', '_self');
        }
      }
    }
    return Promise.reject(error);
  }
);

export default Axios;
export { apiBaseURL as baseURL, assetsURL, assetsThumbnailURL };

import React, { useState } from 'react';
import Form from '../../../Components/Form';

const AddAgent = ({ addAgent }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    addAgent({ username, password });
    setUsername('');
    setPassword('');
  };
  return (
    <>
      <Form title="Add Agent">
        <div className="form-group">
          <label>Username</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="form-control"
          />
        </div>
        <button
          type="submit"
          className="btn btn-block btn-primary"
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Submit
        </button>
      </Form>
    </>
  );
};

export default AddAgent;

import React, { useState } from 'react';
import AddUpdateRateModal from './AddUpdateRateModal';

export default function TopBar({ getRates }) {
  const [addUpdateRateModalOpen, setAddUpdateRateModalOpen] = useState(false);
  const [deleteRateModalOpen, setDeleteRateModalOpen] = useState(false);

  const handleModalOpen = () => {
    setAddUpdateRateModalOpen(true);
    setDeleteRateModalOpen(true);
  };
  const handleModalClose = () => {
    setAddUpdateRateModalOpen(false);
    setDeleteRateModalOpen(false);
  };
  return (
    <div
      className="my-2 p-2 py-3 d-flex"
      style={{
        border: '1px solid rgba(0, 0, 0, 0.125)',
        borderRadius: '10px 10px 0 0',
        boxShadow:
          '0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 3px 6px 0 rgba(0, 0, 0, 0.03)',
      }}
    >
      <button className="btn btn-secondary" onClick={() => handleModalOpen()}>
        Add New Rate
      </button>
      <AddUpdateRateModal
        addUpdateRateModalOpen={addUpdateRateModalOpen}
        deleteRateModalOpen={deleteRateModalOpen}
        handleModalClose={handleModalClose}
        update={false}
        rateId={null}
        getRates={getRates}
      />
    </div>
  );
}

import React, { useState, useEffect } from 'react';

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import axios from '../../services/Axios';
import { service_endpoint_urls } from '../../services/endpoint-detection';

export default function MLSettings() {
  const [systemSettings, setSystemSettings] = useState({
    iosNotificationTestEnv: false,
  });

  useEffect(() => {
    getSystemSettings();
  }, []);

  const getSystemSettings = async () => {
    try {
      const res = await axios.get('/systemsettings');
      if (res?.data) {
        setSystemSettings({
          iosNotificationTestEnv: res.data.iosNotificationTestEnv,
        });
      }
    } catch (err) {
      console.log(err);
      alert('Error in getting settings');
    }
  };

  const handleUpdate = async () => {
    try {
      await axios.put('/systemsettings', {
        iosNotificationTestEnv: systemSettings.iosNotificationTestEnv,
      });
      getSystemSettings();
      alert('Updated Successfully');
    } catch (err) {
      console.log(err);
      alert('Error in updating settings');
    }
  };
  return (
    <div
      style={{
        maxWidth: '1000px',
        margin: 'auto',
      }}
    >
      <h3>IOS notification</h3>
      <Form>
        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
          <Form.Label column sm="7">
            Test Environment?:
          </Form.Label>
          <Col sm="5">
            <Form.Control
              type="checkbox"
              checked={systemSettings.iosNotificationTestEnv}
              disabled={
                service_endpoint_urls.appEnv === 'prod' ||
                service_endpoint_urls.appEnv === 'staging'
              }
              onChange={(evt) =>
                setSystemSettings({
                  ...systemSettings,
                  iosNotificationTestEnv: evt.target.checked,
                })
              }
            />
          </Col>
        </Form.Group>
        <Form.Group>
          <small>
            <b>Note:</b> This setting is only for the Dev environment.
          </small>
        </Form.Group>
      </Form>
      <div className="d-flex justify-content-center">
        <button className="btn btn-outline-primary" onClick={handleUpdate}>
          Update
        </button>
      </div>
    </div>
  );
}

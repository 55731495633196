import { TableBody } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Alert from '@material-ui/lab/Alert';
import Tippy from '@tippyjs/react';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useState } from 'react';
import CsvDownload from 'react-json-to-csv';
import { Link, useHistory } from 'react-router-dom';
import 'tippy.js/animations/scale.css';
import 'tippy.js/themes/light-border.css';
import { AdminUsersContext } from '../../../../ContextProviders/AdminUsersPageProvider';
import Axios from '../../../../services/Axios';
import SweetAlerts from '../../../../services/SweetAlerts';
import LoadingSpinner from '../../../utils/loader';
import ApprovedDraftedFilterSort from '../ApprovedDraftedFilterSort.js';
import DraftedFilterSort from '../DraftedFilterSort.js';
import InventoryFilterSort from '../InventoryFilterSort.js';
import SubmittedFilterSort from '../SubmittedFilterSort.js';
import UserPagination from '../UserPagination.js';
import './UserTable.css';
import { useBetaUser } from './useBetaUser';

let cancelToken;

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    minHeight: '65vh',
    maxHeight: '65vh',
  },
  alert: {
    marginTop: '30vh',
  },
});

const StickyTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#fafafa',
    left: 0,
    position: 'sticky',
    zIndex: theme.zIndex.appBar + 2,
    paddingBottom: '0px',
    minWidth: '240px',
    maxWidth: '240px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: '0px',
    border: '0px',
  },
  body: {
    backgroundColor: 'white',
    left: 0,
    position: 'sticky',
    zIndex: theme.zIndex.appBar + 1,
    paddingBottom: '0px',
  },
}))(TableCell);

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#2A2D34',
    color: 'white',
    fontWeight: 600,
    maxWidth: 220,
    padding: '10px 15px',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const UserTable = (props) => {
  const { isBetaUser, toggleBetaUser } = useBetaUser();
  const {
    contextCurrentPage,
    setContextCurrentPage,
    contextSearchText,
    setContextSearchText,
    contextSortedBy,
    setContextSortedBy,
    contextSortDirection,
    setContextSortDirection,
    contextFilterColumn,
    setContextFilterColumn,
    contextFilterType,
    setContextFilterType,
    contextFirstTimeInputValue,
    setContextFirstTimeInputValue,
    contextSecondTimeInputValue,
    setContextSecondTimeInputValue,
  } = useContext(AdminUsersContext);
  const classes = useStyles();
  const [pageSizeOptions] = useState([50, 100, 200, 300]);
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(pageSizeOptions[0]);
  const [offset, setOffset] = useState(0);
  const [totalNumberOfPages, setTotalNumberOfPages] = useState(1);
  const [totalNumberOfClients, setTotalNumberOfClients] = useState(1);
  const [searchText, setSeachText] = useState('');
  const [sortedBy, setSortedBy] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  const [filterType, setFilterType] = useState('');
  const [filterColumn, setFilterColumn] = useState('');
  const [canSort, setCanSort] = useState(true);
  const [submittedFilterIconEnabled, setSubmittedFilterIconEnabled] =
    useState(true);
  const [draftedFilterIconEnabled, setDraftedFilterIconEnabled] =
    useState(true);
  useState(true);
  const [
    approvedDraftedFilterIconEnabled,
    setApprovedDraftedFilterIconEnabled,
  ] = useState(true);
  const [inventoryFilterIconEnabled, setInventoryFilterIconEnabled] =
    useState(true);
  const [firstTimeInputValue, setFirstTimeInputValue] = useState('');
  const [secondTimeInputValue, setSecondTimeInputValue] = useState('');
  const [isNewPage, setIsNewPage] = useState(true);
  const [openDeleteConfirmationDialog, setOpenDeleteConfirmationDialog] =
    React.useState(false);
  const [userToDeleteId, setUserToDeleteId] = useState('');
  const [userToDeleteName, setUserToDeleteName] = useState('');
  const [deleteButtonEnabled, setDeleteButtonEnabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [cancallationOpen, setCancallationOpen] = useState(false);
  const [cancellation, setCancellation] = useState({});
  const [fallbackMsg, setFallbackMsg] = useState('');
  //Todo: Calendly meeting link
  const [calendlyLinkText, setCalendlyLinkText] = useState('');
  const [calendlyLinkEnabled, setCalendlyLinkEnabled] = useState(false);

  const history = useHistory();

  const getUrl = () => {
    let url = '/client1?&limit=' + limit;
    url = url + '&offset=' + offset;
    if (searchText?.trim().length > 0) url = url + '&searchText=' + searchText;
    if (sortedBy && sortDirection)
      url = url + '&sort_by=' + sortedBy + '&order_by=' + sortDirection;
    url = url + '&filterColumn=' + filterColumn;
    url = url + '&contextFilterColumn=' + contextFilterColumn;
    url = url + '&filterType=' + filterType;
    url = url + '&contextFilterType=' + contextFilterType;
    url = url + '&firstTimeInputValue=' + firstTimeInputValue;
    url = url + '&contextFirstTimeInputValue=' + contextFirstTimeInputValue;
    url = url + '&secondTimeInputValue=' + secondTimeInputValue;
    url = url + '&contextSecondTimeInputValue=' + contextSecondTimeInputValue;

    return url;
  };

  const getClients = async () => {
    setIsLoading(true);
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel('Operation canceled due to new request.');
      setIsLoading(false);
    }

    cancelToken = Axios.CancelToken.source();

    const users = await Axios.get(getUrl(), {
      cancelToken: cancelToken.token,
    })
      .then((res) => {
        console.log('####RES');
        console.log(res);
        setTotalNumberOfClients(res.data.totalNumberOfClients);
        setUsers(res.data.data);
        setFallbackMsg('');
        setIsLoading(false);
      })
      .catch((err) => {
        setFallbackMsg(
          'Please make sure that there is a default rate for users.'
        );
        setIsLoading(false);
      });
  };

  const handleDeleteDialogClickOpen = () => {
    setOpenDeleteConfirmationDialog(true);
  };

  const handleDeleteDialogClose = () => {
    setOpenDeleteConfirmationDialog(false);
    setUserToDeleteId('');
    setUserToDeleteName('');
    setDeleteButtonEnabled(true);
  };

  const moveUserToRecycleBin = async () => {
    setDeleteButtonEnabled(false);
    await Axios.delete('/client1/' + userToDeleteId);
    setOpenDeleteConfirmationDialog(false);
    setUserToDeleteId('');
    setUserToDeleteName('');
    setDeleteButtonEnabled(true);
    await getClients();
  };

  const navigateToUsersRecycleBin = async () => {
    history.push('/users/recyclebin');
  };

  useEffect(() => {
    setIsLoading(true);

    const fetchData = async () => {
      await getClients();
      setIsNewPage(false);
    };

    if (contextCurrentPage > currentPage) setCurrentPage(contextCurrentPage);

    if (contextSearchText.length > searchText.length)
      setSeachText(contextSearchText);

    if (contextSortedBy.length > sortedBy.length) setSortedBy(contextSortedBy);

    if (contextSortDirection.length > sortDirection.length)
      setSortDirection(contextSortDirection);

    if (contextFilterColumn.length > filterColumn.length && isNewPage)
      setFilterColumn(contextFilterColumn);
    else setContextFilterColumn(filterColumn);

    if (contextFilterType.length > filterType.length && isNewPage)
      setFilterType(contextFilterType);
    else setContextFilterType(filterType);

    if (
      firstTimeInputValue === '' &&
      isNaN(contextFirstTimeInputValue) === false &&
      isNewPage
    )
      setFirstTimeInputValue(contextFirstTimeInputValue);
    else setContextFirstTimeInputValue(firstTimeInputValue);

    if (
      secondTimeInputValue === '' &&
      isNaN(contextSecondTimeInputValue) === false &&
      isNewPage
    )
      setSecondTimeInputValue(contextSecondTimeInputValue);
    else setContextSecondTimeInputValue(secondTimeInputValue);

    if (
      currentPage === contextCurrentPage &&
      searchText === contextSearchText &&
      sortedBy === contextSortedBy &&
      sortDirection === contextSortDirection &&
      filterColumn === contextFilterColumn &&
      filterType === contextFilterType &&
      firstTimeInputValue === contextFirstTimeInputValue &&
      secondTimeInputValue === contextSecondTimeInputValue
    ) {
      fetchData();
    }
  }, [getUrl()]);

  useEffect(() => {
    const calculateTotalNumberOfPages = () => {
      let c = Math.ceil(totalNumberOfClients / limit);
      c = c === 0 ? 1 : c;
      setTotalNumberOfPages(c);
    };

    calculateTotalNumberOfPages();
  }, [totalNumberOfClients, limit]);

  useEffect(() => {
    const calculateOffset = () => {
      let offset = limit * (currentPage - 1);
      setOffset(offset);
    };

    calculateOffset();
  }, [currentPage, limit]);

  useEffect(() => {
    if (currentPage > totalNumberOfPages) {
      setCurrentPage(totalNumberOfPages);
      setContextCurrentPage(totalNumberOfPages);
    }
  }, [totalNumberOfPages, currentPage]);

  useEffect(() => {
    setContextFilterColumn(filterColumn);
    setContextFilterType(filterType);

    if (filterType.length > 0) {
      setCanSort(false);
      setSortedBy('');
      setContextSortedBy('');
      setSortDirection('');
      setContextSortDirection('');

      if (filterColumn === 'SUBMITTED') setSubmittedFilterIconEnabled(true);
      else setSubmittedFilterIconEnabled(false);

      if (filterColumn === 'DRAFTED') setDraftedFilterIconEnabled(true);
      else setDraftedFilterIconEnabled(false);

      if (filterColumn === 'APPROVED_DRAFTED')
        setApprovedDraftedFilterIconEnabled(true);
      else setApprovedDraftedFilterIconEnabled(false);

      if (filterColumn === 'INVENTORY') setInventoryFilterIconEnabled(true);
      else setInventoryFilterIconEnabled(false);
    } else {
      setCanSort(true);
      setSubmittedFilterIconEnabled(true);
      setDraftedFilterIconEnabled(true);
      setApprovedDraftedFilterIconEnabled(true);
      setInventoryFilterIconEnabled(true);
    }
  }, [filterType, filterColumn]);

  useEffect(() => {
    if (firstTimeInputValue !== '') {
      setContextFirstTimeInputValue(firstTimeInputValue);
    }

    if (secondTimeInputValue !== '') {
      setContextSecondTimeInputValue(secondTimeInputValue);
    }
  }, [firstTimeInputValue, secondTimeInputValue]);

  const handlePageSizeChange = (size) => {
    setLimit(size);
  };

  const handlePageChange = (previousOrNext) => {
    switch (previousOrNext) {
      case 'PREVIOUS':
        if (currentPage - 1 <= 0) {
          setCurrentPage(1);
          setContextCurrentPage(1);
        } else {
          setCurrentPage((currentPage) => currentPage - 1);
          setContextCurrentPage((currentPage) => currentPage - 1);
        }
        break;
      case 'NEXT':
        if (currentPage >= totalNumberOfPages) {
          setCurrentPage(totalNumberOfPages);
          setContextCurrentPage(totalNumberOfPages);
        } else {
          setCurrentPage((currentPage) => currentPage + 1);
          setContextCurrentPage((currentPage) => currentPage + 1);
        }
        break;
      default:
        break;
    }
  };

  const handlePageChangeViaTextBox = (pageNumber) => {
    if (pageNumber - 1 <= 0) {
      setCurrentPage(1);
      setContextCurrentPage(1);
      return;
    }

    if (pageNumber <= totalNumberOfPages) {
      setCurrentPage(pageNumber);
      setContextCurrentPage(pageNumber);
    } else {
      setCurrentPage(totalNumberOfPages);
      setContextCurrentPage(totalNumberOfPages);
    }
  };

  const sortUsers = (sortByColumn) => {
    let tempSortDirection;

    if (canSort) {
      if (sortedBy !== sortByColumn) {
        tempSortDirection = 'asc';
      }

      if (sortedBy === sortByColumn && sortDirection === 'asc')
        tempSortDirection = 'desc';
      else if (sortedBy === sortByColumn && sortDirection === 'desc')
        tempSortDirection = 'asc';

      setSortedBy(sortByColumn);
      setContextSortedBy(sortByColumn);
      setSortDirection(tempSortDirection);
      setContextSortDirection(tempSortDirection);
    }
  };

  const getSortIcon = (columnName) => {
    if (sortedBy === columnName) {
      if (sortDirection === 'asc') return 'fa fa-sort-up';

      if (sortDirection === 'desc') return 'fa fa-sort-down';
    } else {
      return 'fas fa-sort';
    }
  };

  const updateSearchText = (searchText) => {
    setSeachText(searchText);
    setContextSearchText(searchText);
  };

  const reset = () => {
    setOffset(0);
    setCurrentPage(1);
    setContextCurrentPage(1);
    setSeachText('');
    setContextSearchText('');
    setSortedBy('');
    setContextSortedBy('');
    setSortDirection('');
    setContextSortDirection('');
    setFilterColumn('');
    setContextFilterColumn('');
    setFilterType('');
    setContextFilterType('');
    setFirstTimeInputValue('');
    setContextFirstTimeInputValue('');
    setSecondTimeInputValue('');
    setContextSecondTimeInputValue('');
  };

  const usersCsv = users.map((user, idx) => {
    const userI = {};
    userI['username'] = user.userName;
    userI['email'] = user.email;
    userI['submitted'] = user.submittedCount || 0;
    userI['draft'] = user.draftCount || 0;
    userI['inventory'] = user.inventoryCount || 0;
    userI['latestProduct'] = moment(user.lastestProductDate).fromNow();
    userI['dateofjoining'] = user.dateOfJoining;
    userI['balance'] = user.balance?.toFixed(2) || 0;
    userI['isSubscribed'] = user.isSubscribed || false;
    userI['ebayTokenStatus'] = user.ebayUserTokenStatus;
    return userI;
  });

  const setCalendlyLink = () => {
    setCalendlyLinkEnabled(true);
    Axios.get('/calendly/')
      .then(({ data }) => {
        if (data.linkMeeting[0].link.includes('https://on.sprintful.com/')) {
          setCalendlyLinkText(data.linkMeeting[0].link);
        } else {
          SweetAlerts.ErrorToaster.fire({
            icon: 'info',
            title: 'There is no link for meeting set by admin',
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const closeEditLinkModel = () => {
    setCalendlyLinkEnabled(false);
  };

  const editLink = async () => {
    if (calendlyLinkText.includes('https://on.sprintful.com/')) {
      await Axios.post('/calendly', {
        link: calendlyLinkText,
      }).then(({ data }) => {
        console.log(data);
        if (data.message) {
          SweetAlerts.ErrorToaster.fire({
            icon: 'success',
            title: data.message,
          });
        } else {
          SweetAlerts.ErrorToaster.fire({
            icon: 'error',
            title: 'Error in update meeting link please try again later',
          });
        }
        setCalendlyLinkEnabled(false);
        setCalendlyLinkText('');
      });
    } else {
      SweetAlerts.ErrorToaster.fire({
        icon: 'error',
        title: 'Invalid meeting link',
      });
      setCalendlyLinkEnabled(false);
      setCalendlyLinkText('');
    }
  };

  const _handleTextFieldChange = (e) => {
    setCalendlyLinkText(e.target.value);
  };

  return (
    <div>
      <div
        className="d-flex justify-content-between"
        style={{ marginTop: '25px', marginBottom: '25px' }}
      >
        <div>
          <Link className="add-user-button" to={`/AddUser`}>
            <i className="fa fa-user-plus" /> &nbsp; Add User
          </Link>
          <button className="reset-button" onClick={reset}>
            Reset
          </button>
          <button className="reset-button" onClick={setCalendlyLink}>
            Set Link
          </button>
        </div>
        <div>
          <button
            className="clients-recycle-bin-button"
            onClick={navigateToUsersRecycleBin}
          >
            <i className="fas fa-recycle"></i>
            &nbsp; Recycle Bin
          </button>

          <CsvDownload data={usersCsv} className="download-csv-button">
            <i className="fas fa-file-excel"></i> Download
          </CsvDownload>
          <input
            type="text"
            value={searchText}
            onChange={(event) => updateSearchText(event.target.value)}
            style={{ display: 'inline-block', padding: '5px 7px' }}
            placeholder="Search"
          />
        </div>
      </div>

      <Paper className={classes.root}>
        {fallbackMsg ? (
          <Alert severity="warning" className={classes.alert}>
            {fallbackMsg}
          </Alert>
        ) : (
          <>
            <TableContainer className={classes.container}>
              <Table
                className={classes.table}
                size="small"
                stickyHeader
                aria-label="sticky table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>No</TableCell>
                    <StickyTableCell
                      className={classes.head}
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      User Name &nbsp;
                      <i
                        className={getSortIcon('User Name')}
                        onClick={() => sortUsers('User Name')}
                      ></i>
                    </StickyTableCell>
                    <StickyTableCell
                      className={classes.head}
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      Email &nbsp;
                      <i
                        className={getSortIcon('Email')}
                        onClick={() => sortUsers('Email')}
                      ></i>
                    </StickyTableCell>

                    <StickyTableCell
                      className={classes.head}
                      style={{ whiteSpace: 'nowrap', textAlign: 'center' }}
                    >
                      Beta User
                    </StickyTableCell>

                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                      Allocated &nbsp;
                      <i
                        className={getSortIcon('Allocated')}
                        onClick={() => sortUsers('Allocated')}
                      ></i>
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                      Submitted &nbsp;
                      <Tippy
                        theme="material-theme"
                        animation="scale"
                        arrow={false}
                        trigger="click"
                        interactive={true}
                        interactiveBorder={30}
                        allowHTML={true}
                        zIndex={9999999999}
                        disabled={!submittedFilterIconEnabled}
                        strategy="fixed"
                        content={
                          <SubmittedFilterSort
                            setFilterColumn={setFilterColumn}
                            setFilterType={setFilterType}
                            setFirstTimeInputValue={setFirstTimeInputValue}
                            setSecondTimeInputValue={setSecondTimeInputValue}
                          />
                        }
                      >
                        <i
                          className="fa fa-filter"
                          aria-hidden="true"
                          style={{
                            color:
                              filterColumn === 'SUBMITTED' ? '#C589E8' : null,
                          }}
                        ></i>
                      </Tippy>
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                      Drafted &nbsp;
                      <Tippy
                        theme="material-theme"
                        animation="scale"
                        arrow={false}
                        trigger="click"
                        interactive={true}
                        interactiveBorder={30}
                        allowHTML={true}
                        zIndex={9999999999}
                        disabled={!draftedFilterIconEnabled}
                        strategy="fixed"
                        content={
                          <DraftedFilterSort
                            setFilterColumn={setFilterColumn}
                            setFilterType={setFilterType}
                          />
                        }
                      >
                        <i
                          className="fa fa-filter"
                          aria-hidden="true"
                          style={{
                            color:
                              filterColumn === 'DRAFTED' ? '#C589E8' : null,
                          }}
                        ></i>
                      </Tippy>
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                      Approved Drafted &nbsp;
                      <Tippy
                        theme="material-theme"
                        animation="scale"
                        arrow={false}
                        trigger="click"
                        interactive={true}
                        interactiveBorder={30}
                        allowHTML={true}
                        zIndex={9999999999}
                        disabled={!approvedDraftedFilterIconEnabled}
                        strategy="fixed"
                        content={
                          <ApprovedDraftedFilterSort
                            setFilterColumn={setFilterColumn}
                            setFilterType={setFilterType}
                          />
                        }
                      >
                        <i
                          className="fa fa-filter"
                          aria-hidden="true"
                          style={{
                            color:
                              filterColumn === 'APPROVED_DRAFTED'
                                ? '#C589E8'
                                : null,
                          }}
                        ></i>
                      </Tippy>
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                      Inventory &nbsp;
                      <Tippy
                        theme="material-theme"
                        animation="scale"
                        arrow={false}
                        trigger="click"
                        interactive={true}
                        interactiveBorder={30}
                        allowHTML={true}
                        zIndex={9999999999}
                        disabled={!inventoryFilterIconEnabled}
                        strategy="fixed"
                        content={
                          <InventoryFilterSort
                            setFilterColumn={setFilterColumn}
                            setFilterType={setFilterType}
                          />
                        }
                      >
                        <i
                          className="fa fa-filter"
                          aria-hidden="true"
                          style={{
                            color:
                              filterColumn === 'INVENTORY' ? '#C589E8' : null,
                          }}
                        ></i>
                      </Tippy>
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                      Listed &nbsp;
                      <i
                        className={getSortIcon('Listed')}
                        onClick={() => sortUsers('Listed')}
                      ></i>
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                      Products &nbsp;
                      <i
                        className={getSortIcon('Products')}
                        onClick={() => sortUsers('Products')}
                      ></i>
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                      Plan Name &nbsp;
                      <i
                        className={getSortIcon('Rate')}
                        onClick={() => sortUsers('Rate')}
                      ></i>
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                      Balance &nbsp;
                      <i
                        className={getSortIcon('Balance')}
                        onClick={() => sortUsers('Balance')}
                      ></i>
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                      Subscribed &nbsp;
                      <i
                        className={getSortIcon('Subscribed')}
                        onClick={() => sortUsers('Subscribed')}
                      ></i>
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                      Date of Joining &nbsp;
                      <i
                        className={getSortIcon('Date of Joining')}
                        onClick={() => sortUsers('Date of Joining')}
                      ></i>
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                      Latest Product Date &nbsp;
                      <i
                        className={getSortIcon('Latest Product Date')}
                        onClick={() => sortUsers('Latest Product Date')}
                      ></i>
                    </TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}>
                      Ebay Token &nbsp;
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!isLoading ? (
                    users ? (
                      users.map((user, idx) => {
                        return (
                          <TableRow key={nanoid(4)}>
                            <TableCell style={{ whiteSpace: 'nowrap' }}>
                              {limit * currentPage - (limit - idx - 1)}
                            </TableCell>
                            <StickyTableCell style={{ whiteSpace: 'nowrap' }}>
                              <HtmlTooltip
                                title={
                                  <>
                                    <span>{user.userName}</span>
                                  </>
                                }
                              >
                                <span>{user.userName}</span>
                              </HtmlTooltip>
                            </StickyTableCell>
                            <StickyTableCell style={{ whiteSpace: 'nowrap' }}>
                              <HtmlTooltip
                                title={
                                  <>
                                    <span>{user.email}</span>
                                  </>
                                }
                              >
                                <span>{user.email}</span>
                              </HtmlTooltip>
                            </StickyTableCell>
                            <TableCell
                              style={{
                                whiteSpace: 'nowrap',
                                textAlign: 'center',
                              }}
                            >
                              {isBetaUser(user._id) ? (
                                <span className="badge badge-pill badge-success">
                                  YES
                                </span>
                              ) : (
                                <span className="badge badge-pill badge-danger">
                                  NO
                                </span>
                              )}
                            </TableCell>

                            <TableCell style={{ whiteSpace: 'nowrap' }}>
                              {user.isAllocatedTo?.length ? 'Yes' : 'No'}
                            </TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap' }}>
                              {user.submittedCount}
                            </TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap' }}>
                              {user.draftCount}
                            </TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap' }}>
                              {user.approvedDraftCount}
                            </TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap' }}>
                              {user.inventoryCount}
                            </TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap' }}>
                              {user.noOfListings}
                            </TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap' }}>
                              {user.noOfProducts}
                            </TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap' }}>
                              {user.rateName}
                            </TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap' }}>
                              {user.balance ? user.balance.toFixed(2) : 0}
                            </TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap' }}>
                              {user.isSubscribed ? 'Yes' : 'No'}
                            </TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap' }}>
                              {moment(user.dateOfJoining).format('ll')}
                            </TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap' }}>
                              {moment(user.lastestProductDate).fromNow()}
                            </TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap' }}>
                              {user.ebayUserTokenExists ? (
                                user.hasActiveEbayToken ? (
                                  <span className="badge badge-pill badge-success">
                                    Active Token
                                  </span>
                                ) : (
                                  <span className="badge badge-pill badge-warning">
                                    Inactive Token
                                  </span>
                                )
                              ) : (
                                <span className="badge badge-pill badge-danger">
                                  No User Token
                                </span>
                              )}
                            </TableCell>
                            <TableCell className="text-right">
                              <Link
                                to={`/passwords/${user._id}`}
                                className="view-passwords-button"
                              >
                                <i className="fa fa-key" aria-hidden="true"></i>{' '}
                                &nbsp; View Passwords
                              </Link>
                            </TableCell>
                            <TableCell>
                              <Link
                                to={`/listingSettings/${user._id}`}
                                className="view-products-button"
                              >
                                <i
                                  className="fas fa-barcode"
                                  aria-hidden="true"
                                ></i>{' '}
                                &nbsp; View Listing Settings
                              </Link>
                            </TableCell>
                            <TableCell>
                              <Link
                                to={`/productlist/${user._id}`}
                                className="view-products-button"
                              >
                                <i
                                  className="fa fa-product-hunt"
                                  aria-hidden="true"
                                ></i>{' '}
                                &nbsp; View Products
                              </Link>
                            </TableCell>
                            <TableCell>
                              <Link
                                to={`/payment/${user._id}`}
                                className="view-payments-button"
                              >
                                <i className="fas fa-dollar-sign"></i> &nbsp;
                                View Payments
                              </Link>
                            </TableCell>
                            <TableCell>
                              <Link
                                to={`/charts/${user._id}`}
                                className="view-analytics-button"
                              >
                                <i className="fas fa-chart-area"></i> &nbsp;
                                View Analytics
                              </Link>
                            </TableCell>

                            <TableCell>
                              <Tooltip
                                title={
                                  isBetaUser(user._id)
                                    ? 'Cancel the beta user'
                                    : 'Make it as beta user'
                                }
                              >
                                <button
                                  className={
                                    isBetaUser(user._id)
                                      ? 'userBeta-button danger'
                                      : 'userBeta-button info'
                                  }
                                  onClick={() => {
                                    toggleBetaUser(user._id, user.email);
                                  }}
                                >
                                  <i
                                    style={{ color: 'white' }}
                                    className={
                                      isBetaUser(user._id)
                                        ? 'fas fa-user-minus'
                                        : 'fas fa-user-clock'
                                    }
                                  ></i>
                                </button>
                              </Tooltip>
                            </TableCell>

                            <TableCell>
                              {user.cancellation && user.cancellation.reason ? (
                                <Tooltip title="Cancellation Reasons">
                                  <button
                                    className="client-cancel-button"
                                    onClick={() => {
                                      setCancallationOpen(true);
                                      setCancellation(user.cancellation);
                                    }}
                                  >
                                    <i className="fa fa-sign-out-alt"></i>
                                  </button>
                                </Tooltip>
                              ) : (
                                <button
                                  className="client-delete-button"
                                  onClick={() => {
                                    handleDeleteDialogClickOpen();
                                    setUserToDeleteId(user._id);
                                    setUserToDeleteName(user.userName);
                                  }}
                                >
                                  <i className="far fa-trash-alt"></i>
                                </button>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <tr>
                        <td>There are no users...</td>
                      </tr>
                    )
                  ) : (
                    <div className="center">
                      <LoadingSpinner asOverlay />
                    </div>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <UserPagination
              key={nanoid(4)}
              pageSizeOptions={pageSizeOptions}
              limit={limit}
              handlePageSizeChange={handlePageSizeChange}
              handlePageChange={handlePageChange}
              handlePageChangeViaTextBox={handlePageChangeViaTextBox}
              totalNumberOfPages={totalNumberOfPages}
              currentPage={currentPage}
            />
          </>
        )}
      </Paper>
      <Dialog
        open={openDeleteConfirmationDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleDeleteDialogClose}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          {'Delete '}
          <span style={{ fontWeight: '100', color: 'tomato' }}>
            {' '}
            {userToDeleteName}{' '}
          </span>
          {' ?'}
        </DialogTitle>
        <DialogActions>
          <div style={{ padding: '8px 16px' }}>
            <button
              className="client-delete-confirmation-button"
              disabled={!deleteButtonEnabled}
              onClick={moveUserToRecycleBin}
            >
              <i className="far fa-trash-alt"></i>
              &nbsp; Delete
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={cancallationOpen}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          {'Cancellation Reason'}
          <span style={{ fontWeight: '100', color: 'tomato' }}>
            {' '}
            {cancellation.reason}{' '}
          </span>
        </DialogTitle>
        {cancellation.suggestion && (
          <DialogContentText
            style={{
              marginLeft: '25px',
            }}
          >
            {'Note: '}
            <span
              style={{
                fontWeight: '100',
                color: 'tomato',
              }}
            >
              {cancellation.suggestion}
            </span>
          </DialogContentText>
        )}
        <DialogActions>
          <div style={{ padding: '8px 16px' }}>
            <button
              className="client-delete-confirmation-button"
              onClick={() => {
                setCancallationOpen(false);
              }}
            >
              <i className="far fa-trash-alt"></i>
              &nbsp; Close
            </button>
          </div>
        </DialogActions>
      </Dialog>

      <Dialog
        open={calendlyLinkEnabled}
        TransitionComponent={Transition}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle>
          <span>Set Meeting Link</span>
        </DialogTitle>
        <DialogContent>
          <TextField
            id="outlined-basic"
            fullWidth
            label="Meeting Link"
            variant="outlined"
            value={calendlyLinkText}
            onChange={_handleTextFieldChange}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="secondary" onClick={closeEditLinkModel}>
            Close
          </Button>
          <Button variant="secondary" onClick={editLink}>
            Save Link
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserTable;

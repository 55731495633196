import React from 'react';

function AndNode({ brandName, value, update, remove }) {
  return (
    <div className="my-2">
      <span className="bg-secondary text-white px-3 py-2 mx-2">And</span>
      <input
        className="py-1 text-center"
        type="text"
        value={brandName}
        onChange={(e) => update(e.target.value, value)}
      />
      <span className="bg-secondary text-white px-3 py-2 mx-2">Percentage</span>
      <input
        className="py-1 text-center"
        type="text"
        value={value}
        onChange={(e) => update(brandName, parseFloat(e.target.value) || 0)}
      />
      <button className="btn btn-link" onClick={() => remove()}>
        <i className="fas fa-minus text-dark"></i>
      </button>
    </div>
  );
}

export default AndNode;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Axios, { assetsURL, assetsThumbnailURL } from '../../services/Axios';
import './WholesaleBox.css';
import Pagination from '../../Components/Pagination/Pagination';
import { ErrorToaster } from '../../services/SweetAlerts';
import CustomCheckBox from '../utils/CustomCheckBox';
import ProductTransferModal from './Components/ProductTransferModal';
import LoadingSpinner from '../utils/loader';

export default class ViewProduct extends Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      rowPerPage: 10,
      totalPage: 1,
      count: 0,
      products: [],
      searchedProducts: [],
      filteredProducts: [],
      selectedProducts: [],
      isCheckAll: false,
      loading: false,
    };
  }

  fetchData = async () => {
    try {
      this.setState({ loading: true });

      const { store_id, box_id } = this.state;

      let data = await Axios.get(`/wholesale/search`, {
        params: {
          storeId: store_id ? store_id : '',
          boxId: box_id ? box_id : '',
          page: this.state.page,
          size: this.state.rowPerPage,
        },
      });
      data = data.data;
      this.setState({
        products: data.data,
        totalPage: data.pages || 1,
        count: data.count,
        loading: false,
      });
    } catch (err) {
      this.setState({
        products: [],
        totalPage: 1,
        count: 0,
        loading: false,
      });

      let message = err.response?.data?.error?.message
        ? err.response.data.error.message
        : 'Something went wrong';
      ErrorToaster.fire(message);
    }
  };

  downloadDefaultImages = (products) => {
    this.setState({ loading: true });
    try {
      this.setState({ loading: false });
      products.map((product) => {
        const images = product.images;
        Object.keys(images).forEach(async (image) => {
          if (images[image] && image == 'default_image') {
            const response = await fetch(`${assetsURL + images[image]}`);
            response.blob().then((blob) => {
              let url = window.URL.createObjectURL(blob);
              let a = document.createElement('a');
              a.href = url;
              a.download = image + '-' + images[image];
              a.click();
            });
          }
        });
      });
    } catch (err) {
      this.setState({ loading: false });

      let message = err.response?.data?.error?.message
        ? err.response.data.error.message
        : 'Something went wrong';
      ErrorToaster.fire(message);
    }
  };
  handleSearchChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value, rowsPerPage: 10, page: 1 });
  };
  handleSubmit = () => {
    this.setState({ selectedProducts: [], isCheckAll: false });

    this.fetchData();
  };
  selectItemHandler = (e, product) => {
    let updatedSelected = [...this.state.selectedProducts];

    if (e.target.checked) updatedSelected.push(product._id.toString());
    else
      updatedSelected = updatedSelected.filter(
        (p) => p !== product._id.toString()
      );

    this.setState({ selectedProducts: updatedSelected });
  };

  isItemSelected = (product) => {
    const { selectedProducts } = this.state;

    return (
      selectedProducts.length > 0 &&
      selectedProducts.some((p) => p === product._id.toString())
    );
  };
  handleAllChecked = () => {
    this.setState({ isCheckAll: !this.state.isCheckAll });
    let products = this.state.products;
    this.updatedSelected = [...this.state.selectedProducts];
    if (this.state.isCheckAll) {
      this.setState({ selectedProducts: [] });
    } else {
      products.forEach((product) => {
        if (!this.updatedSelected.includes(product._id.toString())) {
          this.updatedSelected.push(product._id.toString());
        }
        this.setState({ selectedProducts: this.updatedSelected });
      });
    }
  };
  handleProductTransfer = () => {
    this.setState({ selectedProducts: [], isCheckAll: false });
    this.fetchData();
  };
  render() {
    const {
      products,
      searchedProducts,
      filteredProducts,
      count,
      store_id,
      box_id,
    } = this.state;
    const dispProducts =
      searchedProducts.length > 0
        ? searchedProducts
        : filteredProducts.length
        ? filteredProducts
        : products;
    return (
      <div>
        <div className="text-align-right mt-1"></div>
        <div className="d-flex justify-content-between py-2">
          <h4 className="text-center mr-auto ml-3">Wholesale Box Search</h4>
          <div className="d-flex justify-content-between py-2">
            <div className="form-group">
              <label htmlFor="store_id" className="search-label label__style">
                ST
                <input
                  type="text"
                  name="store_id"
                  value={store_id}
                  placeholder="001"
                  className="form-control search-input"
                  onChange={this.handleSearchChange}
                />
              </label>
              <label htmlFor="store_id" className="search-label label__style">
                -BX
                <input
                  type="text"
                  name="box_id"
                  value={box_id}
                  placeholder="001"
                  className="form-control search-input"
                  onChange={this.handleSearchChange}
                />
              </label>
            </div>
          </div>
          <div className="d-flex justify-content-between py-2 px-2">
            <div className="form-group">
              <button className="btn btn-primary " onClick={this.handleSubmit}>
                Search
              </button>
            </div>
          </div>
        </div>
        <div className="d-flex py-2">
          <ProductTransferModal
            selectedProducts={this.state.selectedProducts}
            handleProductTransfer={this.handleProductTransfer}
          />
          <div className="d-flex py-2 px-2">
            <div className="form-group">
              {count > 0 && (
                <button
                  className="btn btn-primary "
                  onClick={() => this.downloadDefaultImages(products)}
                >
                  Download All Default Images in this Box
                </button>
              )}
            </div>
          </div>
        </div>
        {!this.state.loading ? (
          dispProducts.length > 0 ? (
            <table className="table">
              <thead>
                <tr>
                  <th>No</th>
                  <th className="pb-3">
                    Action
                    <CustomCheckBox
                      onChange={this.handleAllChecked}
                      checked={this.state.isCheckAll}
                    />
                  </th>
                  <th className="imageIt">Image</th>
                  <th>Customer's Email</th>
                  <th>Title</th>
                  <th>SKU</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                {dispProducts.map((product, idx) => {
                  const thumbnailPath =
                    product.images && product.images.default_image
                      ? product.images.default_image.substring(
                          0,
                          product.images.default_image.indexOf(':')
                        ) !== 'http' &&
                        product.images.default_image.substring(
                          0,
                          product.images.default_image.indexOf(':')
                        ) !== 'https'
                        ? assetsThumbnailURL + product.images.default_image
                        : product.images.default_image
                      : '';
                  const fullSizePath =
                    product.images && product.images.default_image
                      ? product.images.default_image.substring(
                          0,
                          product.images.default_image.indexOf(':')
                        ) !== 'http' &&
                        product.images.default_image.substring(
                          0,
                          product.images.default_image.indexOf(':')
                        ) !== 'https'
                        ? assetsURL + product.images.default_image
                        : product.images.default_image
                      : '';
                  return (
                    <tr key={`row id with ${idx}`}>
                      <td>
                        {this.state.rowPerPage * (this.state.page - 1) +
                          idx +
                          1}
                      </td>
                      <td className="product-checkbox">
                        <CustomCheckBox
                          onChange={(e) => this.selectItemHandler(e, product)}
                          checked={this.isItemSelected(product)}
                        />
                      </td>
                      <td>
                        <img
                          src={thumbnailPath}
                          className="product-img"
                          onError={(e) => {
                            if (e.target.src !== fullSizePath) {
                              e.target.src = fullSizePath;
                            }
                            e.target.onerror = null;
                          }}
                          alt="Default Image Missing"
                        />
                      </td>
                      <td className="search-product-td-label">
                        {product.user.email}
                      </td>
                      <td className="search-product-td-label">
                        <Link to={`/product/${product.userId}/${product._id}`}>
                          {product.title}
                        </Link>
                      </td>
                      <td className="search-product-td-label">{product.sku}</td>
                      <td className="search-product-td-label">
                        {product.price}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className="w-50 mx-auto py-2">
              <div className="d-flex justify-content-center">
                No Product Found
              </div>
            </div>
          )
        ) : (
          <div className="center">
            <LoadingSpinner asOverlay />
          </div>
        )}
        <div className="w-50 mx-auto py-2">
          <Pagination
            curPage={this.state.page}
            totalPage={this.state.totalPage}
            rowPerPage={this.state.rowPerPage}
            next={(page) =>
              this.setState({ page: page }, () => {
                this.setState({ selectedProducts: [], isCheckAll: false });
                this.fetchData();
              })
            }
            previous={(page) =>
              this.setState({ page: page > 0 ? page : 1 }, () => {
                this.setState({ selectedProducts: [], isCheckAll: false });
                this.fetchData();
              })
            }
            onRowNumberChange={(row) =>
              this.setState({ rowPerPage: row, page: 1 }, () => {
                this.setState({ selectedProducts: [], isCheckAll: false });
                this.fetchData();
              })
            }
          />
        </div>
      </div>
    );
  }
}

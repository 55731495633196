import React, { useState, useEffect, useRef } from 'react';
import { on, off, trigger } from '../../services/event';
import { Accordion, Button, ProgressBar, CloseButton } from 'react-bootstrap';
import './FileUpload.css';

const UploadFiles = (state) => {
  const [progressInfos, setProgressInfos] = useState([]);

  const appendFile = ({ detail }) => {
    const id = detail.data.id;
    const file = progressInfos.find((o) => o.id === id);
    if (!file) {
      setProgressInfos([
        ...progressInfos,
        {
          id,
          title: id.substring(id.indexOf('-') + 1, id.length),
          percentage: 0,
        },
      ]);
    }
  };

  const removeFile = ({ detail }) => {
    setProgressInfos([...progressInfos.filter((o) => o.id !== detail.data.id)]);
  };

  const setImageError = ({ detail }) => {
    const index = progressInfos.findIndex((o) => o.id === detail.data.id);
    if (index > -1) {
      progressInfos[index] = {
        ...progressInfos[index],
        error: 'Upload failed:',
      };
      setProgressInfos([...progressInfos]);
    }
  };

  const setImageProgress = ({ detail }) => {
    const id = detail.data.id;
    const index = progressInfos.findIndex((o) => o.id === id);

    // const modules =
    //   detail.percentage % 2 === 0 || detail.percentage === 99 ? true : false;
    if (index > -1 && detail.percentage < 100) {
      if (detail.percentage === progressInfos[index].percentage) {
        return true;
      }
      progressInfos[index] = {
        id,
        percentage: detail.percentage,
        title: id.substring(id.indexOf('-') + 1, id.length),
      };
      setProgressInfos([...progressInfos]);
    }
  };

  const removeImage = (id) => {
    trigger('image:remove', { data: { id } });
  };

  useEffect(() => {
    on('image:progress', setImageProgress);
    on('image:append', appendFile);
    on('image:restart', appendFile);
    on('image:complete', removeFile);
    on('image:remove', removeFile);
    on('image:error', setImageError);
    return () => {
      off('image:progress', setImageProgress);
      off('image:append', appendFile);
      off('image:restart', appendFile);
      off('image:complete', removeFile);
      on('image:remove', removeFile);
      off('image:error', setImageError);
    };
  }, [progressInfos]);

  return (
    <div>
      {progressInfos.length > 0 && (
        <div className="fab">
          <Accordion defaultActiveKey="0">
            <div className="card ">
              <Accordion.Toggle as={Button} eventKey="0">
                <h5 className="card-header bg-c-yellow">Uploading Images..</h5>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <div className="card-body">
                  <div className="card-text">
                    <div className="row">
                      <div className="col-12">
                        {progressInfos.map((progressInfo, index) => (
                          <div>
                            <label
                              htmlFor="example"
                              className={progressInfo.error ? 'error' : ''}
                              key={'label-' + index}
                            >
                              {progressInfo.error} {progressInfo.title}
                            </label>
                            <CloseButton
                              onClick={() => removeImage(progressInfo.id)}
                            />
                            <ProgressBar
                              animated
                              now={progressInfo.percentage}
                              label={`${progressInfo.percentage}%`}
                              key={'image-' + index}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </Accordion.Collapse>
            </div>
          </Accordion>
        </div>
      )}
    </div>
  );
};

export default UploadFiles;

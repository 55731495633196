import React from 'react';
import { nanoid } from 'nanoid';
import { Link, withRouter } from 'react-router-dom';
import BackButton from '../../../Components/BackButton';

const AgentTable = (props) => {
  const { agents, deleteAgent, search, handleSearchChange, searchedAgents } =
    props;
  const dispAgents =
    searchedAgents.length > 0 || search.length > 0 ? searchedAgents : agents;

  return (
    <>
      <div>
        <div className="d-flex justify-content-between">
          <BackButton />
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              value={search}
              onChange={handleSearchChange}
              placeholder="Search"
              autoFocus
            />
          </div>
        </div>
        <table className="table text-center  table-hover c-pointer">
          <thead>
            <tr>
              <th>Username</th>
              <th>Clients Allocated</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {dispAgents.map((agent) => {
              return (
                <tr key={nanoid(5)}>
                  <td
                    onClick={() => props.history.push(`/allocate/${agent._id}`)}
                  >
                    {agent.username}
                  </td>
                  <td>{agent.noOfClients}</td>
                  <td className="d-flex justify-content-center text-white">
                    <Link
                      to={`/allocate/${agent._id}`}
                      className="bg-primary rounded p-1 px-2"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Allocate user"
                      style={{ margin: '3px' }}
                    >
                      <i className="fas fa-users-cog"></i>
                    </Link>
                    <Link
                      to={`/editagent/${agent._id}`}
                      className="bg-primary rounded p-1 px-2"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Edit Agent"
                      style={{ margin: '3px' }}
                    >
                      <i className="fas fa-pencil-alt"></i>
                    </Link>
                    <div
                      className="bg-danger rounded p-1 px-2"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Delete Agent"
                      onClick={() => deleteAgent(agent._id)}
                      style={{ margin: '3px' }}
                    >
                      <i className="fas fa-trash-alt"></i>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default withRouter(AgentTable);

import React from 'react';
import LeafNode from './LeafNode';
import AndNode from './AndNode';
import OrNode from './OrNode';

function IfThen({
  rules,
  addRules,
  addAndRule,
  addOrRule,
  remove,
  update,
  result,
  setResult,
}) {
  return (
    <div>
      <span className="bg-secondary text-white px-3 py-2 mx-2">if</span>
      {rules.map((rule, i) => {
        if (rule.type === 'leafNode')
          return (
            <LeafNode
              key={i}
              brandName={rule.key}
              value={rule.value}
              update={(key, value) => update(i, key, value)}
            />
          );
        else if (rule.type === 'operatorNode' && rule.op === 'and')
          return (
            <AndNode
              key={i}
              brandName={rule.key}
              value={rule.value}
              update={(key, value) => update(i, key, value)}
              remove={() => remove(i)}
            />
          );
        else
          return (
            <OrNode
              key={i}
              brandName={rule.key}
              value={rule.value}
              update={(key, value) => update(i, key, value)}
              remove={() => remove(i)}
            />
          );
      })}
      <span className="bg-secondary text-white px-3 py-2 mx-2">then</span>
      <input
        className="py-1 text-center"
        value={result}
        onChange={(e) => setResult(e.target.value)}
        type="text"
      />
      <div className="mt-3">
        <button
          className="btn btn-link bg-secondary text-white px-3 py-2 mx-2 rounded-0 text-decoration-none"
          onClick={addAndRule}
        >
          And
        </button>
        ||
        <button
          className="btn btn-link bg-secondary text-white px-3 py-2 mx-2 rounded-0 text-decoration-none"
          onClick={addOrRule}
        >
          or
        </button>
      </div>
    </div>
  );
}

export default IfThen;

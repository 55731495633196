import React, { useRef, useEffect } from 'react';
import './NewMessagesSectionAccordian.css';

const NewMessagesSectionAccordian = (props) => {
  const accordianRef = useRef();

  useEffect(() => {
    props.setNewMessagesAccordianHeight(accordianRef.current.offsetHeight);
  }, []);

  return (
    <>
      <div
        ref={accordianRef}
        className="new-messages-collapsible-section-accordian"
      >
        <div>
          <span
            id="new-messages-section-chevron"
            style={{ margin: '0px 10px', fontSize: '1em' }}
            onClick={() => props.handleCollapse(!props.isCollapsed)}
          >
            {props.isCollapsed === false ? (
              <i className="fas fa-chevron-down"></i>
            ) : (
              <i className="fas fa-chevron-right"></i>
            )}
          </span>
          <span style={{ fontSize: '0.8em', userSelect: 'none' }}>
            New Messages
          </span>
        </div>
      </div>
    </>
  );
};

export default NewMessagesSectionAccordian;

import React from 'react';

import {
  BrowserRouter as Router,
  Route,
  withRouter,
  Redirect,
  Switch,
} from 'react-router-dom';

import FilesUplaod from './Components/FilesUpload/FilesUpload';
import Header from './Components/Header';

import {
  Agent,
  EditAgent,
  AllocateClient,
  Login,
  Users,
  ViewPasswords,
  Payments,
  Charts,
  AddUser,
  Maintenance,
  Messages,
  Rate,
  AddRules,
  Rules,
  UpdateRule,
  Analytics,
  AgentDashboard,
  AgentListingDetails,
  EditAndUpdateRate,
  ProductList,
  ListForm,
  ListingSettingsPortal,
  ProductEditLog,
  Productlogs,
  Notifications,
  Rates,
  ErrorMap,
  GmailAccount,
  SystemSettings,
  WholesaleBox,
} from './Pages';

// import MLSettings from "./Pages/MLSettings/MLSettings";

import { createBrowserHistory } from 'history';
import tokenChecker from './services/interceptor';

const history = createBrowserHistory();
tokenChecker.setupInterceptors(history);

const Routes = withRouter((props) => {
  const { pathname } = props.location;
  return (
    <div className="App">
      {!['/login', '/health'].includes(pathname) && (
        <div className="fixed-top">
          <Header />
          <FilesUplaod />
        </div>
      )}
      <div className="container-fluid px-5 py-5 admin my-5">
        {localStorage.getItem('token') ? (
          <Switch>
            <Route exact path="/messages" component={Messages} />
            <Route path="/users" component={Users} />
            <Route
              exact
              path="/passwords/:clientid"
              component={ViewPasswords}
            />
            <Route exact path="/AddUser" component={AddUser} />
            <Route exact path="/maintenance" component={Maintenance} />
            <Route exact path="/agent" component={Agent} />
            <Route exact path="/editagent/:agentid" component={EditAgent} />
            <Route exact path="/allocate/:agentid" component={AllocateClient} />
            <Route exact path="/rates" component={Rates} />
            <Route exact path="/rates/:id" component={EditAndUpdateRate} />
            <Route exact path="/systemSettings" component={SystemSettings} />
            <Route exact path="/payment/:clientid" component={Payments} />
            <Route exact path="/charts/:clientid" component={Charts} />
            <Route exact path="/rules" component={Rules} />
            <Route exact path="/rules/add" component={AddRules} />
            <Route exact path="/rules/:id" component={UpdateRule} />
            <Route exact path="/notifications" component={Notifications} />
            <Route exact path="/soldnotifications" component={GmailAccount} />
            <Route exact path="/errormap" component={ErrorMap} />
            <Route exact path="/agentDashboard" component={AgentDashboard} />
            <Route
              exact
              path="/productlogs/:productId"
              component={Productlogs}
            />
            <Route
              exact
              path="/agentDashboard/:id"
              component={AgentListingDetails}
            />
            <Route exact path="/analytics" component={Analytics} />
            <Route
              exact
              path="/productlist/:clientid"
              component={ProductList}
            />
            <Route exact path="/producteditlog" component={ProductEditLog} />
            <Route
              exact
              path="/product/:clientid/:productid"
              component={ListForm}
            />
            <Route
              exact
              path="/listingSettings/:clientid"
              component={ListingSettingsPortal}
            />
            <Route path="/wholesaleBox" component={WholesaleBox} />
            <Route path="/" component={() => <Redirect to="/users" />} />
          </Switch>
        ) : (
          <div>
            <Switch>
              <Route path="/health">
                <h3>The App is Healthy</h3>
              </Route>
              <Route exact path="/login" component={Login} />
              <Route path="/" component={() => <Redirect to="/login" />} />
            </Switch>
          </div>
        )}
      </div>
    </div>
  );
});

function App() {
  return (
    <div className="App">
      <Router>
        <Routes />
      </Router>
    </div>
  );
}
//
export default App;

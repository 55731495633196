import React, { Component } from 'react';
import AllocateForm from './Components/AllocateForm';
import ClientTable from './Components/ClientTable';
import Axios from '../../services/Axios';
import { Confirmer } from 'src/services/SweetAlerts';

const $ = window.$;

class AllocateClient extends Component {
  constructor() {
    super();
    this.state = {
      allocatedClients: [],
      clients: [],
    };
  }
  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip();
    const { agentid } = this.props.match.params;
    const queries = [
      Axios.get(`/allocated/${agentid}`),
      Axios.get(`/pendingallocation`),
    ];
    Promise.all(queries)
      .then(([allocatedClientsRes, clientsRes]) => {
        this.setState({
          allocatedClients: allocatedClientsRes.data,
          clients: clientsRes.data,
        });
      })
      .catch((err) => console.log(err));
  }

  allocateClient = (clientid) => {
    const { agentid } = this.props.match.params;
    Axios.post(`/allocate/`, { clientid, agentid })
      .then(({ data }) => {
        const { allocatedClients, clients } = this.state;
        allocatedClients.push(data);
        const idx = clients.findIndex((client) => client._id === data._id);
        clients.splice(idx, 1);
        this.setState({ allocatedClients, clients });
      })
      .catch((err) => console.log(err));
  };

  nooflistprod = (clientid) => {
    const { agentid } = this.props.match.params;
    Axios.post(`/allocate/`, { clientid, agentid })
      .then(({ data }) => {
        const { allocatedClients, clients } = this.state;
        allocatedClients.push(data);
        const idx = clients.findIndex((client) => client._id === data._id);
        clients.splice(idx, 1);
        this.setState({ allocatedClients, clients });
      })
      .catch((err) => console.log(err));
  };

  deAllocateClient = async (clientid) => {
    const { agentid } = this.props.match.params;
    const result = await Confirmer.fire({
      text: 'Are you sure you want to deallocate client?',
      showConfirmButton: true,
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No',
    });

    if (result.isConfirmed) {
      Axios.delete(`/deallocate/${clientid}`, {
        headers: {
          agentid: agentid,
        },
      })
        .then(({ data }) => {
          const { allocatedClients, clients } = this.state;
          clients.push(data);
          const idx = allocatedClients.findIndex(
            (client) => client._id === data._id
          );
          allocatedClients.splice(idx, 1);
          this.setState({ allocatedClients, clients });
        })
        .catch((err) => console.log(err));
    }
  };

  render() {
    const { allocatedClients, clients } = this.state;
    return (
      <div className="row">
        <div className="col-8">
          <ClientTable
            allocatedClients={allocatedClients}
            deAllocateClient={this.deAllocateClient}
          />
        </div>
        <div className="col-4">
          <AllocateForm
            allocateClient={this.allocateClient}
            clients={clients}
          />
        </div>
      </div>
    );
  }
}

export default AllocateClient;

import React, { useState, useEffect } from 'react';
import Axios from '../../../services/Axios';
import NewMessagesSectionAccordian from './NewMessagesSectionAccordian';
import NewMessagesClientList from './NewMessagesClientList';
import './NewMessagesSection.css';
import socket from '../../../services/socket';

const NewMessagesSection = React.forwardRef((props, ref) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    //socket.disconnect();
    socket.connect();

    socket.on('connect', function () {
      socket.emit('joinMessageRoomForAdmin', localStorage.getItem('token'));
    });

    socket.on('newMessage', function (data) {
      props.setNewMessagesClients((clients) => {
        let key =
          data.message.fromUser.userType === 'CLIENT'
            ? data.message.fromUser.userId +
              '|' +
              data.message.fromUser.userName
            : data.message.toUser[0].userType === 'CLIENT'
            ? data.message.toUser[0].userId +
              '|' +
              data.message.toUser[0].userName
            : null;

        if (key === null) return clients;

        //if (clients.filter((client) => Object.keys(client)[0] == key).length == 0 &&
        // (data.message.fromUser.userType == 'ADMIN' || data.message.fromUser.userType == 'AGENT')) {
        // return clients;
        // }

        clients =
          clients.filter((client) => Object.keys(client)[0] === key).length ===
          0
            ? [...clients, { [key]: [] }]
            : clients;
        return clients.map((client, idx) => {
          if (Object.keys(client)[0] === key) {
            let tempKey = Object.keys(client)[0];
            if (
              Object.values(client)[0].filter(
                (message) => message.messageId === data.message.messageId
              ).length === 0
            ) {
              return { [tempKey]: [...Object.values(client)[0], data.message] };
            }
            return {
              [tempKey]: [
                ...Object.values(client)[0].map((message, idx) => {
                  if (
                    Object.values(client)[0].filter(
                      (message) => message.messageId === data.message.messageId
                    ).length === 0
                  )
                    return data.message;
                  if (message.messageId === data.message.messageId) {
                    return data.message;
                  } else {
                    return message;
                  }
                }),
              ],
            };
          } else {
            return client;
          }
        });
      });
    });

    /*        return () => {
            socket.disconnect();
        };*/
  }, []);

  useEffect(() => {
    props.setNewMessagesSectionHeight(ref.current.offsetHeight);
  }, [props.newMessagesClients.length, isCollapsed]);

  useEffect(() => {
    setIsCollapsed(!props.newMessagesSectionExpanded);
  }, [props.newMessagesSectionExpanded]);

  function sortClientsByDate(clients) {
    return clients.sort(
      (a, b) =>
        new Date(
          Object.values(b)[0][Object.values(b)[0].length - 1].creationDate
        ) -
        new Date(
          Object.values(a)[0][Object.values(a)[0].length - 1].creationDate
        )
    );
  }

  return (
    <>
      <div id="newMessagesSection" ref={ref} style={{ width: '100%' }}>
        <NewMessagesSectionAccordian
          setNewMessagesAccordianHeight={props.setNewMessagesAccordianHeight}
          isCollapsed={isCollapsed}
          handleCollapse={(value) => {
            setIsCollapsed(value);
            props.setNewMessagesSectionExpanded(!value);
          }}
        />
        {isCollapsed === false ? (
          <NewMessagesClientList
            clients={sortClientsByDate(props.newMessagesClients)}
            setSelectedClient={props.setSelectedClient}
            selectedClient={props.selectedClient}
            newMessagesClients={props.newMessagesClients}
            maxNewMessagesSectionHeight={props.maxNewMessagesSectionHeight}
          />
        ) : null}
      </div>
    </>
  );
});

export default NewMessagesSection;

import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Table, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Rules() {
  const [rules, setRules] = useState([]);

  useEffect(() => fetchRules(), []);

  const deleteRule = (i, id) => {
    Axios.delete('rule/delete/' + id).then(
      setRules(rules.filter((rule, idx) => idx !== i))
    );
  };

  const fetchRules = () => {
    Axios.get('rule').then((rules) => {
      setRules(
        rules.data.map((rule) => ({
          rule: JSON.parse(rule.conditionType[0]),
          id: rule._id,
        }))
      );
    });
  };

  return (
    <div>
      <h2>
        Rules
        <Link className="float-right" to="rules/add">
          <Button size="sm" variant="parimary">
            <i className="fas fa-plus"></i> Add Rule
          </Button>
        </Link>
      </h2>
      <Table>
        <thead>
          <tr>
            <th>No.</th>
            <th>Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {rules.map((rule, i) => {
            return (
              <tr key={i}>
                <td>{i + 1}</td>
                <td>
                  <Link to={'rules/' + rule.id} style={{ color: 'black' }}>
                    {rule.rule.output}
                  </Link>
                </td>
                <td>
                  <Button variant="link" onClick={() => deleteRule(i, rule.id)}>
                    <i
                      style={{ color: 'red' }}
                      className="fas fa-trash-alt"
                    ></i>
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

export default Rules;

import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { makeStyles } from '@material-ui/core/styles';
import { nanoid } from 'nanoid';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: '76vh',
  },
});

function CustomerDataTable(props) {
  const classes = useStyles();
  const { usersInRange, usersProducts } = props;

  const [allData, setAllData] = useState([]);
  const [sortedBy, setSortedBy] = useState();
  const [sortOrder, setSortOrder] = useState();
  const headers = [
    'First Name',
    'Last Name',
    'Email',
    'Total Products Submitted',
    'Products Submitted This Month',
    'Products Submitted Last Month',
    'Change in Products Submitted',
    'Products Submitted Past 7 Days',
    'Products with status submitted older than 2 days',
  ];

  useEffect(() => {
    setAllData(usersInRange);
  }, [usersInRange, usersProducts]);

  /**
   * Summary: Sort array of usersInRange
   * @param {Int} sortValue  Value to sort usersInRange by corresponding to the headers array (0:"First Name", 1:"Last Name", 2:"Email", 3:"Total Products Submitted", 4:"Products Submitted This Month", 5:"Products Submitted Last Month", 6:"Change in Products Submitted", 7: "Products Submitted Past 7 Days", 8: Products with status submitted older than 2 days)
   */
  function sortUsers(sortValue) {
    //Set sorting states
    let sortDirection = 'asc';
    if (headers[sortValue] === sortedBy && sortOrder === 'asc') {
      sortDirection = 'desc';
    }
    setSortOrder(sortDirection);
    setSortedBy(headers[sortValue]);
    let sortedData = [...allData];
    sortedData = sortedData.sort((a, b) => {
      let valA = null;
      let valB = null;
      switch (sortValue) {
        //Sort by first name
        case 0:
          valA = a.firstName.toLowerCase();
          valB = b.firstName.toLowerCase();
          break;
        //Sort by last name
        case 1:
          valA = a.lastName.toLowerCase();
          valB = b.lastName.toLowerCase();
          break;
        //Sort by email
        case 2:
          valA = a.email.toLowerCase();
          valB = b.email.toLowerCase();
          break;
        //Sort by total products submitted
        case 3:
          valA = a.productsTotal;
          valB = b.productsTotal;
          break;
        //Sort by products submitted this month
        case 4:
          valA = a.productsThisMonth;
          valB = b.productsThisMonth;
          break;
        //Sort by products submitted last month
        case 5:
          valA = a.productsLastMonth;
          valB = b.productsLastMonth;
          break;
        //Sort by change in products submitted
        case 6:
          valA = a.productsThisMonth - a.productsLastMonth;
          valB = b.productsThisMonth - b.productsLastMonth;
          break;
        //Sort by num products submitted in past 7 days
        case 7:
          valA = a.productsSevenDays;
          valB = b.productsSevenDays;
          break;
        //Sort by num products submitted more than 2 days ago with status submitted
        case 8:
          valA = a.productsTwoDaysSubmitted;
          valB = b.productsTwoDaysSubmitted;
          break;
        default:
          break;
      }
      if (sortDirection === 'asc') {
        if (valA > valB) return 1;
        if (valB > valA) return -1;
        else return 0;
      } else {
        if (valA < valB) return 1;
        if (valB < valA) return -1;
        else return 0;
      }
    });
    setAllData(sortedData);
  }

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>No</TableCell>
              {headers.map((header, index) => (
                <TableCell key={'header' + index}>
                  <TableSortLabel
                    active={header === sortedBy}
                    onClick={() => sortUsers(index)}
                    direction={sortOrder}
                  >
                    {header}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {allData.map((user, idx) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={nanoid(5)}>
                  <TableCell>{idx + 1}</TableCell>
                  <TableCell style={{ maxWidth: '200px' }}>
                    {user.firstName}
                  </TableCell>
                  <TableCell style={{ maxWidth: '200px' }}>
                    {user.lastName}
                  </TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.productsTotal}</TableCell>
                  <TableCell>{user.productsThisMonth}</TableCell>
                  <TableCell>{user.productsLastMonth}</TableCell>
                  <TableCell
                    style={
                      user.productsThisMonth - user.productsLastMonth > 0
                        ? { color: 'green' }
                        : user.productsThisMonth - user.productsLastMonth < 0
                        ? { color: 'red' }
                        : { color: 'black' }
                    }
                  >
                    {user.productsThisMonth - user.productsLastMonth}
                  </TableCell>
                  <TableCell>{user.productsSevenDays}</TableCell>
                  <TableCell>{user.productsTwoDaysSubmitted}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default CustomerDataTable;

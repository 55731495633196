import React, { useState, useEffect } from 'react';
import Axios from '../../../services/Axios';
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import { Grid, Button, TextField, Typography } from '@material-ui/core';

const MaintenanceForm = () => {
  const [maintenanceMsg, setMaintenanceMsg] = useState('');
  const [startDate, setStartDate] = useState(
    moment().add(1, 'days').format('YYYY-MM-DDTHH:mm')
  );
  const [endDate, setEndDate] = useState(
    moment().add(25, 'hours').format('YYYY-MM-DDTHH:mm')
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [downTime, setDownTime] = useState('1 hour');
  const [errMsg, setErrMsg] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  //validating time difference
  useEffect(() => {
    const timeDiff = moment(endDate).diff(moment(startDate), 'minutes');
    let msg = '';
    if (timeDiff < 1) {
      setDownTime('');
      return;
    } else if (timeDiff < 60) msg = timeDiff + ' minutes';
    else if (timeDiff < 60 * 24) msg = Math.round(timeDiff / 60) + ' hours';
    else if (isNaN(timeDiff) || !timeDiff) {
      setDownTime('');
    } else {
      msg = Math.round(timeDiff / (60 * 24)) + ' days';
    }
    setDownTime(msg);
  }, [startDate, endDate]);

  const generateMaintenanceMsg = () => {
    setMaintenanceMsg(
      `Due to a scheduled maintenance activity, Hammoq Application will not be available from ${moment(
        startDate
      ).format('YYYY-MM-DD hh:mm a')} to ${moment(endDate).format(
        'YYYY-MM-DD hh:mm a'
      )}. We apologize for any inconvenience this may cause. Your patience is highly appreciated.`
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    //checking time difference
    const timeDiff = moment(endDate).diff(moment(startDate), 'minutes');
    if (timeDiff < 1) {
      setErrMsg('Kindly add a correct date.');
      setShowAlert(true);
      return;
    }
    setErrMsg('');
    setShowAlert(false);

    setIsSubmitting(true);

    const reqBody = {
      startDate,
      endDate,
      downTime,
      maintenanceMsg,
    };

    Axios.post('/maintenance/addAlert', reqBody)
      .then((res) => {
        if (res.data.err) {
          setIsSubmitting(false);
          return setErrMsg(res.data.err);
        }
        //sucess
        setIsSubmitting(false);
        setErrMsg(res.data.msg);
        setShowAlert(true);
        setMaintenanceMsg('');
        // window.open("/maintenance", "_self");
      })
      .catch((err) => {
        const alertMsg = err?.response?.data?.msg
          ? err.response.data.msg
          : err?.response?.data?.err
          ? err.response.data.err
          : 'Something went wrong while setting the Maintenance Mood.';
        setErrMsg(alertMsg);
        setShowAlert(true);
        setIsSubmitting(false);
      });
  };

  return (
    <Grid container justifyContent="center" direction="column">
      <form className="maintenance-form">
        <div
          style={{
            visibility: showAlert ? 'visible' : 'hidden',
          }}
          className="alert-msg"
        >
          {errMsg && showAlert && (
            <Alert
              elevation={6}
              variant="filled"
              onClose={() => setShowAlert(false)}
              severity="error"
            >
              {errMsg}
            </Alert>
          )}
        </div>
        <Typography>All fields are required</Typography>

        <Grid container direction="column">
          <Grid item>
            <div className="fieldContainer">
              <TextField
                id="datetime-local"
                label="From"
                type="datetime-local"
                defaultValue={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="date-picker text-field"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </Grid>
          <Grid item>
            <div className="fieldContainer">
              <TextField
                id="datetime-local"
                label="To"
                type="datetime-local"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="date-picker text-field"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
          </Grid>
        </Grid>
        <div style={{ visibility: downTime ? 'visible' : 'hidden' }}>
          <div>
            <Typography className="fieldTitle">
              {`Downtime: ${downTime}`}
            </Typography>
          </div>
        </div>

        {/* <Grid container> */}
        <div className="maintenance-msg fieldContainer">
          <TextField
            variant="outlined"
            label="Maintenance Message"
            type="text"
            // fullWidth
            cols="20"
            name="maintenanceMsg"
            value={maintenanceMsg}
            onChange={(e) => setMaintenanceMsg(e.target.value)}
            required
            multiline
          ></TextField>
        </div>
        <div className="fieldContainer">
          <Button
            variant="contained"
            type="button"
            onClick={generateMaintenanceMsg}
          >
            Generate Message
          </Button>
        </div>
        {/* </Grid> */}
        <div>
          {isSubmitting ? (
            <button className="btn btn-success" disabled>
              <span
                className="spinner-border spinner-border-sm mr-2"
                role="status"
                aria-hidden="true"
              />
              Submitting...
            </button>
          ) : (
            <button className="btn btn-primary" onClick={handleSubmit}>
              Submit
            </button>
          )}
        </div>
      </form>
    </Grid>
  );
};

export default MaintenanceForm;

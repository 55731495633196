import React, { Component } from 'react';
import { nanoid } from 'nanoid';
import Search from '../utils/Search';
import { Link } from 'react-router-dom';
import Axios, { assetsURL, assetsThumbnailURL } from '../../services/Axios';
import './ProductList.css';
import Pagination from './Components/Pagination';
import ListedProductURL from './Components/ListedProductURL';
import Badge from '@material-ui/core/Badge';
import MailIcon from '@material-ui/icons/Mail';
import { SuccessToaster, ErrorToaster } from '../../services/SweetAlerts';
import { Button } from '@material-ui/core';
import CustomCheckBox from '../utils/CustomCheckBox';
import StatusActionColumn from './Components/StatusActionColumn';
import StatusColumn from './Components/StatusColumn';
import ProductChangeTrigger from './Components/ProductChangeTrigger';
import { DebounceInput } from 'react-debounce-input';

const allowedStatusToUseBulk = ['draft', 'inventory', 'approved_drafts'];
export default class ViewProduct extends Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      rowPerPage: 10,
      totalPage: 1,
      products: [],
      productMessageSeen: [],
      labels: [],
      search: '',
      searchedProducts: [],
      clientname: '',
      datesort: false,
      filterstate: 0,
      productf: [],
      noOfListings: 0,
      noOfProducts: 0,
      filteredProducts: [],
      submittedCount: 0,
      draftCount: 0,
      inventoryCount: 0,
      productStatus: 'submitted',
      selectedProducts: [],
    };
  }

  downloadImages = (images) => {
    Object.keys(images).forEach(async (image) => {
      if (images[image]) {
        const response = await fetch(`${assetsURL + images[image]}`);
        response.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = image + '-' + images[image];
          a.click();
        });
      }
    });
  };

  componentDidMount = () => {
    this.fetchData();
    this.fetchListingDetails();
  };

  handleInventory = () => {
    const Inventory = this.state.products.filter(
      (product) => product.prodStatus === 'inventory'
    );
    this.setState({ filteredProducts: Inventory });
  };

  handleDrafts = () => {
    const Drafts = this.state.products.filter(
      (product) => product.status === 'draft'
    );
    this.setState({ filteredProducts: Drafts });
  };

  handleSubmitted = () => {
    const submittedProduct = this.state.products.filter(
      (product) => product.prodStatus === 'submitted'
    );
    this.setState({ filteredProducts: submittedProduct });
  };

  fetchListingDetails = () => {
    const { clientid } = this.props.match.params;

    Axios.get(`/client1/${clientid}`)
      .then((data) => {
        this.setState({
          noOfListings: data.data[0].noOfListings,
          noOfProducts: data.data[0].noOfProducts,
          clientname: data.data[0].firstName,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  fetchData = () => {
    const { clientid } = this.props.match.params;
    Axios.get(`/product/${clientid}/type/${this.state.productStatus}`, {
      params: {
        page: this.state.page,
        size: this.state.rowPerPage,
        search: this.state.search,
      },
    })
      .then(({ data }) => {
        this.setState({
          products: data.data,
          submittedCount: data.count.submittedCount,
          draftCount: data.count.draftCount,
          inventoryCount: data.count.inventoryCount,
          totalPage: data.pages || 1,
        });
      })
      .catch((err) => console.log(err));
  };

  handleFilter = (newState) => {
    this.setState({ productStatus: newState }, () => {
      this.fetchData();
    });
  };

  handleSearchChange = (e) => {
    const { value } = e.target;
    this.setState({ search: value, rowsPerPage: 10, page: 1 }, async () => {
      this.fetchData();
    });
  };

  datesort = () => {
    let { products, searchedProducts, filteredProducts, datesort } = this.state;
    if (products.length > 0)
      products = products.reverse().map((product, idx) => {
        return product;
      });
    if (searchedProducts.length > 0)
      searchedProducts = searchedProducts.reverse().map((product, idx) => {
        return product;
      });
    if (filteredProducts.length > 0)
      filteredProducts = filteredProducts.reverse().map((product, idx) => {
        return product;
      });
    this.setState({ products, searchedProducts, filteredProducts });
    this.setState({ datesort: !datesort });
  };

  selectItemHandler = (e, product) => {
    let updatedSelected = [...this.state.selectedProducts];

    if (e.target.checked) updatedSelected.push(product._id.toString());
    else
      updatedSelected = updatedSelected.filter(
        (p) => p !== product._id.toString()
      );

    this.setState({ selectedProducts: updatedSelected });
  };

  isItemSelected = (product) => {
    const { selectedProducts } = this.state;

    return (
      selectedProducts.length > 0 &&
      selectedProducts.some((p) => p === product._id.toString())
    );
  };

  moveToInventoryHandler = async (fromStatus) => {
    const { selectedProducts, inventoryCount, draftCount, approvedDraftCount } =
      this.state;

    const payload = selectedProducts.map((pId) => ({
      productId: pId,
      status: 'inventory',
    }));
    try {
      await Axios.put('product/status/bulk', { statusChanges: payload });

      this.updateListsAndResetSelectedProducts(
        selectedProducts,
        inventoryCount + selectedProducts.length,
        fromStatus === 'draft'
          ? draftCount - selectedProducts.length
          : draftCount,
        fromStatus === 'approved_drafts'
          ? approvedDraftCount - selectedProducts.length
          : approvedDraftCount
      );

      SuccessToaster.fire(
        'The products has been moved to inventory successfully'
      );
    } catch (error) {
      console.log('error', error);
      ErrorToaster.fire('Something went wrong.');
    }
  };

  moveToDraftHandler = async () => {
    const { selectedProducts, draftCount, inventoryCount, approvedDraftCount } =
      this.state;

    const payload = selectedProducts.map((pId) => ({
      productId: pId,
      status: 'draft',
    }));

    try {
      await Axios.put('product/status/bulk', { statusChanges: payload });

      this.updateListsAndResetSelectedProducts(
        selectedProducts,
        inventoryCount - selectedProducts.length,
        draftCount + selectedProducts.length,
        approvedDraftCount
      );
      SuccessToaster.fire('The products has been moved to draft successfully');
    } catch (error) {
      console.log('error', error);
      ErrorToaster.fire('Something went wrong.');
    }
  };

  updateListsAndResetSelectedProducts = (
    selectedProducts,
    updatedInventoryCount,
    updatedDraftCount,
    updatedApprovedDraftCount
  ) => {
    const updatedProducts = this.state.products.filter(
      (p) => !selectedProducts.some((s) => s === p._id.toString())
    );
    const updatedSearchedProducts = this.state.searchedProducts.filter(
      (p) => !selectedProducts.some((s) => s === p._id.toString())
    );
    const updatedFilteredProducts = this.state.filteredProducts.filter(
      (p) => !selectedProducts.some((s) => s === p._id.toString())
    );

    this.setState({
      selectedProducts: [],
      products: updatedProducts,
      searchedProducts: updatedSearchedProducts,
      filteredProducts: updatedFilteredProducts,
      inventoryCount: updatedInventoryCount,
      draftCount: updatedDraftCount,
      approvedDraftCount: updatedApprovedDraftCount,
    });
  };

  render() {
    const {
      products,
      search,
      searchedProducts,
      datesort,
      filteredProducts,
      productMessageSeen,
      clientname,
      inventoryCount,
      draftCount,
      submittedCount,
      page,
      totalPage,
      rowPerPage,
    } = this.state;
    const { clientid } = this.props.match.params;
    const dispProducts =
      searchedProducts.length > 0
        ? searchedProducts
        : filteredProducts.length
        ? filteredProducts
        : products;
    return (
      <div>
        <div className="text-align-right mt-1"></div>
        <ProductChangeTrigger
          products={dispProducts}
          currentPage={page}
          totalPage={totalPage}
          rowsPerPage={rowPerPage}
        />
        <div className="d-flex justify-content-between py-2">
          <h4 className="text-center mr-auto ml-3">
            Seller : {clientname}'s Products
          </h4>
          <div className="form-group">
            <DebounceInput
              type="text"
              id="searcheverything"
              className="form-control col-12 ml-2"
              value={search}
              debounceTimeout={300}
              onChange={this.handleSearchChange}
              placeholder="Search"
            />
          </div>
        </div>
        <div className="product__info d-flex justify-content-between">
          <div style={{ justifyContent: 'space-evenly' }}>
            <button
              className="btn btn-primary d-inline mr-1 mb-1"
              onClick={() => {
                this.handleFilter('inventory');
              }}
            >
              {inventoryCount} - Inventory
            </button>
            <button
              className="btn btn-primary d-inline mr-1 mb-1"
              onClick={() => {
                this.handleFilter('draft');
              }}
            >
              {draftCount} - Drafts
            </button>
            <button
              className="btn btn-primary d-inline mr-1 mb-1"
              onClick={() => {
                this.handleFilter('submitted');
              }}
            >
              {submittedCount} - Submitted
            </button>
          </div>
          <div>
            {(this.state.productStatus === 'draft' ||
              this.state.productStatus === 'approved_drafts') && (
              <Button
                variant="contained"
                color="primary"
                disabled={!this.state.selectedProducts.length}
                style={{ marginLeft: 5 }}
                onClick={() =>
                  this.moveToInventoryHandler(this.state.productStatus)
                }
              >
                Move to Inventory
              </Button>
            )}

            {this.state.productStatus === 'inventory' && (
              <Button
                variant="contained"
                color="primary"
                disabled={!this.state.selectedProducts.length}
                style={{ marginLeft: 5 }}
                onClick={this.moveToDraftHandler}
              >
                Move to Draft
              </Button>
            )}
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>No</th>
              {allowedStatusToUseBulk.includes(this.state.productStatus) && (
                <th>#</th>
              )}
              <th className="imageIt">Image</th>
              <th>Title</th>
              <th>Status</th>
              <th>
                <b>Action</b>
              </th>
              <th>
                <button
                  className="btn btn-sm"
                  onClick={() => {
                    this.datesort();
                  }}
                >
                  {' '}
                  <i
                    className={datesort ? 'fas fa-sort-up' : 'fas fa-sort-down'}
                    aria-hidden="true"
                  ></i>
                  <b>DATE</b>
                </button>
              </th>
              <th>Notes</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {dispProducts.map((product, idx) => {
              const thumbnailPath =
                product.images && product.images.default_image
                  ? product.images.default_image.substring(
                      0,
                      product.images.default_image.indexOf(':')
                    ) !== 'http' &&
                    product.images.default_image.substring(
                      0,
                      product.images.default_image.indexOf(':')
                    ) !== 'https'
                    ? assetsThumbnailURL + product.images.default_image
                    : product.images.default_image
                  : '';
              const fullSizePath =
                product.images && product.images.default_image
                  ? product.images.default_image.substring(
                      0,
                      product.images.default_image.indexOf(':')
                    ) !== 'http' &&
                    product.images.default_image.substring(
                      0,
                      product.images.default_image.indexOf(':')
                    ) !== 'https'
                    ? assetsURL + product.images.default_image
                    : product.images.default_image
                  : '';
              return (
                <tr key={`row id with ${idx}`}>
                  <td>
                    {this.state.rowPerPage * (this.state.page - 1) + idx + 1}
                  </td>

                  {allowedStatusToUseBulk.includes(
                    this.state.productStatus
                  ) && (
                    <td className="product-checkbox">
                      <CustomCheckBox
                        onChange={(e) => this.selectItemHandler(e, product)}
                        checked={this.isItemSelected(product)}
                      />
                    </td>
                  )}

                  <td>
                    <img
                      src={thumbnailPath}
                      className="product-img"
                      onError={(e) => {
                        if (e.target.src !== fullSizePath) {
                          e.target.src = fullSizePath;
                        }
                        e.target.onerror = null;
                      }}
                      alt="Default Image Missing"
                    />
                    <button
                      className="btn btn-small btn-primary mt-2"
                      onClick={() => this.downloadImages(product.images)}
                    >
                      Download Images
                    </button>
                  </td>
                  <td className="product-title">{product.title}</td>
                  <td>
                    <StatusColumn product={product} />
                  </td>
                  <td>
                    <StatusActionColumn clientId={clientid} product={product} />
                  </td>
                  <td>{product.date && product.date.split('T')[0]}</td>
                  <td className="product-note">{product.note}</td>
                  <td>
                    <Link
                      to={`/productlogs/${product._id}`}
                      className="btn btn-success btn-sm"
                    >
                      Product Logs
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="w-50 mx-auto py-2">
          <Pagination
            curPage={this.state.page}
            totalPage={this.state.totalPage}
            rowPerPage={this.state.rowPerPage}
            next={(page) =>
              this.setState({ page: page }, () => this.fetchData())
            }
            previous={(page) =>
              this.setState({ page: page }, () => this.fetchData())
            }
            onRowNumberChange={(row) =>
              this.setState({ rowPerPage: row }, () => this.fetchData())
            }
          />
        </div>
      </div>
    );
  }
}

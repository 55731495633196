import React, { useState, useEffect, useRef } from 'react';
import Axios from '../../../services/Axios';
import './MessagesDisplayArea.css';
import ReceivedMessage from './ReceivedMessage';
import SentMessage from './SentMessage';
import socket from '../../../services/socket';

const MessagesDisplayArea = (props) => {
  const [messages, setMessages] = useState([]);
  const [offsetReverse, setOffsetReverse] = useState(0);
  const [limitReverse, setLimitReverse] = useState(5);
  const [offsetNormal] = useState(0);
  const [limitNormal] = useState(1);
  const [localCopyOfSelectedTab, setLocalCopyOfSelectedTab] = useState('');
  const [scrollLock, setScrollLock] = useState(false);
  const [unreadMessagesList, setUnreadMessagesList] = useState([]);

  const messageDisplayAreaRef = useRef();

  const getUrlForMessagesInReverseOrder = () => {
    let url;

    if (props.selectedTab?.tabId) {
      url =
        'messagesNeo/messagesReverse/' +
        props.selectedTab.tabId +
        '?&limit=' +
        limitReverse;

      url = url + '&offset=' + offsetReverse;
    }

    return url;
  };

  const getUrlForMessagesInNormalOrder = () => {
    let url;

    if (props.selectedTab?.tabId) {
      url =
        'messagesNeo/messagesNormal/' +
        props.selectedTab.tabId +
        '?&limit=' +
        limitNormal;

      url = url + '&offset=' + offsetNormal;
    }

    return url;
  };

  useEffect(() => {
    socket.removeAllListeners('markedAsRead');

    socket.on('totalUnreadMessages', function (data) {
      props.setTotalUnreadMessagesInSelectedTab(data.totalUnreadMessages);
    });

    socket.on('markedAsRead', function (data) {
      socket.emit('getTotalUnreadMessages', {
        userId: props.selectedTab?.tabId,
        roomId: localStorage.getItem('token'),
      });

      props.setClientAndTotalUnreadMessagesMap(
        (clientAndTotalUnreadMessagesMap) => {
          if (
            clientAndTotalUnreadMessagesMap.filter(
              (clientAndTotalUnreadMap) =>
                Object.keys(clientAndTotalUnreadMap)[0] === data.userId
            ).length === 0
          ) {
            return [
              ...clientAndTotalUnreadMessagesMap,
              {
                [data.userId]: {
                  totalUnreadMessages: data.totalUnreadMessages,
                },
              },
            ];
          } else {
            return [
              ...clientAndTotalUnreadMessagesMap.map(
                (clientAndTotalUnreadMap) => {
                  if (Object.keys(clientAndTotalUnreadMap)[0] === data.userId) {
                    if (
                      Object.values(clientAndTotalUnreadMap)[0] >= 0 &&
                      data.totalUnreadMessages === -1
                    )
                      return { [data.userId]: { totalUnreadMessages: 0 } };
                    else if (data.totalUnreadMessages !== -1)
                      return {
                        [data.userId]: {
                          totalUnreadMessages: data.totalUnreadMessages,
                        },
                      };
                    else return clientAndTotalUnreadMap;
                  } else {
                    return clientAndTotalUnreadMap;
                  }
                }
              ),
            ];
          }
        }
      );

      props.setNewMessagesClients((newMessagesClients) =>
        newMessagesClients.map((newMessagesClient) => {
          if (Object.keys(newMessagesClient)[0].split('|')[0] === data.userId) {
            return {
              [Object.keys(newMessagesClient)[0]]: [
                ...Object.values(newMessagesClient)[0].map((message) => {
                  if (message.messageId === data.messageId) {
                    return {
                      ...message,
                      messageStatus: 'READ',
                      totalNewMessagesAfterLogin:
                        data.totalNewMessagesAfterLogin,
                      totalUnreadMessages: data.totalUnreadMessages,
                    };
                  } else {
                    return message;
                  }
                }),
              ],
            };
          } else {
            return newMessagesClient;
          }
        })
      );
    });

    if (!props.selectedTab?.tabId) {
      setMessages([]);
      return;
    }

    if (
      props.selectedTab?.tabId &&
      props.selectedTab?.tabId !== localCopyOfSelectedTab
    ) {
      setMessages([]);
    }
  }, [props.selectedTab]);

  useEffect(() => {
    if (messages.length === 0) {
      setOffsetReverse(0);
      setLimitReverse(5);
      setLocalCopyOfSelectedTab(props.selectedTab?.tabId);
      setScrollLock(false);
      setUnreadMessagesList([]);
    }
  }, [messages]);

  useEffect(() => {
    getMessagesInReverseOrder();
  }, [getUrlForMessagesInReverseOrder()]);

  useEffect(() => {
    if (props.refreshDisplayArea === true) getMessagesInNormalOrder();
  }, [props.refreshDisplayArea]);

  useEffect(() => {
    let originalNewMessages = [];

    let newMessages = [];

    if (
      props.newMessagesClients.filter(
        (client) =>
          Object.keys(client)[0].split('|')[0] === props.selectedTab.tabId
      )[0]
    )
      originalNewMessages = Object.values(
        props.newMessagesClients.filter(
          (client) =>
            Object.keys(client)[0].split('|')[0] === props.selectedTab.tabId
        )[0]
      )[0];

    newMessages = originalNewMessages ? originalNewMessages : [];

    newMessages = newMessages.filter(
      (message1) =>
        messages.filter((message) => message.messageId === message1.messageId)
          .length === 0
    );

    if (messages.length > 0)
      newMessages = newMessages.filter(
        (message) =>
          new Date(message.creationDate) >
          new Date(messages[messages.length - 1].creationDate)
      );

    if (messages.length === 0) {
      setMessages((messages) => [...messages, ...newMessages]);

      for (let newMessage of newMessages) {
        if (unreadMessagesList.includes(newMessage.messageId) === false) {
          markMessageAsRead(newMessage);
          setUnreadMessagesList([...unreadMessagesList, newMessage.messageId]);
        }
      }
    }
    if (newMessages.length <= messages.length) {
      setMessages((messages) => [...messages, ...newMessages]);

      for (let newMessage of newMessages) {
        if (unreadMessagesList.includes(newMessage.messageId) === false) {
          markMessageAsRead(newMessage);
          setUnreadMessagesList([...unreadMessagesList, newMessage.messageId]);
        }
      }
    }
  }, [props.newMessagesClients]);

  const getMessagesInReverseOrder = async () => {
    let url = getUrlForMessagesInReverseOrder();
    if (url) {
      const res = await Axios.get(url);

      let tempMessages =
        res.data?.messages?.map((message) => message.messages).reverse() || [];

      setMessages((messages) => [...(tempMessages.concat(messages) || [])]);

      for (let tempMessage of tempMessages) {
        if (unreadMessagesList.includes(tempMessage.messageId) === false) {
          markMessageAsRead(tempMessage);
          setUnreadMessagesList([...unreadMessagesList, tempMessage.messageId]);
        }
      }

      setScrollLock(false);
    }
  };

  const getMessagesInNormalOrder = async () => {
    let url = getUrlForMessagesInNormalOrder();
    if (url) {
      const res = await Axios.get(url);

      let tempMessages =
        res.data?.messages?.map((message) => message.messages) || [];

      setMessages((messages) => [...messages, ...tempMessages]);

      for (let tempMessage of tempMessages) {
        if (tempMessage.messageStatus === 'UNREAD')
          markMessageAsRead(tempMessage);
      }

      props.setRefreshDisplayArea(false);

      setScrollLock(false);
    }
  };

  useEffect(() => {
    messageDisplayAreaRef.current.scrollTop =
      messageDisplayAreaRef.current.scrollHeight;
  }, [messages[messages.length - 1]]);

  const handleScroll = (e) => {
    if (
      e.target.scrollTop === 0 &&
      scrollLock === false &&
      messages.length > 0
    ) {
      setScrollLock(true);
      setOffsetReverse((offsetReverse) => offsetReverse + limitReverse);
      e.target.scrollTop += 180;
    }
  };

  const markMessageAsRead = async (message) => {
    if (message?.fromUser?.userType === 'CLIENT')
      socket.emit('markMessageAsRead', {
        message,
        roomId: localStorage.getItem('token'),
      });
  };

  return (
    <div
      className="messages-display-area"
      ref={messageDisplayAreaRef}
      onScroll={handleScroll}
    >
      {messages.map((message) => {
        if (message.fromUser.userType === 'CLIENT') {
          return <ReceivedMessage message={message} />;
        }

        if (
          message.fromUser.userType === 'ADMIN' ||
          message.fromUser.userType === 'AGENT'
        ) {
          return <SentMessage message={message} />;
        }
      })}
    </div>
  );
};

export default MessagesDisplayArea;

import React, { useEffect, useState } from 'react';
import './OtherMessagesFilterSort.css';
import Select from 'react-select';

const OtherMessagesFilterSort = (props) => {
  const [filterTypeOptions] = useState([
    { value: 'Unread Messages', label: 'Unread Messages' },
    { value: 'Unanswered Messages', label: 'Unanswered Messages' },
    { value: 'Client Joining Date', label: 'Client Joining Date' },
  ]);

  const [sortDirection] = useState([
    { value: 'Recent First', label: 'Recent First' },
    { value: 'Oldest First', label: 'Oldest First' },
  ]);

  const [selectedFilterType, setSelectedFilterType] = useState('');
  const [selectedSortDirection, setSelectedSortDirection] = useState('');
  const [disabledApplyButtonClass, setDisabledApplyButtonClass] = useState('');

  useEffect(() => {
    if (props.filterType === 'FILTER_UNREAD_MESSAGES_RECENT_FIRST') {
      setSelectedFilterType({
        value: 'Unread Messages',
        label: 'Unread Messages',
      });
      setSelectedSortDirection({
        value: 'Recent First',
        label: 'Recent First',
      });
    }

    if (props.filterType === 'FILTER_UNREAD_MESSAGES_OLDEST_FIRST') {
      setSelectedFilterType({
        value: 'Unread Messages',
        label: 'Unread Messages',
      });
      setSelectedSortDirection({
        value: 'Oldest First',
        label: 'Oldest First',
      });
    }

    if (props.filterType === 'FILTER_UNANSWERED_MESSAGES_RECENT_FIRST') {
      setSelectedFilterType({
        value: 'Unanswered Messages',
        label: 'Unanswered Messages',
      });
      setSelectedSortDirection({
        value: 'Recent First',
        label: 'Recent First',
      });
    }

    if (props.filterType === 'FILTER_UNANSWERED_MESSAGES_OLDEST_FIRST') {
      setSelectedFilterType({
        value: 'Unanswered Messages',
        label: 'Unanswered Messages',
      });
      setSelectedSortDirection({
        value: 'Oldest First',
        label: 'Oldest First',
      });
    }

    if (props.filterType === 'FILTER_BY_CLIENT_JOINING_DATE_RECENT_FIRST') {
      setSelectedFilterType({
        value: 'Client Joining Date',
        label: 'Client Joining Date',
      });
      setSelectedSortDirection({
        value: 'Recent First',
        label: 'Recent First',
      });
    }

    if (props.filterType === 'FILTER_BY_CLIENT_JOINING_DATE_OLDEST_FIRST') {
      setSelectedFilterType({
        value: 'Client Joining Date',
        label: 'Client Joining Date',
      });
      setSelectedSortDirection({
        value: 'Oldest First',
        label: 'Oldest First',
      });
    }
  }, [props.filterType]);

  useEffect(() => {
    if (
      (selectedFilterType?.value?.length || 0) === 0 ||
      (selectedSortDirection?.value?.length || 0) === 0
    )
      setDisabledApplyButtonClass('apply-disabled');
    else setDisabledApplyButtonClass('');
  }, [selectedFilterType, selectedSortDirection]);

  const handleFilterTypeChange = (selectedOption) => {
    setSelectedFilterType(selectedOption);
  };

  const handleSortDirectionChange = (selectedOption) => {
    setSelectedSortDirection(selectedOption);
  };

  const apply = () => {
    if (disabledApplyButtonClass.length > 0) return;

    if (selectedFilterType.value === 'Unread Messages') {
      if (selectedSortDirection.value === 'Recent First') {
        props.setFilterType('FILTER_UNREAD_MESSAGES_RECENT_FIRST');
      }

      if (selectedSortDirection.value === 'Oldest First') {
        props.setFilterType('FILTER_UNREAD_MESSAGES_OLDEST_FIRST');
      }
    }

    if (selectedFilterType.value === 'Unanswered Messages') {
      if (selectedSortDirection.value === 'Recent First') {
        props.setFilterType('FILTER_UNANSWERED_MESSAGES_RECENT_FIRST');
      }

      if (selectedSortDirection.value === 'Oldest First') {
        props.setFilterType('FILTER_UNANSWERED_MESSAGES_OLDEST_FIRST');
      }
    }

    if (selectedFilterType.value === 'Client Joining Date') {
      if (selectedSortDirection.value === 'Recent First') {
        props.setFilterType('FILTER_BY_CLIENT_JOINING_DATE_RECENT_FIRST');
      }

      if (selectedSortDirection.value === 'Oldest First') {
        props.setFilterType('FILTER_BY_CLIENT_JOINING_DATE_OLDEST_FIRST');
      }
    }
  };

  const clear = () => {
    setSelectedFilterType('');
    setSelectedSortDirection('');
    props.setFilterType('');
  };

  return (
    <div className="submitted-filter-sort-container">
      <div>
        <span style={{ fontWeight: '400' }}>Filter By</span>
        <Select
          className="basic-single"
          classNamePrefix="select"
          isDisabled={false}
          isClearable={true}
          isSearchable={true}
          autosize={true}
          value={selectedFilterType}
          options={filterTypeOptions}
          onChange={handleFilterTypeChange}
          style={{ display: 'inline-block' }}
        />
      </div>
      <div>
        <span style={{ fontWeight: '400' }}>Sort By</span>
        <Select
          className="basic-single"
          classNamePrefix="select"
          isDisabled={
            !(
              selectedFilterType?.value === 'Unread Messages' ||
              selectedFilterType?.value === 'Unanswered Messages' ||
              selectedFilterType?.value === 'Client Joining Date'
            )
          }
          isClearable={true}
          value={selectedSortDirection}
          isSearchable={true}
          options={sortDirection}
          onChange={handleSortDirectionChange}
        />
      </div>
      <div>
        <div
          id="submitted-filter-apply-button"
          className={disabledApplyButtonClass}
          onClick={() => apply()}
        >
          Apply
        </div>
        <div id="clear-filter-apply-button" onClick={() => clear()}>
          Clear
        </div>
      </div>
    </div>
  );
};

export default OtherMessagesFilterSort;

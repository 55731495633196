import React, { useState } from 'react';
export const AdminUsersContext = React.createContext();

const AdminUsersPageProvider = (props) => {
  const [contextCurrentPage, setContextCurrentPage] = useState(1);
  const [contextSearchText, setContextSearchText] = useState('');
  const [contextLimit, setContextLimit] = useState(0);
  const [contextOffset, setContextOffset] = useState(0);
  const [contextSortedBy, setContextSortedBy] = useState('');
  const [contextSortDirection, setContextSortDirection] = useState('');
  const [contextFilterColumn, setContextFilterColumn] = useState('');
  const [contextFilterType, setContextFilterType] = useState('');
  const [contextFirstTimeInputValue, setContextFirstTimeInputValue] =
    useState('');
  const [contextSecondTimeInputValue, setContextSecondTimeInputValue] =
    useState('');

  return (
    <AdminUsersContext.Provider
      value={{
        contextCurrentPage,
        setContextCurrentPage,
        contextSearchText,
        setContextSearchText,
        contextLimit,
        setContextLimit,
        contextOffset,
        setContextOffset,
        contextSortedBy,
        setContextSortedBy,
        contextSortDirection,
        setContextSortDirection,
        contextFilterColumn,
        setContextFilterColumn,
        contextFilterType,
        setContextFilterType,
        contextFirstTimeInputValue,
        setContextFirstTimeInputValue,
        contextSecondTimeInputValue,
        setContextSecondTimeInputValue,
      }}
    >
      {props.children}
    </AdminUsersContext.Provider>
  );
};

export default AdminUsersPageProvider;

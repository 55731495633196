import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import AdminUsersPageProvider from '../src/ContextProviders/AdminUsersPageProvider';
import App from './App';
import './index.css';
import store from './redux/store';
import './services/images.jsx';
import { initializeDataDog, logger } from './datadog';

initializeDataDog({
  clientToken: 'pub97b19544264a4be021e054b2b79de32e',
  applicationId: 'd43c2817-6ee4-466e-bb9f-10f377d4e419',
  serviceName: 'hammoq-admin-client',
  appVersion: process.env.REACT_APP_SHA || 'no-version-local',
  trackSessionAcrossSubdomains: true,
});

logger.info('application starting up');

// build
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AdminUsersPageProvider>
        <App />
      </AdminUsersPageProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

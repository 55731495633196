import React, { Component } from 'react';
import Axios from '../../services/Axios';
import './addUser.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import {
  Confirmer,
  ErrorToaster,
  SuccessToaster,
} from '../../services/SweetAlerts';

class AddUser extends Component {
  constructor() {
    super();
    this.state = {
      balance: 0,
      subscribedByAdmin: false,
      firstName: '',
      lastName: '',
      email: '',
      phoneno: '',
      password: '',
      confirmPassword: '',
      referralCode: '',
      isSubmitting: false,
      isValidating: {},
      isValid: {},
      hasValidationRan: {},
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleValidate = (name) => {
    let { isValidating, isValid, hasValidationRan } = this.state;
    isValidating[name] = true;
    hasValidationRan[name] = true;
    this.setState({ isValidating, hasValidationRan });

    const body = { fieldvalue: this.state[name] };

    Axios.post(`/client1/validatefield/${name}`, body)
      .then((res) => {
        isValidating[name] = false;
        if (res.data) {
          isValid[name] = res.data.AlreadyExists;
        }
        this.setState({ isValidating, isValid });
      })
      .catch((err) => {
        isValidating[name] = false;
        isValid[name] = true;
        this.setState({ isValidating, isValid });
      });
  };

  handleToggleCheckbox = (e) => {
    const { name } = e.target;
    this.setState({ [name]: !this.state[name] });
  };

  handleSubmit = (e) => {
    this.setState({ isSubmitting: true });
    e.preventDefault();
    const {
      password,
      confirmPassword,
      firstName,
      lastName,
      email,
      phoneno,
      referralCode,
    } = this.state;

    if (email === '') {
      this.setState({ isSubmitting: false });
      return Confirmer.fire({ text: 'Email field is required.' });
    }

    if (phoneno === '') {
      this.setState({ isSubmitting: false });
      return Confirmer.fire({ text: 'Phone Number field is required.' });
    }

    if (firstName === '' || lastName === '') {
      this.setState({ isSubmitting: false });
      return Confirmer.fire('First Name & Last Name is required.');
    }

    if (password === '' || confirmPassword === '') {
      this.setState({ isSubmitting: false });
      return Confirmer.fire('Password & Confirm Password is required.');
    }

    if (password !== confirmPassword) {
      this.setState({ isSubmitting: false });
      return Confirmer.fire('Password does not match.');
    }

    const body = { ...this.state };
    Axios.post('/client1/addUser', body)
      .then(async (res) => {
        if (res.data.err) {
          this.setState({ isSubmitting: false });
          return ErrorToaster.fire({ text: res.data.err });
        }
        await SuccessToaster.fire({
          text: res.data.msg,
          showConfirmButton: true,
        });
        window.open('/users', '_self');
      })
      .catch(async (err) => {
        const alertMsg = err?.response?.data?.msg
          ? err.response.data.msg
          : err?.response?.data?.err
          ? err.response.data.err
          : 'Something went wrong while adding user.';
        await Confirmer.fire({ text: alertMsg });
        this.setState({ isSubmitting: false });
      });
  };

  insideIcon = (name) => {
    const { hasValidationRan, isValid, isValidating } = this.state;
    return hasValidationRan[name] ? (
      isValidating[name] ? (
        <i className="fa fa-cog fa-spin fa-2x fa-fw" />
      ) : isValid[name] ? (
        <i className="fa fa-check-circle-o fa-2x text-success" />
      ) : (
        <i
          className="fa fa-times-circle-o fa-2x text-danger"
          aria-hidden="true"
        />
      )
    ) : (
      ' '
    );
  };

  render() {
    const {
      balance,
      subscribedByAdmin,
      firstName,
      lastName,
      email,
      phoneno,
      password,
      confirmPassword,
      isSubmitting,
      referralCode,
      isValidating,
      isValid,
      hasValidationRan,
    } = this.state;
    return (
      <div>
        <form className="formIt mt-7">
          <br />
          <h5>All fields are required</h5>
          <br />

          <div className="fieldContainer">
            <Typography className="fieldTitle" htmlFor="firstname">
              FIRST NAME:
            </Typography>
            <TextField
              variant="outlined"
              type="text"
              name="firstName"
              value={firstName}
              onChange={this.handleChange}
              className="form-control"
              required
            ></TextField>
          </div>

          <div className="fieldContainer">
            <Typography className="fieldTitle" htmlFor="lastname">
              LAST NAME:
            </Typography>
            <TextField
              variant="outlined"
              type="text"
              name="lastName"
              value={lastName}
              onChange={this.handleChange}
              className="form-control"
              required
            ></TextField>
          </div>

          <div className="fieldContainer">
            <div className="validateAlign">
              <Typography style={{ flexGrow: 1 }} htmlFor="email">
                EMAIL:{' '}
              </Typography>
              <Button
                className="validateButton"
                color="primary"
                onClick={(e) => {
                  this.handleValidate('email');
                }}
                endIcon={<CheckIcon />}
              >
                VALIDATE
              </Button>
            </div>
            <TextField
              variant="outlined"
              type="email"
              name="email"
              value={email}
              onChange={this.handleChange}
              className="form-control"
              required
              InputProps={{ endAdornment: this.insideIcon('email') }}
            ></TextField>
          </div>

          <div className="fieldContainer">
            <div className="validateAlign">
              <Typography className="fieldTitle" htmlFor="subscription">
                User Subscription Enabled:{' '}
              </Typography>
              <Checkbox
                name="subscribedByAdmin"
                checked={subscribedByAdmin}
                onChange={this.handleToggleCheckbox}
              />
            </div>
          </div>

          <div className="fieldContainer">
            <Typography className="fieldTitle" htmlFor="email">
              User Balance:{' '}
            </Typography>
            <TextField
              variant="outlined"
              type="number"
              name="balance"
              value={balance}
              onChange={this.handleChange}
              className="form-control"
              required
            ></TextField>
          </div>

          <div className="fieldContainer">
            <Typography className="fieldTitle" htmlFor="state">
              PHONE.NO:
            </Typography>
            <TextField
              variant="outlined"
              type="text"
              name="phoneno"
              value={phoneno}
              onChange={this.handleChange}
              className="form-control"
              required
            ></TextField>
          </div>

          <div className="fieldContainer">
            <Typography className="fieldTitle" htmlFor="password">
              PASSWORD:
            </Typography>
            <TextField
              variant="outlined"
              type="password"
              name="password"
              value={password}
              onChange={this.handleChange}
              className="form-control"
              required
            ></TextField>
          </div>

          <div className="fieldContainer">
            <Typography className="fieldTitle" htmlFor="passwordconfirmation">
              PASSWORD CONFIRMATION:
            </Typography>
            <TextField
              variant="outlined"
              type="password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={this.handleChange}
              className="form-control"
              required
            ></TextField>
          </div>

          <div className="fieldContainer">
            <div className="validateAlign">
              <Typography
                style={{ flexGrow: 1 }}
                htmlFor="passwordconfirmation"
              >
                REFERRAL CODE:{' '}
              </Typography>
              <Button
                className="validateButton"
                color="primary"
                onClick={(e) => {
                  this.handleValidate('referralCode');
                }}
                endIcon={<CheckIcon />}
              >
                VALIDATE
              </Button>
            </div>
            <TextField
              variant="outlined"
              type="text"
              name="referralCode"
              value={referralCode}
              onChange={this.handleChange}
              className="form-control"
              InputProps={{ endAdornment: this.insideIcon('referralCode') }}
            ></TextField>
          </div>

          <div className="fieldContainer">
            {isSubmitting ? (
              <button className="btn btn-success" disabled>
                <span
                  className="spinner-border spinner-border-sm mr-2"
                  role="status"
                  aria-hidden="true"
                />
                ADDING USER...
              </button>
            ) : (
              <button className="btn btn-primary" onClick={this.handleSubmit}>
                Add User
              </button>
            )}
          </div>
        </form>
      </div>
    );
  }
}

export default AddUser;

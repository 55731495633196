import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Axios from '../../../services/Axios';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableBody } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import './UsersRecycleBinTable.css';
import { nanoid } from 'nanoid';
import LoadingSpinner from '../../utils/loader';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    minHeight: '80vh',
    maxHeight: '80vh',
  },
});

const UsersRecycleBinTable = (props) => {
  const classes = useStyles();

  const [users, setUsers] = useState([]);
  const [userIdOfUserBeingRestored, setUserIdOfUserBeingRestored] =
    useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const getUsers = async () => {
      await Axios.get('/client1/recycled').then((res) => {
        setUsers(res?.data || []);
        setIsLoading(false);
      });
    };
    getUsers();
  }, []);

  const restoreUser = async (userId) => {
    setUserIdOfUserBeingRestored(userId);
    await Axios.post('/client1/restore/' + userId);
    let res = await Axios.get('/client1/recycled');
    setUsers(res?.data || []);
  };

  return (
    <>
      <div style={{ fontWeight: '600', margin: '20px 0px', color: '#0077B6' }}>
        <span>Users Recycle Bin</span>
      </div>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table
            className={classes.table}
            size="small"
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell>User Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading ? (
                users ? (
                  users.map((user, idx) => {
                    return (
                      <TableRow key={nanoid(4)}>
                        <TableCell>{idx + 1}</TableCell>
                        <TableCell>
                          {user.firstName + ' ' + user.lastName}
                        </TableCell>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>
                          <button
                            disabled={userIdOfUserBeingRestored === user.userId}
                            className="restore-user-button"
                            onClick={() => restoreUser(user.userId)}
                          >
                            {' '}
                            Restore{' '}
                          </button>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <tr>
                    <td>There are no deleted users...</td>
                  </tr>
                )
              ) : (
                <div className="center">
                  <LoadingSpinner asOverlay />
                </div>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default UsersRecycleBinTable;
